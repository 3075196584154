import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

import defaultIMG from '../../assets/images/image.svg';

import ButtonSave from '../../component/common/ButtonSave';
import Datepicker from '../../component/common/Datepicker';
import InputPhone from '../../component/common/InputPhone';
import InputText from '../../component/common/InputText';
import UploaderAvatarStudent from '../../component/common/UploaderAvatarStudent';
import CheckboxCustom from '../../component/common/CheckboxCustom';

import { GET_LIST_ALL_CLUB, NEW_STUDENT_REQUEST } from '../../constants/config';
import {
  destructServerErrors,
  destructServerMessage
} from '../../helpers/error';
import { formatDate } from '../../helpers/form';
import { showMessage } from '../../helpers/table';
import { getTakenData, postData } from '../../services/base_services';

class NewStudent extends Component {
  constructor(props) {
    super(props);
    this.documents = [];
    this.state = {
      status: false,
      errors: [],
      idClub: 0,
      listClub: [],
      listClubOption: [],
      typical_flg: 0,
      coach_flg: false,
      user_id: ''
    };
  }

  handleInputChangeChecked = (e) => {
    let value = e.target.checked;
    if (value) {
      this.setState({
        typical_flg: 1
      });
    } else {
      this.setState({
        typical_flg: 0
      });
    }
  };

  handleInputChangeCheckedCoach = (e) => {
    let value = e.target.checked;
    if (value) {
      this.setState({
        coach_flg: true
      });
    } else {
      this.setState({
        coach_flg: false
      });
    }
  };

  addNew = (e) => {
    e.preventDefault();
    const { user } = this.props;
    const positionId = user && user.user && user.user.position_id;
    const clubId = user && user.user && user.user.club_id;
    const formData = {
      name: this.name.value,
      club_id: positionId == 3 ? clubId : this.state.idClub,
      avatar: this.avatar.value,
      address: this.address.value,
      email: this.email.value,
      phone: this.phone.value,
      identify_card_num: this.identify_card_num.value,
      home_town: this.home_town.value,
      health_status: this.health_status.value,
      academic_level: this.academic_level.value,
      birthday:
        this.birthday.value === ''
          ? formatDate(new Date()).split('-').reverse().join('-')
          : this.birthday.value.split('-').reverse().join('-'),
      join_date:
        this.join_date.value === ''
          ? formatDate(new Date()).split('-').reverse().join('-')
          : this.join_date.value.split('-').reverse().join('-'),
      profile: this.profile.value,
      job_detail: this.job.value,
      typical_flg: this.state.typical_flg,
      coach_flg: this.state.coach_flg,
      user_id: this.user_id.value
    };
    if (formData?.avatar == { defaultIMG }) {
      formData.avatar = '';
    }
    postData(NEW_STUDENT_REQUEST, formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({
          errors: [],
          status: true
        });
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);

        this.setState({
          errors: errss
        });
        if (err.response.status == 422) {
          return null;
        }
        showMessage(errs, false);
      });
  };

  handleChange = (newValue) => {
    this.setState({
      idClub: newValue && newValue.value
    });
  };

  componentDidMount = () => {
    this.getList();
  };

  getList = async () => {
    await getTakenData(GET_LIST_ALL_CLUB).then((res) => {
      let listClb = res.data;
      let listClbOpt = [];
      for (let i = 0; i < listClb.length; i++) {
        listClbOpt.push({
          label: listClb[i].name,
          value: listClb[i].id
        });
      }
      this.setState({
        listClub: listClb
      });
      this.setState({
        listClubOption: listClbOpt
      });
    });
  };

  render() {
    const { status, errors, listClub, listClubOption } = this.state;
    const formatDate = 'dd-MM-yyyy';
    const { history, user } = this.props;
    const positionId = user && user.user && user.user.position_id;
    if (status) {
      return (
        <Redirect
          to={{
            pathname: '/statistical/student'
          }}
        />
      );
    }
    return (
      <>
        <div className="body-right-bottom">
          <div className="container-fluid content">
            <div className="row top-table">
              <div className="col-md-12 top-table-title">
                <p>{this.props.t('student.header')}</p>
              </div>
              <div className="col-md-1 top-table-border "></div>
              <div className="col-md-12 top-table-text">
                {this.props.t('student.addStudent')}
              </div>
            </div>
            <div className="content-form">
              <div className="row"></div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 form-title">
                    {this.props.t('student.generalInformation')}
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.name')}
                      name="name"
                      ref={(c) => {
                        this.name = c;
                      }}
                      label={this.props.t('student.name')}
                      errors={errors}
                      type="text"
                      required={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="input-label">
                      {this.props.t('student.ofClub')}{' '}
                      <span style={{ color: '#ED1C24' }}>*</span>
                    </label>
                    {positionId == 3 ? (
                      <Select
                        className="select-custom-import"
                        name="club_id"
                        onChange={this.handleChange}
                        options={listClubOption}
                        isDisabled
                        value={listClubOption}
                        placeholder={this.props.t('student.ofClub')}
                      />
                    ) : (
                      <Select
                        className="select-custom-import"
                        name="club_id"
                        onChange={this.handleChange}
                        options={listClubOption}
                        placeholder={this.props.t('student.ofClub')}
                      />
                    )}

                    {errors.club_id && (
                      <div className="invalid-feedback d-block">
                        {errors.club_id}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <InputPhone
                      className="form-control input-form"
                      placeholder={this.props.t('student.phone')}
                      name="phone"
                      ref={(c) => {
                        this.phone = c;
                      }}
                      label={this.props.t('student.phone')}
                      errors={errors}
                      type="text"
                      required={true}
                    />
                  </div>

                  <div className="col-md-6">
                    <Datepicker
                      name="join_date"
                      ref={(c) => {
                        this.join_date = c;
                      }}
                      label={this.props.t('student.joinDate')}
                      defaultValue={null}
                      errors={errors}
                      clearIcon={false}
                      className="form-control input-form"
                      format={formatDate}
                      required={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <Datepicker
                      name="birthday"
                      ref={(c) => {
                        this.birthday = c;
                      }}
                      label={this.props.t('student.brithday')}
                      defaultValue={null}
                      errors={errors}
                      clearIcon={false}
                      className="form-control input-form"
                      format={formatDate}
                      required={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.uersId')}
                      name="user_id"
                      ref={(c) => {
                        this.user_id = c;
                      }}
                      label={this.props.t('student.uersId')}
                      errors={errors}
                      type="text"
                      required={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 form-title">
                    {this.props.t('student.otherInformation')}
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.address')}
                      name="address"
                      ref={(c) => {
                        this.address = c;
                      }}
                      label={this.props.t('student.address')}
                      errors={errors}
                      type="text"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.email')}
                      name="email"
                      ref={(c) => {
                        this.email = c;
                      }}
                      label={this.props.t('student.email')}
                      errors={errors}
                      type="text"
                    />
                  </div>

                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.account')}
                      name="profile"
                      ref={(c) => {
                        this.profile = c;
                      }}
                      label={this.props.t('student.account')}
                      errors={errors}
                      type="text"
                    />
                  </div>

                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.job')}
                      name="job_detail"
                      ref={(c) => {
                        this.job = c;
                      }}
                      label={this.props.t('student.job')}
                      errors={errors}
                      type="text"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.cardID')}
                      name="identify_card_num"
                      ref={(c) => {
                        this.identify_card_num = c;
                      }}
                      label={this.props.t('student.cardID')}
                      errors={errors}
                      type="number"
                      tabIndex="0"
                      onKeyDown={(e) =>
                        (e.keyCode === 69 || e.keyCode === 190) &&
                        e.preventDefault()
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.homeTown')}
                      name="home_town"
                      ref={(c) => {
                        this.home_town = c;
                      }}
                      label={this.props.t('student.homeTown')}
                      errors={errors}
                      type="text"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.healthCondition')}
                      name="health_status"
                      ref={(c) => {
                        this.health_status = c;
                      }}
                      label={this.props.t('student.healthCondition')}
                      errors={errors}
                      type="text"
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.education')}
                      name="academic_level"
                      ref={(c) => {
                        this.academic_level = c;
                      }}
                      label={this.props.t('student.education')}
                      errors={errors}
                      type="text"
                    />
                  </div>
                  <div className="col-md-6">
                    <UploaderAvatarStudent
                      ref={(c) => {
                        this.avatar = c;
                      }}
                      name="avatar"
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-3 checkbox-main-custom d-flex ">
                    <CheckboxCustom
                      id="checked"
                      label={this.props.t('student.outstandingStudent')}
                      onChange={this.handleInputChangeChecked}
                    />
                  </div>
                  <div className="col-md-3 checkbox-main-custom d-flex ">
                    <CheckboxCustom
                      id="check"
                      label={this.props.t('student.coach')}
                      onChange={(e) => this.handleInputChangeCheckedCoach(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-12 my-5">
                  <div className="form-add-buttons">
                    <Link to="/statistical/student">
                      <ButtonSave
                        text={this.props.t('student.cancel')}
                        className="btn btn-cancel"
                      />
                    </Link>
                    <ButtonSave
                      onClick={this.addNew}
                      text={this.props.t('student.save')}
                      className="btn btn-new ml-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withTranslation()(NewStudent)));
