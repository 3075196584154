/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import {
  DELETE_COURSE,
  GET_LIST_COURSE,
  GET_LIST_BRANCH,
  GET_CLUB_BRANCH,
  GET_CLB_DETAIL
} from '../../constants/config';
import { PER_PAGE } from '../../constants/variable';
import { confirmDelete } from '../../helpers/table';
import { formatDate } from '../../helpers/form';
import {
  postData,
  deleteById,
  getTakenData,
  getDataByID
} from '../../services/base_services';
import Can from '../../services/Can';

import Table from '../../component/common/Table';
import NewButton from '../../component/common/NewButton';
import InputSearch from '../../component/common/InputSearch';
import createNotification from '../../component/common/Notification';
import SelectCustom from '../../component/common/SelectCustom';
import Paginate from '../../component/Paginate';

import editIcon from '../../assets/images/icon/edit.svg';
import deleteIcon from '../../assets/images/icon/delete-kn.svg';

const Course = (props) => {
  const { user } = props;
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [tableData, setTableData] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [listBranch, setListBranch] = useState([]);
  const [initallBranch, setInitallBranch] = useState(0);
  const [listClub, setListClub] = useState([]);
  const [initallClub, setInitallClub] = useState(0);
  const [numberDelete, setNumberDelete] = useState(0);
  const [key, setKey] = useState('');
  const [req, setReq] = useState({
    keyword: key,
    page: queryParams.get('page') || 1,
    branch: '',
    club: 0,
    per_page: queryParams.get('perpage') || PER_PAGE
  });

  const tableHeaders = [
    {
      label: 'STT',
      index: 'stt',
      option: {
        className: 'text-center position-relative'
      },
      callback: null
    },
    {
      label: 'TIÊU ĐỀ',
      index: null,
      option: {
        className: 'w-25'
      },
      callback: (data) => {
        if (data && data.title !== null)
          return (
            <div>
              <span>{data && data.title}</span>
            </div>
          );
        return (
          <div className="text-center">
            <span>{' - '}</span>
          </div>
        );
      }
    },
    {
      label: 'THỜI GIAN BẮT ĐẦU',
      index: null,
      option: {
        className: 'text-center position-relative'
      },
      callback: (data) => {
        if (data && data.start_at)
          return (
            <div>
              <span>{data && formatDate(data.start_at, 'dd-mm-yyyy')}</span>
            </div>
          );
        return (
          <div className="text-center">
            <span>{' - '}</span>
          </div>
        );
      }
    },
    {
      label: 'SỐ BÀI',
      index: null,
      option: {
        className: 'text-center position-relative'
      },
      callback: (data) => {
        if (data && data.num_lesson !== null)
          return (
            <div>
              <span>{data && data.num_lesson}</span>
            </div>
          );
        return (
          <div className="text-center">
            <span>{' - '}</span>
          </div>
        );
      }
    },
    {
      label: 'PHÂN ĐƯỜNG',
      index: null,
      option: {
        className: ''
      },
      callback: (data) => {
        return (
          <div className="text-center">
            <span>{data.branchInfo?.name ? data.branchInfo?.name : ' - '}</span>
          </div>
        );
      }
    },
    {
      label: 'CÂU LẠC BỘ',
      index: null,
      option: {
        className: ''
      },
      callback: (data) => {
        return (
          <div className="text-center">
            <span>{data.clubInfo?.name ? data.clubInfo?.name : ' - '}</span>
          </div>
        );
      }
    },
    {
      label: 'SỐ VÕ SINH',
      index: null,
      option: {
        className: 'text-center'
      },
      callback: (data) => {
        if (data && data.num_member !== null)
          return (
            <div>
              <span>{data && data.num_member}</span>
            </div>
          );
        return (
          <div className="text-center">
            <span>{' - '}</span>
          </div>
        );
      }
    },
    {
      label: 'CHỨC NĂNG',
      index: null,
      option: {
        className: 'text-center fixed-collumn'
      },
      callback: (data) => {
        if (data && data.id) {
          return (
            <div className="btn-group">
              <Can I="course_edit" a="course">
                <span
                  role="button"
                  data-tip="Chỉnh Sửa Khoá Học"
                  className="link-action mr-2"
                  tabIndex="0"
                >
                  <Link to={`/active/course/edit/${data && data.id}`}>
                    <img src={editIcon} className="btn-icon" alt="edit" />
                  </Link>
                </span>
              </Can>
              <Can I="course_del" a="course">
                <span
                  role="button"
                  data-tip="Xóa Khoá Học"
                  className="link-action ml-2"
                  onClick={() =>
                    onDeleteCourse(data && data.id, data && data.title)
                  }
                  tabIndex="0"
                  onKeyPress={() => {}}
                >
                  <img src={deleteIcon} className="btn-icon" alt="delete" />
                </span>
              </Can>
              <ReactTooltip
                className="tooltip-button"
                place="bottom"
                effect="float"
              />
            </div>
          );
        }
        return '';
      }
    }
  ];

  const getListCourse = (newReq) => {
    postData(GET_LIST_COURSE, newReq)
      .then((res) => res && res.data)
      .then((res) => {
        setPaginate({ ...res.paginate, perPage: req?.per_page });
        setTableData(res && res.data);
      });
  };
  const deleteCourse = (id, name) => {
    deleteById(DELETE_COURSE, id).then(() => {
      setNumberDelete(numberDelete + 1);
      createNotification('success', `Bạn đã xoá thành công khoá học ${name}`);
    });
  };
  const onDeleteCourse = (id, name) => {
    confirmDelete().then((res) => {
      if (res && res.value) {
        deleteCourse(id, name);
      }
    });
  };

  const updateURL = (pageNumber, perpage) => {
    history.push(`?page=${pageNumber}&perpage=${perpage}`);
  };

  const changePerPage = (value) => {
    updateURL(1, value);
    setReq({
      ...req,
      page: 1,
      per_page: value
    });
  };

  const changePage = (pageNumber) => {
    updateURL(pageNumber, req?.per_page);
    setReq({
      ...req,
      page: pageNumber,
      per_page: req?.per_page
    });
  };
  const typingTimeoutRef = useRef(null);
  const onChangeSearch = (e) => {
    const value = e.target.value ? e.target.value : '';
    setKey(value);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setReq({
        ...req,
        keyword: value,
        page: 1,
        per_page: req?.per_page
      });
    }, 500);
  };

  useEffect(() => {
    getListCourse(req);
  }, [req, numberDelete]);

  useEffect(() => {
    getTakenData(GET_LIST_BRANCH)
      .then((res) => {
        if (res?.data?.length == 1) {
          setListBranch([...res?.data]);
          setInitallBranch(res?.data?.[0]?.id);
          setReq({ ...req, branch: res?.data?.[0]?.id });

          if (user.position_id == 3) {
            getDataByID(GET_CLUB_BRANCH, res?.data?.[0]?.id).then((res) => {
              const clubByClubId = res?.data?.find(
                (club) => club.id == user.club_id
              );
              setInitallClub(clubByClubId?.id);
              setListClub([clubByClubId]);
              setReq({
                ...req,
                branch: user.branch_id,
                club: clubByClubId?.id
              });
            });
          } else {
            getListClub(res?.data?.[0]?.id);
          }
        } else {
          const shortbranch = [
            {
              id: 0,
              name: 'Tất cả phân đường'
            },
            ...res?.data
          ]?.sort((a, b) => a?.id - b?.id);

          setListBranch(shortbranch);
        }
      })
      .catch((err) => err);

    setListClub([
      {
        id: 0,
        name: 'Tất cả Câu lạc bộ/ Võ đường'
      }
    ]);
  }, []);

  const getListClub = async (idBranch) => {
    await getDataByID(GET_CLUB_BRANCH, idBranch)
      .then((res) => {
        const shortClub = [
          {
            id: 0,
            name: 'Tất cả Câu lạc bộ/ Võ đường'
          },
          ...res?.data
        ]?.sort((a, b) => a?.id - b?.id);

        setListClub(shortClub);
      })
      .catch((err) => err);
  };

  const handleChangeBranch = (e) => {
    const value = e?.target?.value;

    setReq({
      ...req,
      page: 1,
      per_page: req?.per_page,
      branch: Number(value) || 0
    });
    getListClub(value);
    setInitallBranch(value);
  };

  const handleChangeClub = (e) => {
    const value = e?.target?.value;
    setReq({
      ...req,
      page: 1,
      per_page: req?.per_page,
      club: Number(value) || 0
    });
    setInitallClub(value);
  };

  return (
    <>
      <section className="body-right-bottom course-wrap">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>Quản Lý Hoạt Động </p>
            </div>
            <div className="col-md-1 top-table-border "></div>
            <div className="col-md-12 top-table-text">Khoá Học</div>
          </div>

          <div className="row header-wrap">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputSearch onChange={onChangeSearch} />
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4 content__flex-end">
              <Can I="competition_add" a="competition">
                <Link to="/active/course/new">
                  <NewButton />
                </Link>
              </Can>
            </div>
          </div>

          <div className="row">
            {user.position_id < 2 && (
              <div className="col-sm-12 col-md-4 col-lg-4">
                <SelectCustom
                  className="form-control-select"
                  errors="null"
                  name="type"
                  data={listBranch}
                  key_value="id"
                  key_label="name"
                  onChange={handleChangeBranch}
                  defaultValue={initallBranch}
                />
              </div>
            )}

            {user.position_id < 3 && (
              <div className="col-sm-12 col-md-4 col-lg-4">
                <SelectCustom
                  className="form-control-select"
                  label=""
                  errors={[]}
                  key_value="id"
                  key_label="name"
                  include_blank="Câu Lạc Bộ"
                  data={listClub}
                  onChange={handleChangeClub}
                  defaultValue={initallClub}
                />
              </div>
            )}
          </div>
          {/* <div className="row">
            <Can I="competition_add" a="competition">
              <Link to="/active/course/new" className="ml-auto my-auto pb-2">
                <NewButton />
              </Link>
            </Can>
          </div> */}
          <div className="row">
            <div className="col-md-12">
              <Table
                tableData={tableData}
                tableHeaders={tableHeaders}
                tablePaginate={paginate}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate
                  paginate={paginate}
                  changePage={changePage}
                  per_page={req?.per_page || PER_PAGE}
                  tablePaginate={paginate}
                  changePerPage={changePerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.user?.user
  };
};

export default connect(mapStateToProps, null)(withRouter(Course));
