const preRouter = '';

const ADMIN_ROUTES = {
  //HOME
  HOME_PAGE: `${preRouter}/home`,
  FORGOT_PASSWORD: `${preRouter}/forgot`,
  PROFILE: `${preRouter}/profile`,

  //QUAN LY VO PHAI
  STATISTICAL_STUDENT: `${preRouter}/statistical/student`,
  STATISTICAL_CLUB: `${preRouter}/statistical/club`,
  STATISTICAL_BRANCH: `${preRouter}/statistical/branch`,

  //QUAN LY CHUNG
  ACTIVE_DOCUMENTS: `${preRouter}/active/documents`,
  ACTIVE_SERI: `${preRouter}/active/seri`,
  ACTIVE_COURSE: `${preRouter}/active/course`,
  ACTIVE_COMPETITION: `${preRouter}/active/competition`,
  ACTIVE_EVENT: `${preRouter}/active/event`,

  ACTIVE_LIBRARIES_VIDEO: `${preRouter}/active/libraries/video`,
  ACTIVE_LIBRARIES_EBOOK: `${preRouter}/active/libraries/ebook`,
  ACTIVE_LIBRARIES_PHOTO: `${preRouter}/active/libraries/photo`,
  ACTIVE_LIBRARIES_DOCUMENT: `${preRouter}/active/libraries/document`,
  ACTIVE_LIBRARIES_REQUEST: `${preRouter}/active/libraries/request`,
  ACTIVE_LIBRARIES_CATEGORIES: `${preRouter}/active/libraries/categories`,

  //QUAN LY WEBSITE
  POSTWEB_BANNER: `${preRouter}/postweb/banner`,
  POSTWEB_ACTIVITIES: `${preRouter}/postweb/activities`,
  POSTWEB_TECHNIQUE: `${preRouter}/postweb/technique`,
  POSTWEB_FORM: `${preRouter}/postweb/form`,
  POSTWEB_CONTACT: `${preRouter}/postweb/contact`,
  POSTWEB_BANNER_BRANCH: `${preRouter}/postweb/banner/branch`,
  POSTWEB_BANNER_TECHNICAL: `${preRouter}/postweb/banner/technical`,
  POSTWEB_BANNER_GROUP: `${preRouter}/postweb/banner/group`,

  //QUAN LY TAI CHINH
  FINANCIAL_DONATE: `${preRouter}/financial/donate`,
  FINANCIAL_ADS: `${preRouter}/financial/ads`,

  //CAI DAT
  SETTING_PERMISSION: `${preRouter}/setting/permission`
};

const AUTH_ROUTES = {
  LOGIN: `${preRouter}/login`
};

export { ADMIN_ROUTES, AUTH_ROUTES };
