/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { checkError, getError } from '../../helpers/error';
import Alert from './Alert';

const Select = React.forwardRef((props, ref) => {
  const {
    errors,
    data,
    key_value,
    key_label,
    label,
    include_blank,
    defaultValue,
    row,
    ...input
  } = props;
  return (
    <div
      className={` ${row}}
          ? ${checkError(errors, input.name)} d-flex flex-wrap input-row
          : checkError(errors, input.name)`}
    >
       {label && <label className="input-label">{label} {input.required && <span className="required">*</span>}</label>}
      <select value={defaultValue} {...input} ref={ref}>
        {data.map((item, index) => (
          <option key={index} value={item[key_value]}>
            {item[key_label]}
          </option>
        ))}
      </select>
      <Alert messsage={getError(errors, input.name)} />
    </div>
  );
});

export default Select;

Select.propTypes = {
  errors: PropTypes.isRequired,
  defaultValue: PropTypes.isRequired,
  label: PropTypes.isRequired,
  order: PropTypes.isRequired,
  name: PropTypes.isRequired,
  input: PropTypes.isRequired,
  data: PropTypes.isRequired,
  key_label: PropTypes.isRequired,
  key_value: PropTypes.isRequired,
  row: PropTypes.isRequired,
  include_blank: PropTypes.isRequired
};
