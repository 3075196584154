import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { menuItems } from './menu';
import { ADMIN_ROUTES } from '../../constants/router';

import logo from '../../assets/images/logo.svg';
import nav from '../../assets/images/icon/nav-kn.svg';

import info from '../../assets/images/icon/info-kn.svg';
import operational from '../../assets/images/icon/operational-management-kn.svg';
import information from '../../assets/images/icon/information-management-kn.svg';
import statistics from '../../assets/images/icon/statistics-kn.svg';
import settings from '../../assets/images/icon/settings.svg';
import donation from '../../assets/images/icon/donation.svg';

const getUrlCurrentMenu = (match) => {
  const url = match && match.url;
  const arrUrl = url.split('/');
  const menu = arrUrl[1];
  const urlObj = {
    [menu]: url
  };
  return urlObj;
};

const SidebarMenu = (props) => {
  const { match, user } = props;
  const urlCurrent = getUrlCurrentMenu(match);

  const [statusMenu, setStatusMenu] = useState(false);
  const [urlMenu, setUrlMenu] = useState({
    ...urlCurrent
  });
  const widthDisplay = window.innerWidth;

  useEffect(() => {
    if (widthDisplay <= 991) {
      setStatusMenu(false);
      document.querySelector('.menu').style.left = '-225px';
      document.querySelector('.body-right').style.paddingLeft = '40px';
      document.querySelector('.menu-when-hide').style.height = '550px';
      setTimeout(() => {
        document.querySelector('#list-menu').style.display = 'none';
      }, 200);
    } else {
      setStatusMenu(true);
    }
  }, []);

  useEffect(() => {
    const menu = document.querySelector('.menu');
    const bodyRight = document.querySelector('.body-right');
    const menuWhenHide = document.querySelector('.menu-when-hide');
    const listMenu = document.querySelector('#list-menu');

    if (!statusMenu) {
      if (menu) menu.style.left = '-225px';
      if (bodyRight) bodyRight.style.paddingLeft = '40px';
      if (menuWhenHide) menuWhenHide.style.height = '550px';
      if (listMenu) listMenu.style.display = 'none';
    } else {
      if (menu) menu.style.left = '0';
      if (bodyRight) bodyRight.style.paddingLeft = '275px';
      if (listMenu) listMenu.style.display = 'block';
      if (menuWhenHide) menuWhenHide.style.height = '0px';
    }

    if (widthDisplay <= 991 && statusMenu) {
      if (bodyRight) bodyRight.style.paddingLeft = '0';
    }
  }, [statusMenu, widthDisplay]);

  const showMenu = () => {
    setStatusMenu(!statusMenu);
  };

  const changeUrl = (menu, url) => {
    setUrlMenu({ ...urlMenu, [menu]: url });
  };

  return (
    <>
      <div className="menu menu--left" id="menu">
        <Sidebar
          backgroundColor="#fff"
          rootStyles={{
            border: 'none',
            width: '100%'
          }}
        >
          <div className="menu-top top-menu d-flex">
            <Link to="/">
              <img src={logo} className="menu-top-logo" alt="logo" />
            </Link>
            <div className="menu-top-nav" onClick={() => showMenu()}>
              <img src={nav} alt="nav" />
            </div>
          </div>
          <Menu>
            {menuItems.map((item) => {
              if (!item?.children || item?.children?.length === 0) {
                return (
                  user?.position_id <= item?.position_id && (
                    <NavLink
                      to={item?.link}
                      activeClassName="active"
                      className="over-view"
                    >
                      <MenuItem key={item.id}>
                        <div className="item-menu">
                          <ReactSVG src={item?.icon} width={24} height={24} />
                          {item?.name}
                        </div>
                      </MenuItem>
                    </NavLink>
                  )
                );
              }
              return (
                user?.position_id <= item?.position_id && (
                  <div className="item-menu" key={item.id}>
                    <SubMenu
                      {...props}
                      label={item.name}
                      prefix={
                        <ReactSVG src={item?.icon} width={24} height={24} />
                      }
                    >
                      {item?.children?.map((it) => {
                        if (!it?.children || it?.children?.length === 0) {
                          return (
                            user?.position_id != it?.position_id && (
                              <NavLink
                                to={it?.link}
                                activeClassName="active-sub"
                              >
                                <MenuItem key={it?.id}>{it?.name}</MenuItem>
                              </NavLink>
                            )
                          );
                        }
                        return (
                          <SubMenu label={it?.name} key={it?.id}>
                            {it?.children?.map((sub) => (
                              <NavLink key={sub?.id} to={sub?.link}>
                                <MenuItem>{sub?.name}</MenuItem>
                              </NavLink>
                            ))}
                          </SubMenu>
                        );
                      })}
                    </SubMenu>
                  </div>
                )
              );
            })}
          </Menu>
        </Sidebar>

        <div className="menu-when-hide">
          <NavLink
            to={ADMIN_ROUTES.HOME_PAGE}
            activeClassName="active-hide"
            className="nav-link-menu menu-when-hide-icon"
            onClick={() => changeUrl('home', `${ADMIN_ROUTES.HOME_PAGE}`)}
          >
            <ReactSVG src={info} className="naviga-icon" />
          </NavLink>

          <NavLink
            to={ADMIN_ROUTES.STATISTICAL_BRANCH}
            activeClassName="active-hide"
            className="nav-link-menu menu-when-hide-icon"
            onClick={() =>
              changeUrl('statistical', `${ADMIN_ROUTES.STATISTICAL_BRANCH}`)
            }
          >
            <ReactSVG src={operational} className="naviga-icon" />
          </NavLink>

          <NavLink
            to={ADMIN_ROUTES.ACTIVE_DOCUMENTS}
            activeClassName="active-hide"
            className="nav-link-menu menu-when-hide-icon"
            onClick={() =>
              changeUrl('statistical', `${ADMIN_ROUTES.ACTIVE_DOCUMENTS}`)
            }
          >
            <ReactSVG src={information} className="naviga-icon" />
          </NavLink>

          <NavLink
            to={ADMIN_ROUTES.POSTWEB_BANNER}
            activeClassName="active-hide"
            className="nav-link-menu menu-when-hide-icon"
            onClick={() =>
              changeUrl('statistical', `${ADMIN_ROUTES.POSTWEB_BANNER}`)
            }
          >
            <ReactSVG src={statistics} className="naviga-icon" />
          </NavLink>

          <NavLink
            to={ADMIN_ROUTES.FINANCIAL_DONATE}
            activeClassName="active-hide"
            className="nav-link-menu menu-when-hide-icon"
            onClick={() =>
              changeUrl('statistical', `${ADMIN_ROUTES.FINANCIAL_DONATE}`)
            }
          >
            <ReactSVG src={donation} className="naviga-icon" />
          </NavLink>

          <NavLink
            to={ADMIN_ROUTES.SETTING_PERMISSION}
            activeClassName="active-hide"
            className="nav-link-menu menu-when-hide-icon"
            onClick={() =>
              changeUrl('statistical', `${ADMIN_ROUTES.SETTING_PERMISSION}`)
            }
          >
            <ReactSVG src={settings} className="naviga-icon" />
          </NavLink>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.user.user
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SidebarMenu));

SidebarMenu.propTypes = {
  menu: PropTypes.isRequired,
  match: PropTypes.object.isRequired
};
