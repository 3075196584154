import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InputSearch from '../../component/common/InputSearch';
import NewButton from '../../component/common/NewButton';
import Paginate from '../../component/Paginate/index';
import Table from '../../component/common/TableStudent';
import { PER_PAGE } from '../../constants/variable';
import { showMessage, confirmDelete } from '../../helpers/table';
import { LIST_SERI_REQUEST, DELETE_SERI_REQUEST } from '../../constants/config';
import { deleteById, postDataWithParams } from '../../services/base_services';
import { ADMIN_ROUTES } from '../../constants/router';
import { useHistory, useLocation } from 'react-router-dom';

import editIcon from '../../assets/images/icon/edit.svg';
import deleteIcon from '../../assets/images/images/delete.png';

const TableSeri = ({ user }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [paginate, setPaginate] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [perpage, setPerpage] = useState(
    queryParams.get('perpage') || PER_PAGE
  );

  const [filter, setFilter] = useState({
    page: queryParams.get('page') || 1,
    per_page: perpage,
    keyword: keyword,
    is_show: false
  });

  const tableHeaders = [
    {
      label: t('seri.stt'),
      index: 'stt',
      option: {
        className: 'text-center position-relative'
      },
      callback: null
    },
    {
      label: t('document.title'),
      index: null,
      option: {
        className: 'width-300'
      },
      callback: (data) => (
        <div>
          <span>{data && data?.name}</span>
        </div>
      )
    },

    {
      label: t('seri.quantityNews'),
      index: null,
      option: {
        className: 'text-center width-200'
      },
      callback: (data) => (
        <div>
          <span>{(data && data?.total_information) || '---'}</span>
        </div>
      )
    },
    {
      label: t('seri.status'),
      index: null,
      option: {
        className: 'text-center width-200'
      },
      callback: (data) => {
        switch (data && data?.is_show) {
          case true:
            return (
              <div>
                <span>{t('seri.posted')}</span>
              </div>
            );
          case false:
            return (
              <div>
                <span>{t('seri.notPosted')}</span>
              </div>
            );

          default:
            return null;
        }
      }
    },
    {
      label: t('document.action'),
      index: null,
      option: {
        className: 'text-center fixed-collumn'
      },
      callback: (data) => {
        if (data?.id) {
          return (
            <div className="btn-group">
              <span
                data-tip={t('document.edit')}
                className="link-action ml-2 mr-2"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => onEditStudent(data?.id)}
                  onKeyPress={() => {}}
                >
                  <img alt="edit" src={editIcon} className="btn-icon" />
                </div>
              </span>
              <span
                data-tip={t('document.delete')}
                className="link-action ml-2"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => onDeleteStudent(data?.id)}
                  onKeyPress={() => {}}
                >
                  <img alt="delete" src={deleteIcon} className="btn-icon" />
                </div>
              </span>
              <ReactTooltip
                className="tooltip-button"
                place="bottom"
                effect="float"
              />
            </div>
          );
        }
        return null;
      }
    }
  ];

  useEffect(() => {
    getListDocument();
  }, [filter]);

  const onEditStudent = (id) => {
    history.push(`${ADMIN_ROUTES?.ACTIVE_SERI}/edit/${id}`);
  };

  const onDeleteStudent = async (id) => {
    try {
      const res = await confirmDelete();
      if (res?.value) {
        await removeStudentRequest(id);
      }
    } catch (error) {}
  };

  const removeStudentRequest = async (id) => {
    try {
      const res = await deleteById(DELETE_SERI_REQUEST, id);
      showMessage(res?.data?.message, true);
      getListDocument();
    } catch (error) {
      showMessage(t('document.error'), false);
    }
  };

  const getListDocument = async () => {
    try {
      const res = await postDataWithParams(LIST_SERI_REQUEST, filter);

      setTableData(res?.data?.data);
      setPaginate({
        ...res?.data?.paginate,
        perPage: perpage
      });
      setIsLoading(false);
    } catch (error) {
      showMessage(`${t('document.error')} ${error}`, false);
    }
  };

  const onChangeSearch = (e) => {
    setFilter({ ...filter, keyword: e });
    setKeyword(e);
  };

  const updateURL = (pageNumber, perpage) => {
    history.push(`?page=${pageNumber}&perpage=${perpage}`);
  };

  const changePage = (pageNumber) => {
    if (pageNumber !== page) {
      updateURL(pageNumber, filter?.per_page);
      setFilter({ ...filter, page: pageNumber });
      setPage(pageNumber);
    }
  };

  const changePerPage = (value) => {
    setPerpage(value);
    updateURL(1, value);
    setFilter({ ...filter, per_page: value });
  };

  return (
    <>
      <div className="body-right-bottom course-wrap">
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>{t('seri.header')}</p>
            </div>
            <div className="col-md-1 top-table-border "></div>
            <div className="col-md-12 top-table-text">
              {t('seri.headerForm')}
            </div>
          </div>

          <div className="row header-wrap">
            <div className="col-sm-12 col-md-3 col-lg-3 ">
              <InputSearch onChange={(e) => onChangeSearch(e?.target?.value)} />
            </div>

            <div className="col-sm-12 col-md-3 col-lg-3 content__flex-end">
              <Link to="/active/seri/new">
                <NewButton />
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                fixed
                tableHeaders={tableHeaders}
                tableData={tableData}
                tablePaginate={paginate}
                isLoading={isLoading}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate
                  paginate={paginate}
                  changePage={changePage}
                  per_page={perpage || PER_PAGE}
                  tablePaginate={paginate}
                  changePerPage={changePerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
TableSeri.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state?.user?.user
  };
};

export default connect(mapStateToProps)(TableSeri);
