export const COLORS = [
  {
    id: 1,
    name: 'Đai Trắng',
    color: '#F7F2FF'
  },
  {
    id: 2,
    name: 'Đai Vàng',
    color: '#F9B248'
  },
  {
    id: 3,
    name: 'Đai Xanh',
    color: '#0050FD'
  },
  {
    id: 4,
    name: 'Đai Nâu',
    color: '#500003'
  },
  {
    id: 5,
    name: 'Đai Đen nhất Đẳng',
    color: '#000000'
  },
  {
    id: 6,
    name: 'Đai Đen Nhị Đẳng',
    color: '#000000'
  },
  {
    id: 7,
    name: 'Đai Đen Tam Đẳng',
    color: '#000000'
  },
  {
    id: 8,
    name: 'Đai Đen Tứ Đẳng',
    color: '#000000'
  },
  {
    id: 9,
    name: 'Đai Đen Ngũ Đẳng',
    color: '#000000'
  },
  {
    id: 10,
    name: 'Đai Đen Lục Đẳng',
    color: '#000000'
  },
  {
    id: 11,
    name: 'Đai Đen Thất Đẳng',
    color: '#000000'
  },
  {
    id: 12,
    name: 'Đai Đen Bát Đẳng',
    color: '#000000'
  },
  {
    id: 13,
    name: 'Đai Đen Cửu Đẳng',
    color: '#000000'
  },
  {
    id: 14,
    name: 'Đai Đen Thập Đẳng',
    color: '#000000'
  }
];
