/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Select from '../common/Select';

const PerPage = (props) => {
  const { per_page, tablePaginate, changePerPage } = props;
  const options = [
    {
      label: '10/Trang',
      value: 10
    },
    {
      label: '20/Trang',
      value: 20
    },
    {
      label: '30/Trang',
      value: 30
    },
    {
      label: '50/Trang',
      value: 50
    }
  ];
  if (tablePaginate.total === 0) {
    return '';
  }
  const changePage = (e) => {
    const { value } = e.target;
    changePerPage(value);
  };

  return (
    <div className="per-page">
      <Select
        className="form-control select-perpage"
        key_value="value"
        key_label="label"
        data={options}
        errors={[]}
        value={per_page}
        onChange={changePage}
      />
    </div>
  );
};

export default PerPage;

PerPage.propTypes = {
  per_page: PropTypes.isRequired,
  tablePaginate: PropTypes.isRequired,
  changePerPage: PropTypes.isRequired
};
