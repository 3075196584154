/* eslint-disable react/display-name */
import React from 'react';
import { Switch } from 'react-router-dom';

import { ADMIN_ROUTES } from '../constants/router';

import AuthRoute from './AuthRoute';
import Ads from '../container/Ads/AdsTable';
import AdsNew from '../container/Ads/NewAds';
import AdsEdit from '../container/Ads/EditAds';

import AddDonate from '../container/Donate/AddDonate';
import Donate from '../container/Donate';
import EditDonate from '../container/Donate/EditDonate';

const routes = [
  {
    path: ADMIN_ROUTES.FINANCIAL_ADS,
    component: () => <Ads />,
    exact: true,
    meta: {
      action: 'ads_managerment',
      subject: 'advertisements'
    }
  },
  {
    path: '/financial/ads/new',
    component: () => <AdsNew />,
    exact: true
  },
  {
    path: '/financial/ads/edit/:id',
    component: () => <AdsEdit />,
    exact: true
  },

  {
    path: ADMIN_ROUTES.FINANCIAL_DONATE,
    component: () => <Donate />,
    exact: true,
    meta: {
      action: 'donate_managerment',
      subject: 'donate'
    }
  },
  {
    path: '/financial/donate/add',
    component: () => <AddDonate />,
    exact: true
  },
  {
    path: '/financial/donate/edit/:id',
    component: () => <EditDonate />,
    exact: true
  }
];

const FinancialRoute = () => {
  if (typeof window !== 'undefined') {
    return (
      <>
        <Switch>
          {routes.map((route) => (
            <AuthRoute key={route.path} {...route} />
          ))}
        </Switch>
      </>
    );
  }
  return '';
};

export default FinancialRoute;
