import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import ButtonSave from '../../component/common/ButtonSave';
import Datepicker from '../../component/common/Datepicker';
import InputText from '../../component/common/InputText';
import createNotification from '../../component/common/Notification';
import { UPDATE_ADS, GET_DETAIL_ADS } from '../../constants/config';
import { destructServerErrors } from '../../helpers/error';
import { putData, getDataByID } from '../../services/base_services';
import GroupPhoto from '../../component/common/GroupPhoto';

const NewAds = (props) => {
  const idUser = useSelector(
    (state) => state && state.user && state.user.user && state.user.user.id
  );
  const { history, match } = props;
  const id = match && match.params && match.params.id;
  const [isLoading, setLoading] = useState(true);
  const [detail, setDetail] = useState([]);
  const formatDate = 'dd-MM-yyyy';
  let formData = {};
  const titleRef = useRef('');
  const dateSRef = useRef('');
  const dateERef = useRef('');
  const webRef = useRef('');
  const bgr = useRef([]);
  const [errors, setErrors] = useState([]);
  const onSubmit = () => {
    const title = titleRef.current.value;
    const web = webRef.current.value;
    const dateS = dateSRef.current.value.split('-').reverse().join('-');
    const dateE = dateERef.current.value.split('-').reverse().join('-');
    {
      formData = {
        title,
        link: web,
        start_at: dateS,
        end_at: dateE,
        banner: bgr.current.value
      };

      putData(UPDATE_ADS, id, formData)
        .then((res) => {
          createNotification('success', `Bạn đã cập nhật thành công`);
          setErrors('');
          history.push('/ads');
        })
        .catch((err) => {
          const errss = destructServerErrors(err);
          setErrors(errss);
          if (err.response.status == 422) {
            return null;
          }
          return createNotification('error', err.message);
        });
    }
  };

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    getDataByID(GET_DETAIL_ADS, id).then((res) => {
      setDetail(res.data);
      setLoading(false);
    });
  };
  return isLoading ? (
    ''
  ) : (
    <>
      <section className="body-right-bottom">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>Quản Lý Quảng Cáo </p>
            </div>
            <div className="col-md-1 top-table-border "></div>
            <div className="col-md-12 top-table-text">Chỉnh sửa</div>
          </div>

          <div className="content-form">
            <div className="row">
              <div className="col-md-12 content-title">
                <p>Cập nhật Nội Dung</p>
              </div>
            </div>
            <div className="form-add">
              <div className="row">
                <div className="col-md-12">
                  <InputText
                    label="Tên quảng cáo : (*) "
                    className="form-control input-form"
                    placeholder="Tên quảng cáo"
                    name="title"
                    ref={titleRef}
                    errors={errors}
                    type="text"
                    defaultValue={detail && detail.title}
                  />
                </div>

                <div className="col-md-6">
                  <Datepicker
                    label="Thời gian bắt Đầu quảng cáo (*): "
                    className="form-control input-form"
                    name="start_at"
                    placeholder="Thời Gian Bắt Đầu Quảng cáo"
                    ref={dateSRef}
                    errors={errors}
                    clearIcon={false}
                    format={formatDate}
                    defaultValue={detail && detail.start_at}
                  />
                </div>

                <div className="col-md-6">
                  <Datepicker
                    label="Thời gian kết thúc quảng cáo (*): "
                    className="form-control input-form"
                    name="end_at"
                    placeholder="Thời gian kết thúc quảng cáo"
                    ref={dateERef}
                    errors={errors}
                    clearIcon={false}
                    format={formatDate}
                    defaultValue={detail && detail.end_at}
                  />
                </div>

                <div className="col-md-12">
                  <InputText
                    label="Link trang web quảng cáo (*) :"
                    className="form-control input-form"
                    placeholder="Link trang web quảng cáo"
                    name="link"
                    ref={webRef}
                    errors={errors}
                    type="text"
                    defaultValue={detail && detail.link}
                  />
                </div>
                <div className="col-md-6">
                  <GroupPhoto
                    label="Ảnh bìa (*) :"
                    name="banner"
                    ref={bgr}
                    title="Chọn ảnh để tải"
                    errors={errors}
                    defaultValue={detail && detail.banner}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-12">
                  <div className="form-add-buttons">
                    <ButtonSave
                      onClick={() => onSubmit()}
                      text="Lưu thông tin"
                      className="btn btn-new ml-0"
                    />
                    <ButtonSave
                      onClick={history.goBack}
                      text="Hủy"
                      className="btn btn-cancel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(NewAds);

NewAds.propTypes = {
  history: PropTypes.isRequired
};
