import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom';
import ButtonSave from '../../component/common/ButtonSave';
import Datepicker from '../../component/common/Datepicker';
import InputText from '../../component/common/InputText';
import Select from '../../component/common/Select';
import TextArea from '../../component/common/TextArea';
import TextEditor from '../../component/common/TextEditor';
import UploadImg from '../../component/common/UploadImg';
import {
  ADD_EVENT,
  GET_CLUB_BRANCH,
  GET_LIST_BRANCH
} from '../../constants/config';
import {
  destructServerErrors,
  destructServerMessage
} from '../../helpers/error';
import { showMessage } from '../../helpers/table';
import {
  getDataByID,
  getTakenData,
  postData
} from '../../services/base_services';

class AddEvent extends Component {
  constructor(props) {
    super(props);
    this.documents = [];
    this.state = {
      status: false,
      errors: [],
      listBranch: [],
      listClub: [],
      contextData: [],
      listDoc: [],
      defaultType: [],
      type: [
        {
          id: 1,
          name: 'Sự kiện kín'
        },
        {
          id: 2,
          name: 'Sự kiện công khai'
        },
        {
          id: 3,
          name: 'Sự kiện ủng hộ'
        }
      ],
      type_sec: [
        {
          id: 1,
          name: 'Sự kiện kín'
        }
      ],
      type_id: 1,
      donate: []
    };
  }

  addNew = (e) => {
    e.preventDefault();
    const { contextData, type_id } = this.state;
    const { user } = this.props;
    let formData = [];
    if (type_id == 1) {
      formData = {
        title: this.title.value,
        branch_id: this.branch_id ? this.branch_id.value : null,
        club_id: this.club_id ? this.club_id.value : null,
        address: this.address.value,
        start_at: this.start_at.value.split('-').reverse().join('-'),
        detail: contextData,
        attach_files: this.documents,
        type: type_id,
        created_by: user.id,
        num_member: 0
      };
    } else if (type_id == 2) {
      formData = {
        title: this.title.value,
        address: this.address.value,
        start_at: this.start_at.value.split('-').reverse().join('-'),
        detail: contextData,
        attach_files: this.documents,
        type: type_id,
        created_by: user.id,
        num_member: 0
      };
    } else if (type_id == 3) {
      formData = {
        title: this.title.value,
        end_at: this.end_at.value.split('-').reverse().join('-'),
        detail: contextData,
        receiver_info: this.receiver_info.value,
        attach_files: this.documents,
        type: type_id,
        created_by: user.id,
        num_member: 0
      };
    }
    postData(ADD_EVENT, formData)
      .then((res) => {
        showMessage('', true);
        this.setState({
          errors: [],
          status: true
        });
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);
        this.setState({
          errors: errss
        });
        if (err.response.status == 422) {
          return null;
        }
        showMessage(errs, false);
      });
  };

  componentDidMount = () => {
    const { location } = this.props;
    const { state } = location;
    const { type } = this.state;
    this.setState({
      donate: state && state.donate,
      defaultType:
        state && state.donate ? type.find((item) => item.id === 3) : type[0],
      type_id: state && state.donate ? 3 : 1
    });
    this.getList();
  };

  getList = async () => {
    const { user } = this.props;
    const positionId = user && user.position_id;
    if (user && user.position_id != 2) {
      await getTakenData(GET_LIST_BRANCH).then((res) => {
        this.setState({
          listBranch: res?.data
        });
      });
    } else {
      await this.setState({
        listBranch: [
          {
            id: user && user.branch && user.branch.id,
            name: user && user.branch && user.branch.name
          }
        ]
      });
      this.defBranch(user && user.branch && user.branch.id);
    }
    if (positionId == 3) {
      const idBranchList = this.state.listBranch[0].id;
      await getDataByID(GET_CLUB_BRANCH, idBranchList).then((res) => {
        const clubByClubId = res?.data?.find(
          (club) => club?.id == user?.club_id
        );
        this.setState({
          listClub: [clubByClubId]
        });
      });
    }
  };

  onChangeDocument = (data) => {
    this.documents = data;
  };

  changeEditer = (e, editor) => {
    const data = editor.getData();
    this.setState({
      contextData: data
    });
  };
  chosseBranch = async (branchId) => {
    await getDataByID(GET_CLUB_BRANCH, branchId).then((res) => {
      this.setState({
        listClub: [
          {
            id: 0,
            name: 'Tất cả CLB / Võ đường'
          },
          ...res.data
        ]
      });
    });
  };

  defBranch = async (e) => {
    const branch_id = e;
    await getDataByID(GET_CLUB_BRANCH, branch_id).then((res) => {
      this.setState({
        listClub: res.data
      });
    });
  };

  changeType = (e) => {
    this.setState({
      type_id: e.target.value,
      errors: []
    });
  };

  render() {
    const formatDate2 = 'dd-MM-yyyy';
    const {
      status,
      errors,
      listBranch,
      listClub,
      type,
      type_id,
      donate,
      defaultType,
      type_sec
    } = this.state;
    const { user } = this.props;
    const positionId = user && user.position_id;
    const { history } = this.props;
    if (status && donate && type_id == 3) {
      return (
        <Redirect
          to={{
            pathname: '/donate'
          }}
        />
      );
    } else if (status) {
      return (
        <Redirect
          to={{
            pathname: '/active/event'
          }}
        />
      );
    }
    return (
      <>
        <div className="body-right-bottom">
          <div className="container-fluid content">
            <div className="row top-table">
              <div className="col-md-12 top-table-title">
                <p>Quản Lý Hoạt Động</p>
              </div>
              <div className="col-md-1 top-table-border "></div>
              <div className="col-md-12 top-table-text">Sự Kiện</div>
            </div>
            <div className="content-form">
              <div className="row">
                <div className="col-md-12 content-title">
                  <p>Tạo Sự Kiện Mới</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {positionId == 2 || positionId == 3 ? (
                    <>
                      <Select
                        label="Loại sự kiện"
                        className="form-control-white input-form mb-3 input-blu input-form-select-custom"
                        name="type"
                        disabled
                        errors={errors}
                        key_value="id"
                        key_label="name"
                        data={type_sec}
                        ref={(c) => {
                          this.type = c;
                        }}
                        required={true}
                      />
                    </>
                  ) : (
                    <Select
                      label="Loại sự kiện"
                      className="form-control-white input-form mb-3 input-blu"
                      name="type"
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      data={type}
                      ref={(c) => {
                        this.type = c;
                      }}
                      onChange={this.changeType}
                      defaultValue={defaultType}
                      required={true}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  <InputText
                    className="form-control input-form"
                    name="title"
                    label="Tên sự kiện "
                    errors={errors}
                    type="text"
                    ref={(c) => {
                      this.title = c;
                    }}
                    placeholder="Tên sự kiện"
                    required={true}
                  />
                </div>
              </div>
              {type_id == 1 ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      {positionId == 2 || positionId == 3 ? (
                        <Select
                          label="Đối tượng tham gia"
                          className="form-control-select input-form input-form-select-custom"
                          name="branch_id"
                          errors={errors}
                          key_value="id"
                          key_label="name"
                          data={listBranch}
                          ref={(c) => {
                            this.branch_id = c;
                          }}
                          disabled
                          onChange={(e) => this.chosseBranch(e?.target?.value)}
                          defaultValue={
                            user &&
                            (user.position_id == 2 || user.position_id == 3)
                              ? listBranch[0]
                              : ''
                          }
                          required={true}
                        />
                      ) : (
                        <Select
                          label="Đối tượng tham gia"
                          className="form-control-select input-form"
                          name="branch_id"
                          errors={errors}
                          key_value="id"
                          key_label="name"
                          include_blank="Tất cả phân đường"
                          data={listBranch}
                          ref={(c) => {
                            this.branch_id = c;
                          }}
                          onChange={(e) => this.chosseBranch(e?.target?.value)}
                          defaultValue={
                            user &&
                            (user.position_id == 2 || user.position_id == 3)
                              ? listBranch[0]
                              : ''
                          }
                          required={true}
                        />
                      )}
                    </div>
                    <div className="col-md-6">
                      {positionId == 3 ? (
                        <Select
                          label="Câu Lạc Bộ/Võ Đường"
                          className="form-control-select input-form input-form-select-custom"
                          name="club_id"
                          disabled
                          errors={errors}
                          key_value="id"
                          key_label="name"
                          data={listClub}
                          ref={(c) => {
                            this.club_id = c;
                          }}
                        />
                      ) : (
                        <Select
                          label="Câu Lạc Bộ/Võ Đường"
                          className="form-control-select input-form"
                          name="club_id"
                          errors={errors}
                          key_value="id"
                          key_label="name"
                          include_blank="Tất cả Câu Lạc Bộ/Võ Đường"
                          data={listClub}
                          ref={(c) => {
                            this.club_id = c;
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Datepicker
                        label="Thời gian tổ chức"
                        className="form-control input-form"
                        name="start_at"
                        errors={errors}
                        clearIcon={false}
                        format={formatDate2}
                        ref={(c) => {
                          this.start_at = c;
                        }}
                        required={true}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputText
                        className="form-control input-form"
                        placeholder="Địa điểm tổ chức"
                        name="address"
                        label="Địa điểm tổ chức"
                        errors={errors}
                        type="text"
                        ref={(c) => {
                          this.address = c;
                        }}
                        required={true}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              {type_id == 2 ? (
                <div className="row">
                  <div className="col-md-6">
                    <Datepicker
                      label="Thời gian tổ chức "
                      className="form-control input-form"
                      name="start_at"
                      errors={errors}
                      clearIcon={false}
                      format={formatDate2}
                      ref={(c) => {
                        this.start_at = c;
                      }}
                      required={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder="Địa điểm"
                      name="address"
                      label="Địa điểm tổ chức "
                      errors={errors}
                      type="text"
                      ref={(c) => {
                        this.address = c;
                      }}
                      required={true}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              {type_id == 3 ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <Datepicker
                        label="Thời gian hết hạn nhận ủng hộ"
                        className="form-control input-form"
                        name="end_at"
                        errors={errors}
                        clearIcon={false}
                        format={formatDate2}
                        ref={(c) => {
                          this.end_at = c;
                        }}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 custom-text">
                      <TextArea
                        className="form-control input-form text-area"
                        name="receiver_info"
                        label="Thông tin tài khoản nhận ủng hộ"
                        errors={errors}
                        type="text"
                        ref={(c) => {
                          this.receiver_info = c;
                        }}
                        required={true}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className="row">
                <div className="col-md-12 custom-text">
                  <TextEditor
                    name="content_notify"
                    label="Nội dung sự kiện "
                    errors={errors}
                    onChange={this.changeEditer}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="input-label">Hình ảnh đính kèm</label>
                  <UploadImg
                    label="hình ảnh đính kèm"
                    name="main_exam"
                    title="Chọn ảnh để tải"
                    onChange={this.onChangeDocument}
                    errors={[]}
                    default={this.documents}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-4 ">
                  <div className="form-add-buttons">
                    <Link to="/active/event">
                      <ButtonSave text="Hủy" className="btn btn-cancel" />
                    </Link>
                    <ButtonSave
                      onClick={this.addNew}
                      text="Tạo sự kiện"
                      className="btn btn-new"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

AddEvent.propTypes = {
  history: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user
  };
};

export default connect(mapStateToProps, null)(withRouter(AddEvent));
