import React, { useState, useEffect } from 'react';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Table from '../../../component/common/TableStudent';
import { TIME_FORMAT } from '../../../constants/formatDateTime';

import './ActivateTable.scss';

function ActivateTable({ dataActivate }) {
  const { t } = useTranslation();

  const formatDate = (date, format = TIME_FORMAT.EURO_DATE_FORMAT) => {
    if (!date) {
      return '';
    }
    const newDate = new Date(date);
    return dateFormat(newDate, format);
  };

  const [tableHeaders] = useState([
    {
      label: t('activateTable.stt'),
      index: 'stt',
      option: {
        className: 'text-center position-relative'
      }
    },
    {
      label: t('activateTable.title'),
      index: null,
      option: {
        className: 'width-50'
      },
      callback: (data) => (
        <div>
          <span>{data?.title}</span>
        </div>
      )
    },
    {
      label: t('activateTable.day'),
      index: null,
      option: {
        className: 'text-center width-20'
      },
      callback: (data) => (
        <div>
          <span>{formatDate(data?.date)}</span>
        </div>
      )
    },

    {
      label: t('activateTable.type'),
      index: null,
      option: {
        className: 'text-center width-20'
      },
      callback: (data) => (
        <div>
          <span>{t(`dashboard.${data?.type}`)}</span>
        </div>
      )
    }
  ]);

  return (
    <div className="activate activate-wrap">
      <div className="activate-header">
        <p>{t('activateTable.header')}</p>
        <Link to="/active/event">
          <span>{t('activateTable.seeMore')}</span>
        </Link>
      </div>
      <div className="activate-table">
        <Table
          fixed
          tableHeaders={tableHeaders}
          tablePaginate
          tableData={dataActivate}
        />
      </div>
    </div>
  );
}

export default ActivateTable;
