import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

import { destructServerErrors } from '../../helpers/error';
import { bannerTypeEnum } from '../../constants/banner.constants';
import { putData, getDataByID } from '../../services/base_services';
import { GET_DETAIL_BANNER, UPDATE_BANNER } from '../../constants/config';
import { ADMIN_ROUTES } from '../../constants/router';
import { showMessage } from '../../helpers/table';

import TextArea from '../../component/common/TextArea';
import InputText from '../../component/common/InputText';
import SelectCustom from '../../component/common/SelectCustom';
import GroupPhoto from '../../component/common/GroupPhoto';
import ButtonSave from '../../component/common/ButtonSave';
import createNotification from '../../component/common/Notification';

const NewAds = (props) => {
  const { history, match } = props;
  const id = match && match.params && match.params.id;
  const banner = useRef([]);
  const bannerMobile = useRef([]);
  const titleRef = useRef('');
  const position = useRef();
  const content = useRef('');

  const [isLoading, setLoading] = useState(true);
  const [detail, setDetail] = useState([]);
  const [errors, setErrors] = useState([]);
  const [typeBanner, setTypeBanner] = useState('');
  const [showBanner, setShowBanner] = useState(false);

  const onSubmit = () => {
    const payload = {
      title: titleRef?.current?.value,
      image: banner?.current?.value,
      image_mobile: bannerMobile?.current?.value,
      content: content?.current?.value,
      position: Number(position?.current?.value),
      is_show: showBanner,
      type: typeBanner
    };
    if (
      Number.isInteger(Number(position?.current?.value)) &&
      Number(position?.current?.value) > 0
    ) {
      putData(UPDATE_BANNER, id, payload)
        .then(() => {
          createNotification('success', `Bạn đã thêm thành công`);
          setErrors('');
          history.push(ADMIN_ROUTES.POSTWEB_BANNER);
        })
        .catch((err) => {
          const errss = destructServerErrors(err);
          setErrors(errss);
          if (err.response.status == 422) {
            return null;
          }
          return createNotification('error', err.message);
        });
    } else {
      showMessage('Position là số nguyên dương lớn hơn 0', false);
    }
  };
  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = () => {
    getDataByID(GET_DETAIL_BANNER, id).then((res) => {
      setDetail(res?.data);
      setLoading(false);
      setTypeBanner(res?.data?.type);
      setShowBanner(res?.data?.is_show);
    });
  };

  const handleSelectBannerType = (type) => {
    setTypeBanner(type?.target?.value);
  };

  const handleShowBanner = (show) => {
    setShowBanner(show);
  };

  const onChangePosition = (e) => {
    if (!Number.isInteger(Number(e)) || Number(e) <= 0) {
      setErrors({
        ...errors,
        position: ['Position là số nguyên dương lớn hơn 0']
      });
    }
  };

  return isLoading ? (
    ''
  ) : (
    <>
      <section className="body-right-bottom">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>Quản Lý Banner </p>
            </div>
            <div className="col-md-1 top-table-border "></div>
            <div className="col-md-12 top-table-text">Chỉnh sửa</div>
          </div>

          <div className="content-form">
            <div className="row">
              <div className="col-md-12 content-title">
                <p>Chỉnh sửa Banner</p>
              </div>
            </div>
            <div className="form-add">
              <div className="row">
                <div className="col-md-6">
                  <InputText
                    label="Tiêu đề "
                    className="form-control input-form"
                    placeholder="Tiêu đề"
                    name="title"
                    ref={titleRef}
                    errors={errors}
                    type="text"
                    defaultValue={detail && detail.title}
                    required={true}
                  />
                </div>
                <div className="col-md-6">
                  <SelectCustom
                    className="form-control-select"
                    label="Loại ảnh bìa"
                    errors={errors}
                    name="type"
                    data={bannerTypeEnum}
                    key_value="key"
                    key_label="label"
                    onChange={handleSelectBannerType}
                    defaultValue={typeBanner}
                  />
                </div>
                <div className="col-12">
                  <TextArea
                    className="form-control input-form-short"
                    placeholder="Nhập nội dung"
                    name="content"
                    label="Mô tả"
                    errors={errors}
                    type="textarea"
                    ref={content}
                    defaultValue={detail && detail?.content}
                  />
                </div>
                <div className="col-md-6">
                  <GroupPhoto
                    label="Ảnh bìa"
                    name="image"
                    ref={banner}
                    title="Chọn ảnh để tải"
                    errors={errors}
                    defaultValue={detail && detail.image}
                    order={1}
                    required={true}
                  />
                </div>

                <div className="col-md-6">
                  <GroupPhoto
                    label="Ảnh bìa mobile"
                    name="imageMobile"
                    ref={bannerMobile}
                    title="Chọn ảnh để tải"
                    errors={errors}
                    defaultValue={detail && detail?.image_mobile}
                    order={2}
                    required={true}
                  />
                </div>

                <div className="col-md-6">
                  <InputText
                    label="Vị trí"
                    className="form-control input-form"
                    placeholder="Vị trí"
                    errors={errors}
                    type="number"
                    name="position"
                    ref={position}
                    defaultValue={detail && detail?.position}
                    onChange={(e) => onChangePosition(e?.target?.value)}
                    min={1}
                    step={1}
                    required={true}
                  />
                </div>

                <div className="col-md-6">
                  <p className="label-banner-mobile">Hiển thị</p>
                  <Switch onChange={handleShowBanner} checked={showBanner} />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-12">
                  <div className="form-add-buttons">
                    <ButtonSave
                      onClick={history.goBack}
                      text="Hủy"
                      className="btn btn-cancel"
                    />
                    <ButtonSave
                      onClick={() => onSubmit()}
                      text="Lưu thông tin"
                      className="btn btn-new ml-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(NewAds);

NewAds.propTypes = {
  history: PropTypes.isRequired
};
