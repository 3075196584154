import React from 'react';
import { withTranslation } from 'react-i18next';

import InputText from './InputText';

import '../../assets/styles/HashTag/HashTag.scss';

const Tag = (props) => <span className="tag" {...props} />;
const Delete = (props) => <button className="delete" {...props} />;

class TagsInput extends React.Component {
  constructor() {
    super();
    this.state = {
      newTag: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleRemoveTag = this.handleRemoveTag.bind(this);
  }

  handleChange(e) {
    this.setState({ newTag: e.target.value });
  }

  handleKeyDown(e) {
    if (e.keyCode === 188 && e.target.value !== '') {
      e.preventDefault();
      let newTag = this.state.newTag?.trim();

      if (this.props.value?.indexOf(newTag) === -1) {
        this.props.value.push(newTag);
        this.setState({ newTag: '' });
      }
      this.props.onChange(this.props.value);
      e.target.value = '';
    }
  }

  handleRemoveTag(e) {
    let tag = e.target.parentNode.textContent.trim();
    let index = this.props.value.indexOf(tag);
    this.props.value.splice(index, 1);
    this.setState({ newTag: '' });
    this.props.onChange(this.props.value);
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        {this.props.label && <p className="input-label">{this.props.label} </p>}
        <div className="tags-input">
          {this.props.value?.map((tag, index) => (
            <Tag>
              {tag}
              <Delete onClick={this.handleRemoveTag} />
            </Tag>
          ))}
          <InputText
            type="text"
            value={this.state.newTag}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            className="form-control input-form"
            placeholder={t('document.placeholderHashtag')}
            name="hashtag"
            errors={[]}
          />
        </div>
      </div>
    );
  }
}
export default withTranslation()(TagsInput);
