import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { withTranslation } from 'react-i18next';

import {
  GET_SERI_REQUEST,
  UPDATE_DOCUMENT_REQUEST,
  UPDATE_SERI_REQUEST
} from '../../constants/config';
import {
  destructServerErrors,
  destructServerMessage
} from '../../helpers/error';
import { showMessage } from '../../helpers/table';
import { getDataByID, putData } from '../../services/base_services';

import TextArea from '../../component/common/TextArea';
import InputText from '../../component/common/InputText';
import TextEditor from '../../component/common/TextEditor';
import ButtonSave from '../../component/common/ButtonSave';
import GroupPhoto from '../../component/common/GroupPhoto';

class EditSeri extends Component {
  constructor(props) {
    super(props);
    this.documents = [];
    this.state = {
      isLoading: true,
      status: 1,
      errors: [],
      contentText: '',
      defaultList: [],
      data: [],
      is_show_new: true,
      thumbnail: '',
      title: ''
    };
  }
  addNew = (e) => {
    e.preventDefault();
    const { contentText, is_show_new } = this.state;
    const { match, history } = this.props;
    const { params } = match;
    const { id } = params;
    let formData = [];

    formData = {
      content: contentText,
      description: this.description?.value,
      name: this.title?.value,
      status: true,
      is_show: is_show_new,
      banner: this.thumbnail?.value
    };

    putData(UPDATE_SERI_REQUEST, id, formData)
      .then((res) => {
        showMessage(res?.data?.message, true);
        this.setState({
          errors: [],
          status: true
        });
        history.goBack();
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);
        this.setState({
          errors: errss
        });
        if (err?.response?.status == 422) {
          return null;
        }
        showMessage(errs, false);
      });
  };
  addDraft = (e) => {
    e.preventDefault();
    const { contentText, is_show_new } = this.state;
    const { match, history } = this.props;
    const { params } = match;
    const { id } = params;
    let formData = [];

    formData = {
      content: contentText,
      description: this.description?.value,
      name: this.title?.value,
      status: 0,
      is_show: is_show_new,
      banner: this.thumbnail?.value
    };

    putData(UPDATE_DOCUMENT_REQUEST, id, formData)
      .then((res) => {
        showMessage(res?.data?.message, true);
        this.setState({
          errors: [],
          status: true
        });
        history.goBack();
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);
        this.setState({
          errors: errss
        });
        if (err?.response?.status == 422) {
          return null;
        }
        showMessage(errs, false);
      });
  };

  componentDidMount = async () => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    if (id) {
      await getDataByID(GET_SERI_REQUEST, id)
        .then((res) => {
          this.setState({
            data: res?.data,
            contentText: res?.data?.content,
            title: res?.data?.name,
            isLoading: false,
            is_show_new: res?.data?.is_show || false,
            thumbnail: res?.data?.banner
          });

          return Promise.resolve({ res });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
  };

  changeEditer = (e, editor) => {
    const data = editor?.getData();
    this.setState({
      contentText: data
    });
  };

  handleDisplayToHome = (e) => {
    this.setState({
      is_show_new: e
    });
  };

  handleTagsChange(newtags) {
    this.setState({ hashtag: newtags });
  }

  render() {
    const { t } = this.props;

    const {
      errors,
      data,
      isLoading,
      is_show_new,
      thumbnail,
      title
    } = this.state;
    const { history } = this.props;

    return isLoading ? (
      ''
    ) : (
      <>
        <div className="body-right-bottom">
          <div className="container-fluid container-shadow">
            <div className="row top-table">
              <div className="col-md-12 top-table-title">
                <p>{t('seri.header')}</p>
              </div>
              <div className="col-md-1 top-table-border "></div>
              <div className="col-md-12 top-table-text">
                {' '}
                <p>{t('seri.headerFormEdit')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-title">
                <p>{t('seri.edit')} </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <InputText
                  className="form-control input-form"
                  placeholder={t('seri.title')}
                  name="name"
                  ref={(c) => {
                    this.title = c;
                  }}
                  label={t('seri.title')}
                  errors={errors}
                  type="text"
                  defaultValue={title}
                  required={true}
                />
              </div>
              <div className="col-md-12">
                <TextArea
                  className="form-control input-form"
                  placeholder={t('seri.description')}
                  name="description"
                  ref={(c) => {
                    this.description = c;
                  }}
                  label={t('seri.description')}
                  errors={errors}
                  defaultValue={data && data?.description}
                  type="textarea"
                />
              </div>
              <div className="col-md-12">
                <TextEditor
                  name="content"
                  label={t('seri.content')}
                  errors={errors}
                  data={data && data?.content}
                  onChange={this.changeEditer}
                  required={true}
                />
              </div>
              <div className="col-md-6">
                <GroupPhoto
                  label={t('seri.banner')}
                  name="banner"
                  title={t('seri.changeImgBanner')}
                  errors={[]}
                  order={1}
                  required={true}
                  ref={(c) => {
                    this.thumbnail = c;
                  }}
                  defaultValue={thumbnail}
                />
              </div>

              <div className="col-md-6">
                <label className="input-label col-md-12">
                  {t('seri.display')}
                </label>
                <Switch
                  onChange={this.handleDisplayToHome}
                  checked={is_show_new}
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className=" d-flex mx-auto my-5">
                <ButtonSave
                  text={t('seri.saveDraft')}
                  className="btn btn-draf"
                  onClick={this.addDraft}
                />
                <ButtonSave
                  onClick={this.addNew}
                  text={t('seri.save')}
                  className="btn btn-new ml-0"
                />
                <div
                  onClick={history.goBack}
                  onKeyPress={[]}
                  role="button"
                  tabIndex={0}
                >
                  <ButtonSave
                    text={t('seri.cancel')}
                    className="btn btn-cancel"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

EditSeri.propTypes = {
  history: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired,
  match: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state?.user
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withTranslation()(EditSeri)));
