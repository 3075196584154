import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip } from 'recharts';

import { COLORS } from '../../../constants/color';
import { COLORS_AGE } from '../../../constants/colorAge';

import CustomRadioGroup from '../../../component/common/CustomRadiogroup';

import './PieChart.scss';

export default function PieChartCommon({ dataPieChart, dataPieChartAge }) {
  const { t } = useTranslation();

  const [filter, setFilter] = useState('lever');
  const [dataChart, setDataChart] = useState(dataPieChart);

  const options = [
    { label: t('dashboard.belt'), value: 'lever' },
    { label: t('dashboard.age'), value: 'age' }
  ];

  const handleColor = (data) => {
    if (filter === 'lever') {
      return COLORS?.find((colors) => data?.name == colors?.name)?.color;
    } else {
      return COLORS_AGE?.find((colors) => data?.name == colors?.name)?.color;
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p
            className="label"
            style={{
              color: `${
                payload?.[0].name == 'Đai Trắng'
                  ? '#000000'
                  : payload?.[0].payload?.fill?.[0]
              }`,
              marginBottom: 0
            }}
          >{`${payload[0].name}: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    filter === 'lever'
      ? setDataChart(dataPieChart)
      : setDataChart(dataPieChartAge);
  }, [filter, dataPieChart, dataPieChartAge]);

  const handleChange = (value) => {
    setFilter(value);
  };
  return (
    <div className="piechar piechar-wap">
      <div className=" piechar-filter ">
        <CustomRadioGroup
          idPie="age"
          options={options}
          value={filter}
          onChange={handleChange}
          buttonStyle="buttonStyle"
        />
      </div>

      <div className="piechar-item">
        <ResponsiveContainer width="100%" height={290}>
          <PieChart width={'100%'} height={200}>
            <Pie
              data={dataChart}
              cx={'50%'}
              cy={'50%'}
              labelLine={false}
              outerRadius={'100%'}
              fill="#8884d8"
              dataKey="total"
            >
              {dataChart?.map((data, index) => (
                <Cell key={`cell-${index}`} fill={handleColor(data)} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
