/* eslint-disable react/display-name */
import React from 'react';
import { Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import Banner from '../container/Banner/BannerTable';
import BannerNew from '../container/Banner/BannerAdd';
import BannerEdit from '../container/Banner/BannerEdit';
import BranchBanner from '../container/Banner/BranchBanner';
import TechnicalBanner from '../container/Banner/TechnicalBanner';
import GroupBanner from '../container/Banner/GroupBanner';
import BranchBannerEdit from '../container/Banner/BranchBannerEdit';
import TechnicalBannerEdit from '../container/Banner/TechnicalBannerEdit';
import GroupBannerEdit from '../container/Banner/GroupBannerEdit';

const routes = [
  {
    path: '/banner',
    component: () => <Banner />,
    exact: true,
    meta: {
      action: 'banner_managerment',
      subject: 'banner'
    }
  },
  {
    path: '/banner/new',
    component: () => <BannerNew />,
    exact: true
    // meta: {
    //   action: 'donate_managerment',
    //   subject: 'donate'
    // }
  },
  {
    path: '/banner/edit/:id',
    component: () => <BannerEdit />,
    exact: true
    // meta: {
    //   action: 'donate_managerment',
    //   subject: 'donate'
    // }
  },
  {
    path: '/banner/branch',
    component: () => <BranchBanner />,
    exact: true,
  },
  {
    path: '/banner/branch/edit/:id',
    component: () => <BranchBannerEdit />,
    exact: true,
  },
  {
    path: '/banner/technical',
    component: () => <TechnicalBanner />,
    exact: true,
  },
  {
    path: '/banner/technical/edit/:id',
    component: () => <TechnicalBannerEdit />,
    exact: true,
  },
  {
    path: '/banner/group',
    component: () => <GroupBanner />,
    exact: true,
  },
  {
    path: '/banner/group/edit/:id',
    component: () => <GroupBannerEdit />,
    exact: true,
  }
];

const BannerRoutes = () => {
  if (typeof window !== 'undefined') {
    return (
      <>
        <Switch>
          {routes.map((route) => (
            <AuthRoute key={route.path} {...route} />
          ))}
        </Switch>
      </>
    );
  }
  return '';
};

export default BannerRoutes;
