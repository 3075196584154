import { Ability } from '@casl/ability';
import store from '../store/store';

function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  return item.__type;
}
const ability = new Ability([], { subjectName });

store.subscribe(async () => {
  const user = await store.getState().user;
  const permissions = user && user.permissions;
  if (permissions) {
    ability.update(permissions);
  }
});

export default ability;
