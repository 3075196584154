import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { postData } from '../../services/base_services';
import { NEW_TITLE_CATEGORY } from '../../constants/config';

import {
  destructServerErrors,
  destructServerMessage
} from '../../helpers/error';
import { showMessage } from '../../helpers/table';

import ButtonSave from '../../component/common/ButtonSave';
import InputText from '../../component/common/InputText';

class NewTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      status: false,
      errors: []
    };
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addNew = (e) => {
    e.preventDefault();
    const { title } = this.state;
    const { history } = this.props;

    const formData = {
      title: title
    };

    postData(NEW_TITLE_CATEGORY, formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({ errors: [], status: true });
        history.goBack();
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);
        this.setState({ errors: errss });
        if (err.response.status === 422) {
          return null;
        }
        showMessage(errs, false);
      });
  };

  render() {
    const { errors, title } = this.state;
    const { history } = this.props;
    return (
      <>
        <div className="body-right-bottom">
          <div className="container-fluid container-shadow">
            <div className="row top-table">
              <div className="col-md-12 top-table-title">
                <p>{this.props.t('title.manager')}</p>
              </div>
              <div className="col-md-1 top-table-border "></div>
              <div className="col-md-12 top-table-text">
                {this.props.t('title.add')}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-title">
                <p> {this.props.t('title.newDocument')}</p>
              </div>
            </div>
            <div className="row"></div>
            <div className="row">
              <div className="col-md-12">
                <InputText
                  className="form-control input-form"
                  placeholder={this.props.t('title.title')}
                  name="title"
                  value={title}
                  onChange={this.handleInputChange}
                  label={this.props.t('title.title')}
                  errors={errors}
                  type="text"
                  required={true}
                  ref={(input) => {
                    this.titleInput = input;
                  }}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className=" d-flex mx-auto my-5">
                <div
                  onClick={history.goBack}
                  onKeyPress={[]}
                  role="button"
                  tabIndex={0}
                >
                  <ButtonSave
                    text={this.props.t('title.cancle')}
                    className="btn btn-cancel"
                  />
                </div>
                <ButtonSave
                  onClick={this.addNew}
                  text={this.props.t('title.save')}
                  className="btn btn-new ml-0"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

NewTitle.propTypes = {
  history: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withTranslation()(NewTitle)));
