import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BASE_IMG } from '../../../constants/config';
import userNew from '../../../assets/images/userNew.png';

import '../StudentTable/StudentTable.scss';

function StudentTable({ dataStudent }) {
  const { t } = useTranslation();

  return (
    <div className="student student-wrap">
      <div className="student-header">
        <p>{t('studentTable.header')}</p>
        <Link to="/statistical/student">
          <span>{t('studentTable.seeMore')}</span>
        </Link>
      </div>
      {dataStudent?.map((data) => {
        return (
          <div key={data.id} className="student-item">
            <div className="student-item-image">
              <img
                src={data?.avatar ? `${BASE_IMG}/${data?.avatar}` : userNew}
              />
            </div>

            <div className="student-item-info">
              <div className="student-item-info-name">{data?.name}</div>
              <div className="student-item-info-club">
                {data?.infoClub?.name}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default StudentTable;
