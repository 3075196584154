/* eslint-disable react/display-name */
import React from 'react';
import { Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import Ads from '../container/Ads/AdsTable';
import AdsNew from '../container/Ads/NewAds';
import AdsEdit from '../container/Ads/EditAds';

const routes = [
  {
    path: '/ads',
    component: () => <Ads />,
    exact: true,
    meta: {
      action: 'ads_managerment',
      subject: 'advertisements'
    }
  },
  {
    path: '/ads/new',
    component: () => <AdsNew />,
    exact: true
    // meta: {
    //   action: 'donate_managerment',
    //   subject: 'donate'
    // }
  },
  {
    path: '/ads/edit/:id',
    component: () => <AdsEdit />,
    exact: true
    // meta: {
    //   action: 'donate_managerment',
    //   subject: 'donate'
    // }
  }
];

const AdsRoutes = () => {
  if (typeof window !== 'undefined') {
    return (
      <>
        <Switch>
          {routes.map((route) => (
            <AuthRoute key={route.path} {...route} />
          ))}
        </Switch>
      </>
    );
  }
  return '';
};

export default AdsRoutes;
