import React from 'react';
import { useTranslation } from 'react-i18next';

import arrowUp from '../../../assets/images/icon/arrow-up-green.svg';
import arrowDown from '../../../assets/images/icon/arrow-up-red.svg';

import '../Statistic/Statistic.scss';
function Statistic({ title, valueCurrent, valuePre, image }) {
  const { t } = useTranslation();

  const handledifference = (valueCurrent, valuePre) => {
    if (valuePre != 0) {
      return (
        Math.round(
          Math.abs(((valueCurrent - valuePre) / valuePre) * 100) * 10
        ) / 10
      );
    } else {
      return 0;
    }
  };

  return (
    <div className="statistic-wrap">
      <div className="statistic-item">
        <div className="statistic-content">
          <div className="statistic-image">
            <img src={image} alt="statistic" />
          </div>
          <div className="statistic-title">
            <p>{title}</p>
          </div>
          <div className="statistic-value">{<p>{valueCurrent}</p>}</div>
          {valuePre != undefined && (
            <div className="statistic-difference">
              <img src={valueCurrent - valuePre >= 0 ? arrowUp : arrowDown} />
              <p
                style={{
                  color: valueCurrent - valuePre >= 0 ? '#28A745' : '#DC3545'
                }}
              >
                {handledifference(valueCurrent, valuePre)}
                <span>%</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Statistic;
