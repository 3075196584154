/* eslint-disable react/display-name */
import React from 'react';
import { Switch } from 'react-router-dom';
import TableStudent from '../container/Student';
import NewStudent from '../container/Student/NewStudent';
import EditStudent from '../container/Student/EditStudent';
import CertificateStudent from '../container/Student/CertificateStudent';
import AnotherStudent from '../container/Student/AnotherStudent';
import HistoryStudent from '../container/Student/HistoryStudent';
import RoleStudent from '../container/Student/RoleStudent';
import AuthRoute from './AuthRoute';
import AddCertificateStudent from '../container/Student/AddCertificateStudent';
import AddHistoryStudent from '../container/Student/AddHistoryStudent';
import EditCertificateStudent from '../container/Student/EditCertificateStudent';
import AddAnotherStudent from '../container/Student/AddAnotherStudent';
import EditAnotherStudent from '../container/Student/EditAnotherStudent';
import EditHistoryStudent from '../container/Student/EditHistoryStudent';
import StudentDocumentManagement from '../container/Student/StudentDocumentManagement';

const routes = [
  {
    path: '/statistical/student',
    component: () => <TableStudent />,
    exact: true,
    meta: {
      action: 'user_view',
      subject: 'user'
    }
  },
  {
    path: '/statistical/student/new',
    component: () => <NewStudent />,
    exact: true
    // meta: {
    //   action: "user_add",
    //   subject: "user",
    // },
  },
  {
    path: '/statistical/student/edit/:id',
    component: () => <EditStudent />,
    exact: true
    // meta: {
    //   action: "user_edit",
    //   subject: "user",
    // },
  },
  {
    path: '/statistical/student/certificate/:id',
    component: () => <CertificateStudent />,
    exact: true,
    meta: {
      action: 'certificate_view',
      subject: 'certificate'
    }
  },
  {
    path: '/statistical/student/another/:id',
    component: () => <AnotherStudent />,
    exact: true
    // meta: {
    //   action: 'certificate_view',
    //   subject: 'certificate'
    // }
  },
  {
    path: '/statistical/student/:id/another/add',
    component: () => <AddAnotherStudent />,
    exact: true
    // meta: {
    //   action: 'certificate_view',
    //   subject: 'certificate'
    // }
  },
  ,
  {
    path: '/statistical/student/:id/another/edit/:idCertification',
    component: () => <EditAnotherStudent />,
    exact: true
    // meta: {
    //   action: 'certificate_view',
    //   subject: 'certificate'
    // }
  },
  {
    path: '/statistical/student/:id/certificate/add',
    component: () => <AddCertificateStudent />,
    exact: true,
    meta: {
      action: 'certificate_add',
      subject: 'certificate'
    }
  },
  {
    path: '/statistical/student/:id/certificate/edit/:idCertification',
    component: () => <EditCertificateStudent />,
    exact: true,
    meta: {
      action: 'certificate_edit',
      subject: 'certificate'
    }
  },
  {
    path: '/statistical/student/history/:id',
    component: () => <HistoryStudent />,
    exact: true,
    meta: {
      action: 'user_history_view',
      subject: 'user'
    }
  },
  {
    path: '/statistical/student/:id/history/add',
    component: () => <AddHistoryStudent />,
    exact: true,
    meta: {
      action: 'user_history_add',
      subject: 'user'
    }
  },
  {
    path: '/statistical/student/:id/history/edit/:idstudent',
    component: () => <EditHistoryStudent />,
    exact: true,
    meta: {
      action: 'user_history_edit',
      subject: 'user'
    }
  },
  {
    path: '/statistical/student/role/:id',
    component: () => <RoleStudent />,
    exact: true
  },
  {
    path: '/statistical/student/documents/:id',
    component: () => <StudentDocumentManagement />,
    exact: true
  }
];

const StudentRoutes = () => {
  if (typeof window !== 'undefined') {
    return (
      <>
        <Switch>
          {routes.map((route) => (
            <AuthRoute key={route.path} {...route} />
          ))}
        </Switch>
      </>
    );
  }
  return '';
};

export default StudentRoutes;
