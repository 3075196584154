import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import close from '../../assets/images/images/delete.png';

import ButtonSave from '../../component/common/ButtonSave';
import GroupEbookSmall from '../../component/common/GroupEbookSmall';
import InputText from '../../component/common/InputText';
import createNotification from '../../component/common/Notification';
import Select from '../../component/common/Select';
import TextEditor from '../../component/common/TextEditor';
import Datepicker from '../../component/common/Datepicker';

import {
  GET_CLUB_BRANCH,
  GET_COURSE_DETAIL,
  GET_LIST_BRANCH,
  UPDATE_COURSE,
  GET_ALL_BELT_COURSE
} from '../../constants/config';
import { destructServerErrors } from '../../helpers/error';
import { getFormDataFromRef, formatDate } from '../../helpers/form';
import {
  getDataByID,
  getTakenData,
  putData
} from '../../services/base_services';

const formatDate2 = 'dd-MM-yyyy';

class EditCourse extends Component {
  constructor(props) {
    super(props);
    this.documents = [];
    this.state = {
      courseDetail: [],
      status: false,
      errors: [],
      listBranch: [],
      listBranchAll: [],
      idBranch: 0,
      listClub: [],
      listClubAll: [],
      listBelt: [],
      isChangeBranch: 0,
      arrDelete: [],
      numFiles: [],
      urlFiles: {},
      summary: '',
      start_at: ''
    };
  }

  //test add course
  getListBelt = async () => {
    await getTakenData(GET_ALL_BELT_COURSE).then((res) => {
      this.setState({
        listBelt: res && res.data
      });
    });
  };
  getListBranch = async () => {
    await getTakenData(GET_LIST_BRANCH).then((res) => {
      const dataNew = res && res.data;
      this.setState({
        listBranch: dataNew
      });
      dataNew.push({ id: 0, name: 'Tất cả phân đường' });
      this.setState({
        listBranchAll: dataNew
      });
    });
  };
  handleChangeBranch = () => {
    this.setState({
      isChangeBranch: this.state.isChangeBranch + 1
    });
  };
  getListClub = async (id) => {
    await getTakenData(`${GET_CLUB_BRANCH}/${id}`).then((res) => {
      const dataNew = res && res.data;
      this.setState({
        listClub: dataNew
      });
      dataNew.push({ id: 0, name: 'Tất cả Câu Lạc Bộ/Võ Đường' });
      this.setState({
        listClubAll: dataNew
      });
    });
  };

  handleAddInput = () => {
    const { numFiles } = this.state;
    const temp = {
      title: '',
      file: ''
    };
    this.setState({
      numFiles: [...numFiles, temp]
    });
  };

  deleteFile = (e, id) => {
    e.preventDefault();
    let inputDeleteElm = document.querySelector(`#div-${id}`);
    if (inputDeleteElm) {
      inputDeleteElm.innerHTML = '';
      this.setState({
        arrDelete: [...this.state.arrDelete, id]
      });
    }
    return;
  };

  onSubmit = () => {
    const { user, history, match } = this.props;
    const idCourse = match && match.params && match.params.id;
    const { numFiles, urlFiles } = this.state;

    const idUser = user && user.user && user.user.id;
    const title = this.nameRef.value;
    const par_conditions = this.IDBeltRef.value;
    const organized_by_branch =
      this.IDBranchRef.value == 0 ? null : this.IDBranchRef.value;
    const organized_by_club =
      this.IDClubRef && this.IDClubRef.value == 0 ? null : this.IDClubRef.value;
    const course_overview = this.summary;

    const dataFile = getFormDataFromRef(this.refs);

    let attach_files = [];
    numFiles.map((item, index) => {
      attach_files = [
        ...attach_files,
        {
          file: urlFiles[index].length === 0 ? '' : urlFiles[index][0],
          title: dataFile[`indexText${index}`]
        }
      ];
    });

    for (let i = 0; i < this.state.arrDelete.length; i++) {
      attach_files.splice(this.state.arrDelete[i], 1, 'file was delete');
    }
    let attach_files_select = attach_files.filter(
      (item) => item !== 'file was delete'
    );
    const formData = {
      title,
      par_conditions,
      organized_by_branch,
      organized_by_club,
      created_by: idUser,
      is_sendnotify: false,
      course_overview,
      num_lesson: attach_files.length - this.state.arrDelete.length || 0,
      attach_files: attach_files_select,
      status: 2,
      start_at: this.state.start_at
    };

    putData(UPDATE_COURSE, idCourse, formData)
      .then((res) => {
        createNotification(
          'success',
          `Bạn đã chỉnh sửa thành công khoá học ${title}`
        );
        this.setState({
          errors: ''
        });
        history.push('/active/course');
      })
      .catch((err) => {
        const errss = destructServerErrors(err);
        this.setState({
          errors: errss
        });
        if (err.response.status == 422) {
          return null;
        }
        return createNotification('error', err.message);
      });
  };

  getCourseDetail = () => {
    const { match } = this.props;
    const idCourse = match && match.params && match.params.id;
    return getDataByID(GET_COURSE_DETAIL, idCourse).then((res) => {
      this.setState({
        courseDetail: res && res.data
      });
      this.setState({ start_at: res?.data?.start_at });
      this.summary = res?.data?.course_overview || '';
      const attach_files_fetch = res && res.data && res.data.attach_files;
      if (attach_files_fetch.length > 0) {
        this.setState({
          numFiles: [...(res && res.data && res.data.attach_files)]
        });
        let urlFilesTemp = {};
        for (let i = 0; i < attach_files_fetch.length; i++) {
          urlFilesTemp = { ...urlFilesTemp, [i]: [attach_files_fetch[i].file] };
        }
        this.setState({
          urlFiles: urlFilesTemp
        });
      } else {
        // default one input
        this.setState({
          numFiles: [
            {
              file: '',
              title: ''
            }
          ]
        });
      }
      this.getListClub(
        res && res.data && res.data.branchInfo && res.data.branchInfo.id
      );
    });
  };

  handleChangeSummary = (e, editor) => {
    const data = editor.getData();
    this.summary = data;
  };

  handleChangeDate = (date) => {
    this.setState({ start_at: formatDate(date, 'yyyy-mm-dd') });
  };

  componentDidMount = () => {
    this.getListBelt();
    this.getListBranch();
    this.getCourseDetail();
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.isChangeBranch !== prevState.isChangeBranch) {
      this.getListClub(this.IDBranchRef.value);
    }
  }
  render() {
    const {
      errors,
      listClub,
      listClubAll,
      listBranch,
      listBelt,
      numFiles,
      courseDetail,
      isChangeBranch
    } = this.state;
    const { history, location, user } = this.props;
    const positionId = user && user.user && user.user.position_id;
    const id = location && location.query && location.query.log_id;
    return (
      <>
        <section className="body-right-bottom">
          <div className="container-fluid content course">
            <div className="row top-table">
              <div className="col-md-12 top-table-title">
                <p>Quản Lý Khoá Học </p>
              </div>
              <div className="col-md-1 top-table-border "></div>
              <div className="col-md-12 top-table-text">Chỉnh Sửa Khoá Học</div>
            </div>

            <div className="content-form">
              <div className="row">
                <div className="col-md-12 content-title">
                  <p>Chỉnh Sửa Khoá Học</p>
                </div>
              </div>
              <div className="form-add">
                <div className="row">
                  <div className="col-md-12">
                    <InputText
                      className="form-control input-form"
                      placeholder=""
                      name="title"
                      ref={(r) => {
                        this.nameRef = r;
                      }}
                      label="Tên khoá học"
                      required={true}
                      errors={errors}
                      type="text"
                      defaultValue={courseDetail && courseDetail.title}
                    />
                  </div>

                  <div className="col-md-6">
                    <Select
                      className="form-control-white input-form mb-3 input-blu"
                      label="Điều kiện đăng ký của võ sinh"
                      required={true}
                      name="par_conditions"
                      ref={(r) => {
                        this.IDBeltRef = r;
                      }}
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      include_blank="Điều kiện đăng ký"
                      data={listBelt}
                      defaultValue={courseDetail && courseDetail.beltInfo}
                    />
                  </div>

                  <div className="col-md-6">
                    <Datepicker
                      name="start_at"
                      label="Thời gian bắt đầu nhận hồ sơ *"
                      required={true}
                      errors={errors}
                      clearIcon={false}
                      className="form-control input-form"
                      format={formatDate2}
                      value={this.state.start_at}
                      onChange={(date) => this.handleChangeDate(date)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-add-title form-add-title-custom form-add-title--small">
                      <p>Đối tượng tham gia :</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {positionId == 2 || positionId == 3 ? (
                      <Select
                        label="Đối tượng tham gia"
                        className="form-control-select input-form form-control-product mb-3 input-grey input-form-select-custom"
                        name="branch_id"
                        ref={(r) => {
                          this.IDBranchRef = r;
                        }}
                        errors={errors}
                        key_value="id"
                        key_label="name"
                        disabled
                        data={listBranch}
                        include_blank="Tất cả phân đường"
                        onChange={this.handleChangeBranch}
                        defaultValue={
                          courseDetail &&
                          courseDetail.branchInfo &&
                          courseDetail.branchInfo.id > 0
                            ? courseDetail.branchInfo
                            : { id: 0, name: 'Tất cả phân đường' }
                        }
                      />
                    ) : (
                      <Select
                        label=""
                        className="form-control-select input-form form-control-product mb-3 input-grey"
                        name="branch_id"
                        ref={(r) => {
                          this.IDBranchRef = r;
                        }}
                        errors={errors}
                        key_value="id"
                        key_label="name"
                        data={listBranch}
                        include_blank="Tất cả phân đường"
                        onChange={this.handleChangeBranch}
                        defaultValue={
                          courseDetail &&
                          courseDetail.branchInfo &&
                          courseDetail.branchInfo.id > 0
                            ? courseDetail.branchInfo
                            : { id: 0, name: 'Tất cả phân đường' }
                        }
                      />
                    )}
                  </div>

                  <div className="col-md-6">
                    {listClub && listClubAll ? (
                      <Select
                        label=""
                        className="form-control-select input-form form-control-product mb-3 input-grey"
                        name="branch_id"
                        ref={(r) => {
                          this.IDClubRef = r;
                        }}
                        errors={errors}
                        key_value="id"
                        key_label="name"
                        include_blank={
                          listClub.length > 0
                            ? ''
                            : 'Tất cả Câu Lạc Bộ/Võ Đường'
                        }
                        data={
                          courseDetail &&
                          courseDetail.clubInfo &&
                          courseDetail.clubInfo.id > 0
                            ? listClubAll
                            : listClub
                        }
                        defaultValue={
                          courseDetail &&
                          courseDetail.clubInfo &&
                          courseDetail.clubInfo.id > 0
                            ? isChangeBranch == 0
                              ? courseDetail.clubInfo
                              : { id: 0, name: 'Tất cả Câu Lạc Bộ/Võ Đường' }
                            : { id: 0, name: 'Tất cả Câu Lạc Bộ/Võ Đường' }
                        }
                      />
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-add-title form-add-title-custom form-add-title--small">
                      <p>Bài học </p>
                    </div>
                    {errors && errors.attach_files ? (
                      <div className="form-add-error">
                        <p>{errors.attach_files}</p>
                      </div>
                    ) : null}

                    {numFiles.map((item, index) => (
                      <>
                        <div
                          className="row position-relative"
                          key={index}
                          id={`div-${index}`}
                        >
                          <div className="col-md-6">
                            <InputText
                              className="form-control input-form course-custom-input"
                              placeholder="Nhập tên bài"
                              name={`indexText${index}`}
                              ref={`indexText${index}`}
                              label="Tên Bài"
                              errors={errors}
                              type="text"
                              order={index}
                              defaultValue={item.title || ''}
                            />
                          </div>

                          <div className="col-md-6">
                            <div className="upload-wrap">
                              <GroupEbookSmall
                                label="Tài liệu"
                                title="Chọn file để tải"
                                name={`fileIndex${index}`}
                                ref={`fileIndex${index}`}
                                errors={errors}
                                onChange={(data) =>
                                  this.setState({
                                    urlFiles: {
                                      ...this.state.urlFiles,
                                      [index]: data
                                    }
                                  })
                                }
                                default={item.file != '' ? [item.file] : []}
                                order={index}
                              />
                              <div
                                className="cursor-pointer"
                                onClick={(e) => this.deleteFile(e, index)}
                              >
                                <img src={close} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>

                  <div className="col-12">
                    <ButtonSave
                      onClick={this.handleAddInput}
                      text="Thêm Bài"
                      className="btn btn-new ml-0 mb-4"
                    />
                  </div>

                  <div className="col-12">
                    <TextEditor
                      name="course_overview"
                      label="Tóm tắt"
                      errors={errors}
                      onChange={this.handleChangeSummary}
                      data={this.summary}
                    />
                  </div>

                  <div className="col-12">
                    <div className="form-add-buttons">
                      <ButtonSave
                        onClick={history.goBack}
                        text="Hủy"
                        className="btn btn-cancel"
                      />
                      <ButtonSave
                        onClick={this.onSubmit}
                        text="Lưu thông tin"
                        className="btn btn-new ml-0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

EditCourse.propTypes = {
  history: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(withRouter(EditCourse));
