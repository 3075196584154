import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  GET_CLB_DETAIL,
  GET_LIST_BRANCH,
  UPDATE_CLB
} from '../../constants/config';
import { destructServerErrors } from '../../helpers/error';
import {
  getDataByID,
  getTakenData,
  putData
} from '../../services/base_services';
import { LIMIT_POSITION } from '../../constants/position';

import ButtonSave from '../../component/common/ButtonSave';
import InputText from '../../component/common/InputText';
import LocationSearchInput from '../../component/common/LocationSearchInput';
import createNotification from '../../component/common/Notification';
import Select from '../../component/common/Select';
import TextArea from '../../component/common/TextArea';
import UploadImg from '../../component/common/UploadImg';
import TextEditor from '../../component/common/TextEditor';

const EditClub = (props) => {
  const { t } = useTranslation();

  const { history, match, user } = props;
  const positionId = user && user.user && user.user.position_id;
  const idClb = match && match.params && match.params.id;
  let formData = {};

  const nameRef = useRef('');
  const nameLeaderRef = useRef('');
  const emailRef = useRef('');
  const IDBranchRef = useRef('');
  const descriptionRef = useRef('');
  const typeRef = useRef('');
  const bgRef = useRef('');

  const [clbDetail, setClbDetail] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const [listBranch, setListBranch] = useState([]);
  const [errors, setErrors] = useState('');
  const [bgrImage, setBgrImage] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [address, setAddess] = useState('');
  const [map, setMap] = useState({ lng: 108.1760846, lat: 16.0748634 });
  const [flag, setFlag] = useState('');
  const [contextData, setContextData] = useState([]);
  const [position, setPosition] = useState(LIMIT_POSITION);

  const type = [
    {
      value: 0,
      name: t('clubs.clubs')
    },
    {
      value: 1,
      name: t('clubs.dojo')
    }
  ];

  const onSubmit = () => {
    const name = nameRef.current.value;
    const nameLeader = nameLeaderRef.current.value;
    const email = emailRef.current.value;
    const IDBranch = IDBranchRef.current.value;
    const description = contextData;
    const typeId = typeRef.current.value;
    const bgClb = bgRef.current.value || '';
    {
      formData = {
        name: name,
        club_leader: nameLeader,
        email: email,
        is_sendmail: isCheck,
        bgr_image: bgClb,
        branch_id: IDBranch,
        intro_club: description,
        bgr_image: bgrImage,
        lng: map.lng,
        lat: map.lat,
        address: address,
        type: typeId,
        flag_img: flag,
        position: position
      };

      putData(UPDATE_CLB, idClb, formData)
        .then((res) => {
          if (res.status === 200) {
            createNotification(t('clubs.success'), res.data.message);
            history.push('/statistical/club');
          } else return createNotification(t('clubs.error'), res.data.message);
        })
        .catch((err) => {
          const errss = destructServerErrors(err);
          setErrors(errss);
          if (err.response.status == 422) {
            return null;
          }
          return createNotification(t('clubs.error'), err.message);
        });
    }
  };
  const onCheck = () => {
    setIsCheck(!isCheck);
  };
  const getListBranch = async () => {
    await getTakenData(GET_LIST_BRANCH).then((res) => {
      setListBranch(res && res.data);
    });
  };

  const getClbDetail = (id) => {
    return getDataByID(GET_CLB_DETAIL, id).then((res) => {
      setClbDetail(res && res.data);
      if (res?.data?.bgr_image) {
        setBgrImage([res?.data?.bgr_image]);
      }
      setAddess(res?.data?.address);
      if (res?.data?.lng && res?.data?.lat) {
        setMap({
          lng: res?.data?.lng,
          lat: res?.data?.lat
        });
      }

      setFlag(res?.data?.flag);
      setPhotos(res?.data?.photos);
      res?.data?.position
        ? setPosition(res?.data?.position)
        : setPosition(LIMIT_POSITION);
      setLoading(false);
    });
  };

  const onChangeDocument = (e) => {
    setBgrImage(e);
  };

  useEffect(() => {
    getListBranch();
    getClbDetail(idClb);
  }, []);

  const changeEditer = (e, editor) => {
    const data = editor.getData();
    setContextData(data);
  };

  const handleChangePosition = (e) => {
    setPosition(e);
  };

  return isLoading ? (
    ''
  ) : (
    <>
      <section className="body-right-bottom">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>{t('clubs.header')}</p>
            </div>
            <div className="col-md-1 top-table-border "></div>
            <div className="col-md-12 top-table-text">{t('clubs.update')}</div>
          </div>
          <div className="content-form">
            <div className="row">
              <div className="col-md-12 content-title">
                <p>{t('clubs.update')}</p>
              </div>
            </div>

            <div className="row form-add">
              <div className="col-md-12">
                <div className="form-add-title">
                  <p>{t('clubs.infoClubs')}</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                {positionId == 3 ? (
                  <InputText
                    className="form-control input-form"
                    placeholder={t('clubs.nameClubs')}
                    name="name"
                    ref={nameRef}
                    disabled
                    label={t('clubs.nameClubs')}
                    errors={errors}
                    type="text"
                    required={true}
                    defaultValue={clbDetail && clbDetail.name}
                  />
                ) : (
                  <InputText
                    className="form-control input-form"
                    placeholder={t('clubs.nameClubs')}
                    name="name"
                    ref={nameRef}
                    label={t('clubs.nameClubs')}
                    errors={errors}
                    type="text"
                    required={true}
                    defaultValue={clbDetail && clbDetail.name}
                  />
                )}
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-6">
                {positionId == 3 ? (
                  <Select
                    label={t('clubs.clubs')}
                    className="form-control input-form form-control-product mb-3 input-grey input-form-select-custom"
                    name="type"
                    ref={typeRef}
                    errors={errors}
                    disabled
                    key_value="value"
                    key_label="name"
                    required={true}
                    data={type}
                    defaultValue={type[clbDetail && clbDetail.type]}
                  />
                ) : (
                  <Select
                    label={t('clubs.clubs')}
                    className="form-control-select input-form form-control-product mb-3 input-grey"
                    name="type"
                    ref={typeRef}
                    errors={errors}
                    key_value="value"
                    key_label="name"
                    include_blank={t('clubs.clubs')}
                    required={true}
                    data={type}
                    defaultValue={type[clbDetail && clbDetail.type]}
                  />
                )}
              </div>

              <div className="col-md-6">
                {positionId == 2 || positionId == 3 ? (
                  <Select
                    label={t('clubs.ofBranch')}
                    className="form-control-select input-form form-control-product mb-3 input-grey input-form-select-custom"
                    name="branch_id"
                    ref={IDBranchRef}
                    errors={errors}
                    disabled
                    key_value="id"
                    key_label="name"
                    include_blank={t('clubs.branch')}
                    required={true}
                    data={listBranch}
                    defaultValue={clbDetail && clbDetail.branch}
                  />
                ) : (
                  <Select
                    label={t('clubs.ofBranch')}
                    className="form-control-select input-form form-control-product mb-3 input-grey"
                    name="branch_id"
                    ref={IDBranchRef}
                    errors={errors}
                    key_value="id"
                    key_label="name"
                    include_blank={t('clubs.branch')}
                    required={true}
                    data={listBranch}
                    defaultValue={clbDetail && clbDetail.branch}
                  />
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <style jsx>
                  {`
                    textarea.input-form.form-control {
                      height: 100%;
                      max-height: 300px;
                      overflow-y: hidden;
                    }
                  `}
                </style>
                <TextEditor
                  name="description"
                  label={t('clubs.introduce')}
                  errors={errors}
                  onChange={changeEditer}
                  data={clbDetail?.intro_club}
                />
              </div>
              <div className="col-md-6">
                <label className="input-label">{t('clubs.imgBanner')}</label>
                <UploadImg
                  label={t('clubs.addImgBanner')}
                  name="bgr_image"
                  title={t('clubs.changeImgBanner')}
                  onChange={onChangeDocument}
                  errors={[]}
                  default={bgrImage}
                />
              </div>
              {/* <div className="col-md-12">
                <LocationSearchInput
                  label={t('clubs.address')}
                  address={address}
                  setAddess={setAddess}
                  map={map}
                  setMap={setMap}
                  setFlag={setFlag}
                  flag={clbDetail && clbDetail.flag_img}
                />
              </div> */}
            </div>
            <div className="row">
              <div className="col-md-6">
                {user?.position_id == 1 && (
                  <InputText
                    className="form-control input-form"
                    placeholder={t('clubs.position')}
                    name="posision"
                    onChange={(e) => handleChangePosition(e?.target?.value)}
                    label={t('clubs.labelPosition')}
                    errors={errors}
                    type="number"
                    defaultValue={position}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12  mt-4">
                <div className="form-add-buttons">
                  <Link to="/statistical/club">
                    <ButtonSave
                      text={t('clubs.cancel')}
                      className="btn btn-cancel"
                    />
                  </Link>
                  <ButtonSave
                    onClick={() => onSubmit()}
                    text={t('clubs.save')}
                    className="btn btn-new ml-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(withRouter(EditClub));

EditClub.propTypes = {
  history: PropTypes.isRequired
};
