import { postData, getTakenData } from '../services/base_services';

export const fetchClubs = (pageNumber) => {
  postData('/clubs', {
    keyword: '',
    page: pageNumber,
    // eslint-disable-next-line camelcase
    per_page: 5
  })
    .then((res) => {})
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const test = () => {
  return 0;
};

export const exportFile = () => {
  return getTakenData('/clubsexport')
    .then((res) => res.data)

    .catch((err) => {
      throw new Error(err.message);
    });
};

export const exportFileStudent = () => {
  return getTakenData('/usersexport')
    .then((res) => res.data)

    .catch((err) => {
      throw new Error(err.message);
    });
};

export const exportFileDonate = () => {
  return getTakenData('/donateexport')
    .then((res) => res.data)

    .catch((err) => {
      throw new Error(err.message);
    });
};
