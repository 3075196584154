import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  GET_LIST_CATEGORY,
  UPDATE_TITLE_CATEGORY
} from '../../constants/config';
import { getTakenData, putData } from '../../services/base_services';
import {
  destructServerErrors,
  destructServerMessage
} from '../../helpers/error';
import { showMessage } from '../../helpers/table';

import ButtonSave from '../../component/common/ButtonSave';
import InputText from '../../component/common/InputText';

class EditTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errors: [],
      title: ''
    };
  }

  componentDidMount = async () => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    if (id) {
      await getTakenData(GET_LIST_CATEGORY)
        .then((res) => {
          const filterData = res?.data?.find((item) => item?.id == id);
          this.setState({
            title: filterData?.title || '',
            isLoading: false
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  addNew = (e) => {
    e.preventDefault();
    const { title } = this.state;
    const { match, history } = this.props;
    const { params } = match;
    const { id } = params;

    const formData = {
      title: title
    };

    putData(UPDATE_TITLE_CATEGORY, id, formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({ errors: [], status: true });
        history.goBack();
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);
        this.setState({ errors: errss });
        if (err.response.status === 422) return null;
        showMessage(errs, false);
      });
  };
  handleCancel = () => {
    const { history } = this.props;
    history.push('/active/libraries/categories');
  };

  render() {
    const { errors, title, isLoading } = this.state;

    return (
      <>
        {!isLoading && (
          <div className="body-right-bottom">
            <div className="container-fluid container-shadow">
              <div className="row top-table">
                <div className="col-md-12 top-table-title">
                  <p>{this.props.t('title.manager')}</p>
                </div>
                <div className="col-md-1 top-table-border "></div>
                <div className="col-md-12 top-table-text">
                  {this.props.t('title.edit')}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 form-title">
                  <p> {this.props.t('title.editTitle')} </p>
                </div>
              </div>
              <div className="row"></div>
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('title.title')}
                      name="title"
                      value={title}
                      onChange={this.handleInputChange}
                      label={this.props.t('title.title')}
                      errors={errors}
                      type="text"
                      required={true}
                      ref={(input) => {
                        this.titleInput = input;
                      }}
                    />
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="d-flex mx-auto my-5">
                    <div
                      onClick={this.handleCancel}
                      onKeyPress={[]}
                      role="button"
                      tabIndex={0}
                    >
                      <ButtonSave
                        text={this.props.t('title.cancle')}
                        className="btn btn-cancel"
                      />
                    </div>
                    <ButtonSave
                      onClick={this.addNew}
                      text={this.props.t('title.save')}
                      className="btn btn-new ml-0"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </>
    );
  }
}

EditTitle.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withTranslation()(EditTitle)));
