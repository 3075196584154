import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

const Loading = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className={`${className}`}>
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <span className="ms-2">{t('loading.loading')}</span>
    </div>
  );
};

export default Loading;
