import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import store from '../store/store';
import Can from '../services/Can';

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const {
        user: { authenticated }
      } = store.getState();
      return authenticated ? (
        rest.meta ? (
          <Can I={rest.meta.action} a={rest.meta.subject}>
            <Component {...props} />
          </Can>
        ) : (
          <Component {...props} />
        )
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      );
    }}
  />
);

AuthRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.func
};

AuthRoute.defaultProps = {
  component: [],
  location: []
};

export default AuthRoute;
