import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import ButtonSave from '../../component/common/ButtonSave';
import Datepicker from '../../component/common/Datepicker';
import GroupFile from '../../component/common/GroupOneFile';
import InputText from '../../component/common/InputText';
import Select from '../../component/common/Select';
import {
  NEW_HISTORY_CLUB,
  GET_LIST_BRANCH,
  GET_CLUB_BRANCH,
  DETAIL_HISTORY_CLUB
} from '../../constants/config';
import { destructServerErrors } from '../../helpers/error';
import { getFormDataFromRef } from '../../helpers/form';
import { showMessage } from '../../helpers/table';
import {
  getTakenData,
  postData,
  getDataByID,
  putData
} from '../../services/base_services';

class AddAnotherStudent extends Component {
  constructor(props) {
    super(props);
    this.certificate_image = '';
    this.state = {
      status: false,
      udFlag: false,
      errors: [],
      listBranch: [],
      listClub: [],
      data: [],
      isLoading: true,
      defaultbranch: [],
      defaultClub: []
    };
  }

  addNew = (e) => {
    const { id, idstudent } = this.props.match.params;
    e.preventDefault();
    let formData = getFormDataFromRef(this.refs);
    formData = {
      ...formData,
      user_id: id,
      club_id: this.clubId.value,
      joined_at: this.joined_at.value.split('-').reverse().join('-'),
      left_at: this.left_at.value.split('-').reverse().join('-')
    };
    putData(NEW_HISTORY_CLUB, idstudent, formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({
          errors: [],
          status: true
        });
      })
      .catch((err) => {
        const errs = destructServerErrors(err);
        this.setState({
          errors: errs
        });
      });
  };

  onChangeDocument = (data) => {
    this.certificate_image = data;
  };

  componentDidMount = async () => {
    const { match, location } = this.props;
    const { params } = match;
    const { idstudent } = params;
    this.setState({
      udFlag: location.state.flg
    })
    if (idstudent) {
      await getDataByID(DETAIL_HISTORY_CLUB, idstudent)
        .then((res) => {
          this.setState({
            data: res.data,
            isLoading: false
          });
          return Promise.resolve({ res });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
    await this.getList();
  };

  getList = async () => {
    const { data } = this.state;

    await getTakenData(GET_LIST_BRANCH).then((res) => {
      this.setState({
        listBranch: res.data,
        defaultbranch: res.data.find((item) => item.id === data.branch_id)
      });
    });
    await getDataByID(GET_CLUB_BRANCH, data.branch_id).then((res) => {
      this.setState({
        listClub: res.data,
        defaultClub: res.data.find((item) => item.id === data.club_id)
      });
    });
  };
  selectBranch = async (e) => {
    const branchId = e.target.value;
    await getDataByID(GET_CLUB_BRANCH, branchId).then((res) => {
      this.setState({
        listClub: res.data
      });
    });
  };
  render() {
    const {
      status,
      errors,
      listClub,
      listBranch,
      data,
      isLoading,
      defaultClub,
      defaultbranch,
      udFlag
    } = this.state;
    const { history } = this.props;
    if (status) {
      return (
        <Redirect
          to={{
            pathname: `/statistical/student/history/${this.props.match.params.id}`
          }}
        />
      );
    }
    return isLoading ? (
      ''
    ) : (
      <>
        <div className="body-right-bottom">
          <div className="container-fluid content">
            <div className="row top-table">
              <div className="col-md-12 top-table-title">
                <p>Thống Kê</p>
              </div>
              <div className="col-md-1 top-table-border "></div>
              <div className="col-md-12 top-table-text">Lịch sử hoạt động</div>
            </div>
            <div className="content-form">
              <div className="row">
                <div className="col-md-12 content-title">
                  <p>Cập nhật thông tin sinh hoạt :</p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <Select
                    label="Phân đường (*):"
                    className="form-control input-form form-control-product mb-3 input-grey input-form-select"
                    name="branch_id"
                    ref={(c) => {
                      this.branchId = c;
                    }}
                    errors={errors}
                    key_value="id"
                    key_label="name"
                    include_blank="Phân đường"
                    data={listBranch}
                    onChange={this.selectBranch}
                    defaultValue={defaultbranch}
                  />
                </div>
                <div className="col-md-6">
                  <Select
                    label="Câu Lạc Bộ/Võ Đường :"
                    className="form-control input-form form-control-product mb-3 input-grey input-form-select"
                    name="club_id"
                    ref={(c) => {
                      this.clubId = c;
                    }}
                    errors={errors}
                    key_value="id"
                    key_label="name"
                    include_blank="Câu Lạc Bộ/Võ Đường"
                    data={listClub}
                    defaultValue={defaultClub}
                  />
                </div>
                <div className="col-md-6">
                  <Datepicker
                    label="Ngày Bắt Đầu:"
                    className="form-control input-form"
                    name="joined_at"
                    ref={(c) => {
                      this.joined_at = c;
                    }}
                    errors={errors}
                    clearIcon={false}
                    format="dd-MM-yyyy"
                    defaultValue={data && data.joined_at}
                  />
                </div>

                <div className="col-md-6">
                  <Datepicker
                    label="Ngày Kết Thúc:"
                    className="form-control input-form"
                    name="left_at"
                    ref={(c) => {
                      this.left_at = c;
                    }}
                    errors={errors}
                    clearIcon={false}
                    format="dd-MM-yyyy"
                    defaultValue={data && data.left_at}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-add-buttons">
                    {udFlag && <ButtonSave
                      onClick={this.addNew}
                      text="Lưu thông tin"
                      className="btn btn-new ml-0"
                    />}
                    <div
                      onClick={history.goBack}
                      onKeyPress={[]}
                      role="button"
                      tabIndex={0}
                    >
                      <ButtonSave text="Hủy" className="btn btn-cancel" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

AddAnotherStudent.propTypes = {
  history: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(withRouter(AddAnotherStudent));
