/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { GET_LIST_CONTACT } from '../../constants/config';
import { PER_PAGE } from '../../constants/variable';
import { postData } from '../../services/base_services';

import Table from '../../component/common/Table';
import Paginate from '../../component/Paginate';

const ContactTable = (props) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [tableData, setTableData] = useState([]);
  const [paginate, setPaginate] = useState([]);

  const [req, setReq] = useState({
    page: queryParams.get('page') || 1,
    per_page: queryParams.get('perpage') || PER_PAGE,

    name: '',
    phone: '',
    email: '',
    message: ''
  });

  const tableHeaders = [
    {
      label: 'STT',
      index: 'stt',
      option: {
        className: 'text-center position-relative'
      },
      callback: null
    },
    {
      label: 'Họ Và Tên',
      index: null,
      option: {
        className: ''
      },
      callback: (data) => {
        return (
          <div>
            <span>{data && data.name}</span>
          </div>
        );
      }
    },
    {
      label: 'Số Điện Thoại',
      index: null,
      option: {
        className: 'text-center'
      },
      callback: (data) => {
        return (
          <div>
            <span>{data && data.phone}</span>
          </div>
        );
      }
    },
    {
      label: 'Email',
      index: null,
      option: {
        className: ''
      },
      callback: (data) => {
        return (
          <div>
            <span>{data && data.email}</span>
          </div>
        );
      }
    },
    {
      label: 'Lời Nhắn',
      index: null,
      option: {
        className: 'width-30'
      },
      callback: (data) => {
        return (
          <div>
            <span>{data && data.message}</span>
          </div>
        );
      }
    }
  ];

  const getListContact = async (newReq) => {
    await postData(GET_LIST_CONTACT, newReq)
      .then((res) => res && res.data)
      .then((res) => {
        setPaginate({ ...res.paginate, perPage: req?.per_page });
        setTableData(res && res.data);
      });
  };

  const updateURL = (pageNumber, perpage) => {
    history.push(`?page=${pageNumber}&perpage=${perpage}`);
  };

  const changePage = (pageNumber) => {
    setReq({
      ...req,
      page: pageNumber
    });
    updateURL(pageNumber, req?.per_page);
  };

  const changePerPage = (value) => {
    setReq({
      ...req,
      page: 1,
      per_page: value
    });
    updateURL(1, value);
  };

  useEffect(() => {
    getListContact(req);
  }, [req]);

  return (
    <>
      <section className="body-right-bottom post-web-content-wrap course-wrap">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>Quản Lý Liên Hệ </p>
            </div>
            <div className="col-md-1 top-table-border "></div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                tableData={tableData}
                tableHeaders={tableHeaders}
                tablePaginate={paginate}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate
                  paginate={paginate}
                  changePage={changePage}
                  per_page={req?.per_page || PER_PAGE}
                  tablePaginate={paginate}
                  changePerPage={changePerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(ContactTable);

ContactTable.propTypes = {
  history: propTypes.isRequired
};
