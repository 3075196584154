import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';

import { uploadDocument } from '../../helpers/form';
import { UPLOAD_FILE_STUDENTS } from '../../constants/config';
import { postData } from '../../services/base_services';
import { showMessage } from '../../helpers/table';

import uploadIcon from '../../assets/images/icon/upload-icon.svg';

const UploadFileZip = ({ order }) => {
  const [loading, setLoading] = useState(false);

  const onChangeButton = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setLoading(true);

    uploadDocument(file)
      .then((res) => {
        setLoading(true);
        postData(UPLOAD_FILE_STUDENTS, {
          file: res?.data?.attach_files?.[0]
        })
          .then(() => {
            setLoading(false);
            showMessage('Upload thành công', true);
          })
          .catch(() => {
            showMessage('UpLoad Lỗi', false);
            setLoading(false);
          });
      })
      .catch(() => {
        showMessage('UpLoad Lỗi', false);
        setLoading(false);
      });
  };

  const clickUpload = () => {
    document.querySelector(`#upload-input-${order}`).click();
  };

  return (
    <div className="upload-field-zip up-gr-img">
      <input
        style={{ border: '1px solid red !important' }}
        type="file"
        accept=".zip"
        className="upload-input"
        id={`upload-input-${order}`}
        onChange={onChangeButton}
        onClick={(e) => (e.target.value = null)}
      />

      {loading ? (
        <div className="upload-file-area">
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span>...Loading</span>
        </div>
      ) : (
        <div
          className="upload-file-area"
          onClick={clickUpload}
          style={{ border: '1px solid red !important', cursor: 'pointer' }}
        >
          <p>Upload file</p>
          <ReactSVG src={uploadIcon} />
        </div>
      )}
    </div>
  );
};

export default UploadFileZip;
