import { BASE_IMG, UPLOAD_URL } from '../constants/config';
import { postDataMultipart } from '../services/base_services';
class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
    this.upload = this.upload.bind(this);
    this.abort = this.abort.bind(this);
  }
  upload() {
    return this.loader.file.then((uploadedFile) => {
      return new Promise((resolve) => {
        const data = new FormData();
        console.log(this.loader);
        data.append('image', uploadedFile);
        postDataMultipart(UPLOAD_URL, data)
          .then((res) => {
            resolve({
              default: BASE_IMG + res.data.src
            });
          })
          .catch((error) => {
            alert(error);
            return Promise.reject(error);
          });
      });
    });
  }

  abort() {
    // Reject promise returned from upload() method.
  }
}
export default UploadAdapter;
