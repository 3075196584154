import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import {
  GET_LIST_CLUB,
  DELETE_CLB,
  GET_GROUP_BRANCH_REQUEST,
  GET_BRANCH_BY_ID
} from '../../constants/config';
import { PER_PAGE } from '../../constants/variable';
import {
  deleteById,
  postDataWithParams,
  getTakenData,
  getDataByID
} from '../../services/base_services';
import { exportFile } from '../../actions/club';
import Can from '../../services/Can';
import { destructServerMessage } from '../../helpers/error';
import { showMessage } from '../../helpers/table';
import { BASE_URL } from '../../constants/config';
import { confirmDelete } from '../../helpers/table';

import Table from '../../component/common/Table';
import NewButton from '../../component/common/NewButton';
import InputSearch from '../../component/common/InputSearch';
import Select from '../../component/common/Select';
import createNotification from '../../component/common/Notification';
import Paginate from '../../component/Paginate';

import editIcon from '../../assets/images/icon/edit.svg';
import deleteIcon from '../../assets/images/icon/delete-kn.svg';
import detail from '../../assets/images/images/detail.svg';
import uploadIcon from '../../assets/images/icon/upload-icon.svg';

const Club = (props) => {
  const { t } = useTranslation();
  const { user } = props;

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [tableData, setTableData] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [numberDelete, setNumberDelete] = useState(0);
  const [perPage, setPerPage] = useState(queryParams.get('perpage') || 10);

  const [area, setArea] = useState([]);
  const [branch, setBranch] = useState([
    {
      id: 0,
      name: t('clubs.branch')
    }
  ]);
  const [filter, setFilter] = useState({
    page: queryParams.get('page') || 1,
    per_page: perPage,
    keyword: '',
    area: 0,
    branch: ''
  });

  const tableHeaders = [
    {
      label: t('clubs.stt'),
      index: 'stt',
      option: {
        className: 'text-center position-relative'
      },
      callback: null
    },
    {
      label: t('clubs.nameClubs'),
      index: null,
      callback: (data) => {
        if (data && data.name !== null)
          return (
            <div>
              <span>{data && data.name}</span>
            </div>
          );
        return (
          <div className="text-center">
            <span>{' - '}</span>
          </div>
        );
      }
    },
    {
      label: t('clubs.manageClub'),
      index: null,
      callback: (data) => {
        if (data && data.club_leader)
          return (
            <div>
              <span>{data && data.club_leader}</span>
            </div>
          );
        return (
          <div className="text-center">
            <span>{' - '}</span>
          </div>
        );
      }
    },
    {
      label: t('clubs.ofBranch'),
      index: null,
      callback: (data) => {
        if (data && data.branch && data.branch.name !== null)
          return (
            <div>
              <span>{data && data.branch && data.branch.name}</span>
            </div>
          );
        return (
          <div className="text-center">
            <span>{' - '}</span>
          </div>
        );
      }
    },
    {
      label: t('clubs.student'),
      index: null,
      option: {
        className: 'text-center'
      },
      callback: (data) => {
        if (data && data.num_member !== null)
          return (
            <div>
              <span>{data && data.num_member}</span>
            </div>
          );
        return (
          <div className="text-center">
            <span>{' - '}</span>
          </div>
        );
      }
    },
    {
      label: t('clubs.action'),
      index: null,
      option: {
        className: 'text-center fixed-collumn'
      },
      callback: (data) => {
        if (data && data.id) {
          return (
            <div className="btn-group">
              <span
                data-tip={t('clubs.clubDetail')}
                className="link-action ml-2 mr-2"
              >
                <Link
                  className="d-block"
                  role="button"
                  tabIndex={0}
                  onClick={() => onEditClub(data && data.id)}
                  onKeyPress={() => {}}
                  to={`/statistical/club/id/${data && data.id}`}
                >
                  <img src={detail} alt="edit" />
                </Link>
              </span>
              <Can I="club_edit" a="club">
                <span
                  data-tip={t('clubs.editClubs')}
                  className="link-action ml-2 mr-2"
                >
                  <Link
                    role="button"
                    className="d-block"
                    tabIndex={0}
                    onClick={() => onEditClub(data && data.id)}
                    onKeyPress={() => {}}
                    to={`/statistical/club/edit/${data && data.id}`}
                  >
                    <img src={editIcon} alt="edit" className="btn-icon" />
                  </Link>
                </span>
              </Can>
              <Can I="club_del" a="club">
                <span
                  data-tip={t('clubs.deleteClubs')}
                  className="link-action ml-2"
                >
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      onDeleteClub(data && data.id, data && data.name)
                    }
                    onKeyPress={() => {}}
                  >
                    <img alt="delete" src={deleteIcon} className="btn-icon" />
                  </div>
                </span>
              </Can>
              <ReactTooltip
                className="tooltip-button"
                place="bottom"
                effect="float"
              />
            </div>
          );
        }
        return '';
      }
    }
  ];

  const deleteClb = (id, name) => {
    deleteById(DELETE_CLB, id)
      .then(() => {
        setNumberDelete(numberDelete + 1);
        createNotification(
          t('clubs.success'),
          `${t('clubs.deleteSuccess')} ${name}`
        );
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        showMessage(errs, false);
      });
    setFilter({ ...filter, keyword: '', page: 1 });
  };
  const onEditClub = (id) => {};
  const onDeleteClub = (id, name) => {
    confirmDelete().then((res) => {
      if (res && res.value) {
        deleteClb(id, name);
      }
    });
  };

  const renderExcel = () => {
    exportFile().then((res) => {
      if (res?.status === 'OK') {
        window.open(`${BASE_URL}download?file=/uploads/clubs.csv&name=clubs`);
      }
    });
  };

  useEffect(() => {
    postFilter(filter);
    getTakenData(GET_GROUP_BRANCH_REQUEST).then((res) => {
      setArea([
        {
          id: 0,
          name: t('clubs.area')
        },
        ...res?.data
      ]);
    });
  }, [filter]);

  const getBranchByArea = (id) => {
    getDataByID(GET_BRANCH_BY_ID, id)
      .then((res) => {
        setBranch([
          {
            id: '',
            name: t('clubs.branch')
          },
          ...res?.data
        ]);
      })
      .catch((error) => showMessage(`${t('dashboard.error')} ${error}`), false);
  };

  const postFilter = (param) => {
    postDataWithParams(GET_LIST_CLUB, param)
      .then((res) => {
        setTableData(res?.data?.data);
        setPaginate({ ...res?.data?.paginate, perPage: perPage });
      })
      .catch((error) => showMessage(`${t('dashboard.error')} ${error}`), false);
  };

  const updateURL = (pageNumber, perpage) => {
    history.push(`?page=${pageNumber}&perpage=${perpage}`);
  };

  const handleChangeArea = (e) => {
    getBranchByArea(e.target.value);
    setFilter({ ...filter, page: 1, area: e.target.value, branch: '' });
    postFilter({ ...filter, page: 1, area: e.target.value, branch: '' });
  };

  const handleChangeBranch = (e) => {
    setFilter({ ...filter, page: 1, branch: e.target.value });
    postFilter({ ...filter, page: 1, branch: e.target.value });
  };

  const handleChangeSearch = (e) => {
    setFilter({ ...filter, page: 1, keyword: e.target.value });
    postFilter({ ...filter, page: 1, keyword: e.target.value });
  };

  const handleChangePage = (pageNumber) => {
    if (pageNumber !== paginate.current_page) {
      setPaginate({ ...paginate, page: pageNumber });
      setFilter({ ...filter, page: pageNumber, per_page: perPage });
      updateURL(pageNumber, perPage);
      postFilter({ ...filter, page: pageNumber, per_page: perPage });
    }
  };

  const changePerPage = (value) => {
    setPerPage(value);
    setFilter({ ...filter, page: 1, per_page: value });

    updateURL(1, perPage);
    postFilter({ ...filter, page: 1, per_page: value });
  };

  return (
    <>
      <section className="body-right-bottom course-wrap">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>{t('clubs.header')}</p>
            </div>
            <div className="col-md-1 top-table-border "></div>
            <div className="col-md-12 top-table-text">
              {t('clubs.headerForm')}
            </div>
          </div>

          <div className="row header-wrap">
            <div className="col-sm-12 col-md-3 col-lg-4">
              {user?.position_id != 3 && (
                <InputSearch onChange={(e) => handleChangeSearch(e)} />
              )}
            </div>
            <div className="col-sm-12 col-md-3 col-lg-4">
              {user?.position_id == 1 && (
                <Select
                  className="form-control-select input-form col-md-3"
                  name="name"
                  key_value="id"
                  key_label="name"
                  data={area}
                  errors={[]}
                  value={area?.id}
                  onChange={(e) => {
                    handleChangeArea(e);
                  }}
                />
              )}
            </div>
            <div className="col-sm-12 col-md-3 col-lg-4">
              {user?.position_id == 1 && (
                <Select
                  className="form-control-select input-form col-md-3"
                  name="name"
                  key_value="id"
                  key_label="name"
                  data={branch}
                  errors={[]}
                  value={filter.branch}
                  onChange={(e) => {
                    handleChangeBranch(e);
                  }}
                />
              )}
            </div>
          </div>

          <div className="row header-wrap">
            <div></div>
            <div className="col-sm-12 col-md-4 col-lg-4 content__flex-end">
              {user?.position_id != 3 && (
                <button className="donate-list" onClick={renderExcel}>
                  Xuất file
                  <ReactSVG src={uploadIcon} className="naviga-icon" />
                </button>
              )}

              <Can I="club_add" a="club">
                <Link to="/statistical/club/addClub">
                  <NewButton />
                </Link>
              </Can>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                tableData={tableData}
                tableHeaders={tableHeaders}
                tablePaginate={paginate}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                {user?.position_id != 3 && (
                  <Paginate
                    paginate={paginate}
                    changePage={handleChangePage}
                    per_page={perPage || 10}
                    tablePaginate={paginate}
                    changePerPage={changePerPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.user?.user
  };
};

export default connect(mapStateToProps, null)(withRouter(Club));
