/* eslint-disable react/display-name */
import React from 'react';
import { Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import Form from '../container/Form/FormTable';
import FormNew from '../container/Form/FormAdd';
import FormEdit from '../container/Form/FormEdit';

const routes = [
  {
    path: '/form',
    component: () => <Form />,
    exact: true,
    meta: {
      action: 'form_managerment',
      subject: 'form'
    }
  },
  {
    path: '/form/new',
    component: () => <FormNew />,
    exact: true
    // meta: {
    //   action: 'donate_managerment',
    //   subject: 'donate'
    // }
  },
  {
    path: '/form/edit/:id',
    component: () => <FormEdit />,
    exact: true
    // meta: {
    //   action: 'donate_managerment',
    //   subject: 'donate'
    // }
  }
];

const AdsRoutes = () => {
  if (typeof window !== 'undefined') {
    return (
      <>
        <Switch>
          {routes.map((route) => (
            <AuthRoute key={route.path} {...route} />
          ))}
        </Switch>
      </>
    );
  }
  return '';
};

export default AdsRoutes;
