/* eslint-disable react/display-name */
import React from 'react';
import { Switch } from 'react-router-dom';
import { ADMIN_ROUTES } from '../constants/router';

import TableSeri from '../container/Seri/TableSeri';
import NewSeri from '../container/Seri/NewSeri';
import EditSeri from '../container/Seri/EditSeri';
import DetailDocument from '../container/Document/DetailDocument';

import AuthRoute from './AuthRoute';

const routes = [
  {
    path: ADMIN_ROUTES.ACTIVE_SERI,
    component: () => <TableSeri />,
    exact: true,
    meta: {
      action: 'information_all_view',
      subject: 'information'
    }
  },
  {
    path: `${ADMIN_ROUTES.ACTIVE_SERI}/new`,
    component: () => <NewSeri />,
    exact: true
  },
  {
    path: `${ADMIN_ROUTES.ACTIVE_SERI}/edit/:id`,
    component: () => <EditSeri />,
    exact: true
  }
];

const SeriRoutes = () => {
  if (typeof window !== 'undefined') {
    return (
      <>
        <Switch>
          {routes.map((route) => (
            <AuthRoute key={route.path} {...route} />
          ))}
        </Switch>
      </>
    );
  }
  return '';
};

export default SeriRoutes;
