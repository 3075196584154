import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import ButtonSave from '../../component/common/ButtonSave';
import Datepicker from '../../component/common/Datepicker';
import InputPhone from '../../component/common/InputPhone';
import InputText from '../../component/common/InputText';
import createNotification from '../../component/common/Notification';
import Select from '../../component/common/Select';
import TextEditor from '../../component/common/TextEditor';
import { GET_DETAIL_FORM, UPDATE_FORM } from '../../constants/config';
import { destructServerErrors } from '../../helpers/error';
import { putData, getDataByID } from '../../services/base_services';
import GroupEbookSmall from '../../component/common/GroupEbookSmall';
import GroupPhoto from '../../component/common/GroupOneFile';
import GroupPhotoTwo from '../../component/common/GroupPhoto';

const NewAds = (props) => {
  const { history, match } = props;
  const id = match && match.params && match.params.id;
  let formData = {};
  const [isLoading, setLoading] = useState(true);
  const [detail, setDetail] = useState([]);
  const titleRef = useRef('');
  const bgr = useRef([]);
  const typeRef = useRef();
  const bannerImg = useRef();
  const [errors, setErrors] = useState([]);
  const [document, setDocument] = useState([]);
  const [typeF, setTypeF] = useState(2);
  const [contentText, setContentText] = useState('');

  const type = [
    {
      name: 'Về nghĩa dũng',
      value: 0
    },
    {
      name: 'Quy trình nghĩa dũng',
      value: 1
    },
    {
      name: 'Nguồn gốc và Lịch sữ',
      value: 2
    },
    {
      name: 'Hệ thống phân đường',
      value: 3
    },
    {
      name: 'Kỹ thuật',
      value: 4
    },
    {
      name: 'Sinh hoạt',
      value: 5
    },
    {
      name: 'Thư viện',
      value: 6
    },
    {
      name: 'Điều khoản sử dụng',
      value: 7
    }
  ];

  const onSubmit = () => {
    const title = titleRef.current.value;
    const attach_files = document;

    {
      if(typeF == 2 ) {
        formData = {
          title,
          attach_files,
          type: parseInt(typeRef.current.value),
          content: contentText,
          img_banner: bannerImg.current.value ? bannerImg.current.value : detail?.img_banner
        };
      } else {
        formData = {
          title,
          attach_files,
          type: parseInt(typeRef.current.value),
          content: contentText
        };
      }

      putData(UPDATE_FORM, id, formData)
        .then((res) => {
          createNotification('success', `Bạn đã thêm thành công`);
          setErrors('');
          history.push('/form');
        })
        .catch((err) => {
          const errss = destructServerErrors(err);
          setErrors(errss);
          if (err.response.status == 422) {
            return null;
          }
          return createNotification('error', err.message);
        });
    }
  };
  useEffect(() => {
    getDetail();
  }, []);
  const changeEditer = (e, editor) => {
    const data = editor.getData();
    setContentText(data);
  };

  const getDetail = async () => {
    getDataByID(GET_DETAIL_FORM, id).then((res) => {
      setDetail(res.data);
      setDocument(res.data.attach_files);
      setLoading(false);
      setTypeF(res.data.type);
      setContentText(res.data.content);
    });
  };
  return isLoading ? (
    ''
  ) : (
    <>
      <section className="body-right-bottom">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>Quản Lý Quy Trình , Biểu Mẫu </p>
            </div>
            <div className="col-md-1 top-table-border "></div>
            <div className="col-md-12 top-table-text">Chỉnh sửa</div>
          </div>

          <div className="content-form">
            <div className="row">
              <div className="col-md-12 content-title">
                <p>Chỉnh sửa Biểu Mẫu</p>
              </div>
            </div>
            <div className="form-add">
              <div className="row">
                <div className="col-md-6">
                  <Select
                    label="Loại Biểu Mẫu (*) :"
                    className="form-control input-form form-control-product mb-3 input-grey input-form-select-custom"
                    name="type"
                    ref={typeRef}
                    errors={errors}
                    key_value="value"
                    key_label="name"
                    include_blank="Loại Biểu Mẫu"
                    data={type}
                    defaultValue={type[detail && detail.type]}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <InputText
                    label="Tiêu đề : (*) "
                    className="form-control input-form"
                    placeholder="Tiêu đề"
                    name="title"
                    ref={titleRef}
                    errors={errors}
                    type="text"
                    defaultValue={detail && detail.title}
                  />
                </div>
                {typeF == 1 && (
                  <div className="col-md-12 course">
                    <GroupEbookSmall
                      label="Upload file PDF"
                      name="attach_files"
                      ref={bgr}
                      title="Chọn ảnh để tải"
                      errors={errors}
                      default={document}
                      onChange={(data) => setDocument(data)}
                    />
                  </div>
                )}
                {typeF != 1 && (
                  <>
                    <div className="col-md-6">
                      <GroupPhoto
                        label="Chọn ảnh để tải"
                        name="attach_files"
                        ref={bgr}
                        title="Chọn ảnh để tải"
                        errors={errors}
                        default={document}
                        onChange={(data) => setDocument(data)}
                      />
                    </div>
                    {
                      typeF == 2 && <div className="col-md-6">
                        <GroupPhotoTwo
                          label="Ảnh bìa (*)"
                          name="img_banner"
                          ref={bannerImg}
                          title="Chọn ảnh để tải"
                          errors={errors}
                          order={2}
                          defaultValue={detail?.img_banner}
                        />
                      </div>
                    }
                    <div className="col-md-12">
                      <TextEditor
                        name="content"
                        label="Nội dung (*) : "
                        errors={errors}
                        onChange={changeEditer}
                        data={detail && detail.content}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="row mt-2">
                <div className="col-12">
                  <div className="form-add-buttons">
                    <ButtonSave
                      onClick={() => onSubmit()}
                      text="Lưu thông tin"
                      className="btn btn-new ml-0"
                    />
                    <ButtonSave
                      onClick={history.goBack}
                      text="Hủy"
                      className="btn btn-cancel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(NewAds);

NewAds.propTypes = {
  history: PropTypes.isRequired
};
