import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { deleteById, getTakenData } from '../../services/base_services';
import {
  DELETE_TITLE_CATEGORY,
  GET_LIST_CATEGORY
} from '../../constants/config';

import NewButton from '../../component/common/NewButton';
import Table from '../../component/common/TableCategories';
import Can from '../../services/Can';

import { confirmDelete, showMessage } from '../../helpers/table';

import editIcon from '../../assets/images/icon/edit.svg';
import deleteIcon from '../../assets/images/images/delete.png';

class TableTitle extends Component {
  constructor(props) {
    super(props);
    this.keyword = '';
    this.state = {
      isLoading: true,
      perPage: 10,
      paginate: [],
      tableData: [],
      tableHeaders: [
        {
          label: this.props.t('library.stt'),
          index: 'stt',
          option: { className: 'text-center position-relative' }
        },
        {
          label: this.props.t('library.title'),
          option: { className: 'width-300' },
          callback: (data) => (
            <div>
              <span>{data?.title}</span>
            </div>
          )
        },
        {
          label: this.props.t('library.action'),
          index: null,
          option: { className: 'text-center fixed-column' },
          callback: (data) => this.renderActions(data)
        }
      ]
    };
  }

  componentDidMount = () => {
    this.getListDocument();
  };

  getListDocument = async () => {
    try {
      const res = await getTakenData(GET_LIST_CATEGORY);
      this.setState({
        tableData: res?.data,
        isLoading: false
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      this.setState({ isLoading: false });
    }
  };

  onEditStudent = (id) => {
    const { history } = this.props;
    history.push(`/active/libraries/edit-title/${id}`);
  };

  onDeleteStudent = (id) => {
    confirmDelete().then((res) => {
      if (res.value) {
        this.removeStudentRequest(id);
      }
    });
  };

  removeStudentRequest = async (id) => {
    try {
      await deleteById(DELETE_TITLE_CATEGORY, id);
      showMessage('Deleted title successfully', true);
      this.getListDocument();
    } catch (error) {
      console.error('Error deleting data:', error);
      showMessage('Error deleting data');
    }
  };

  renderActions = (data) => (
    <div className="btn-group">
      <Can I="library_document_edit" a="library">
        <span
          data-tip={this.props.t('title.edit')}
          className="link-action ml-2 mr-2"
        >
          <div
            role="button"
            tabIndex={0}
            onClick={() => this.onEditStudent(data.id)}
          >
            <img alt="edit" src={editIcon} className="btn-icon" />
          </div>
        </span>
      </Can>
      <Can I="library_document_del" a="library">
        <span
          data-tip={this.props.t('title.delete')}
          className="link-action ml-2"
        >
          <div
            role="button"
            tabIndex={0}
            onClick={() => this.onDeleteStudent(data.id)}
          >
            <img alt="delete" src={deleteIcon} className="btn-icon" />
          </div>
        </span>
      </Can>
    </div>
  );

  render() {
    const { tableHeaders, tableData, isLoading } = this.state;

    return (
      <div className="body-right-bottom course-wrap">
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>{this.props.t('title.manager')}</p>
            </div>
            <div className="col-md-1 top-table-border"></div>
            <div className="col-md-12 top-table-text">
              {this.props.t('title.menu')}
            </div>
          </div>

          <div className="row header-wrap">
            <div className="col-12 content__flex-end">
              <Can I="library_document_add" a="library">
                <Link to="/active/libraries/new-title">
                  <NewButton />
                </Link>
              </Can>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                fixed
                tableHeaders={tableHeaders}
                tableData={tableData}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TableTitle.propTypes = {
  history: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(
  mapStateToProps,
  null
)(withRouter(withTranslation()(TableTitle)));
