/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import NavStudent from './NavStudent';
import { PER_PAGE } from '../../constants/variable';
import InputSearch from '../../component/common/InputSearch';
import NewButton from '../../component/common/NewButton';
import Paginate from '../../component/Paginate/index';
import Table from '../../component/common/TableStudent';
import {
  DELETE_HISTORY_CLUB,
  UPDATE_USER_APP,
  ACCEPT_USER,
  GET_LIST_HISTORY_CLUB
} from '../../constants/config';
import {
  deleteById,
  putDataAcceptUser,
  postDataByID
} from '../../services/base_services';
import { confirmDelete, showMessage, checkUser } from '../../helpers/table';
import editIcon from '../../assets/images/icon/view-kn.svg';
import deleteIcon from '../../assets/images/images/delete.png';
import ReactTooltip from 'react-tooltip';
import { formatDate } from '../../helpers/form';
import Can from '../../services/Can';

class HistoryStudent extends Component {
  constructor(props) {
    super(props);
    this.keyword = '';
    this.state = {
      isLoading: true,
      perPage: PER_PAGE,
      paginate: [],
      tableData: [],
      udFlag: false,
      tableHeaders: [
        {
          label: 'STT',
          index: 'stt',
          option: {
            className: 'text-center position-relative'
          },
          callback: null
        },
        {
          label: 'tên Câu Lạc Bộ/Võ Đường',
          index: null,
          callback: (data) => {
            if (data && data.infoClub && data.infoClub.name !== null)
              return (
                <div>
                  <span>{data && data.infoClub && data.infoClub.name}</span>
                </div>
              );
            return (
              <div className="text-center">
                <span>{' - '}</span>
              </div>
            );
          }
        },
        {
          label: 'Thuộc phân đường',
          index: null,
          option: {
            className: 'text-center'
          },
          callback: (data) => {
            if (data && data.branch && data.branch.name !== null)
              return (
                <div>
                  <span>{data && data.branch && data.branch.name}</span>
                </div>
              );
            return (
              <div className="text-center">
                <span>{' - '}</span>
              </div>
            );
          }
        },
        {
          label: 'Bắt đầu',
          index: null,
          callback: (data) => {
            if (data && data.joined_at !== null)
              return (
                <div>
                  <span>
                    {data && data.joined_at && formatDate(data.joined_at)}
                  </span>
                </div>
              );
            return (
              <div className="text-center">
                <span>{' - '}</span>
              </div>
            );
          }
        },
        {
          label: 'Kết thúc',
          index: null,
          callback: (data) => {
            if (data && data.left_at !== null)
              return (
                <div>{data && data.left_at && formatDate(data.left_at)}</div>
              );
            return (
              <div className="text-center">
                <span>{' - '}</span>
              </div>
            );
          }
        },
        {
          label: 'CHỨC NĂNG',
          index: null,
          option: {
            className: 'text-center fixed-collumn'
          },
          callback: (data) => {
            if (data.id) {
              return (
                <div className="btn-group">
                  <Can I="user_history_edit" a="user">
                    {data.ud_flag && (
                      <span
                        data-tip="Chỉnh Sửa Lịch Sử"
                        className="link-action ml-2 mr-2"
                      >
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => {
                            this.onEditStudent(data.id, data.ud_flag);
                          }}
                          onKeyPress={() => {}}
                        >
                          <img alt="edit" src={editIcon} className="btn-icon" />
                        </div>
                      </span>
                    )}
                  </Can>
                  <Can I="user_history_del" a="user">
                    {data.ud_flag && (
                      <span data-tip="Xóa Lịch Sử" className="link-action ml-2">
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => this.onDeleteStudent(data.id)}
                          onKeyPress={() => {}}
                        >
                          <img
                            alt="delete"
                            src={deleteIcon}
                            className="btn-icon"
                          />
                        </div>
                      </span>
                    )}
                  </Can>
                  <ReactTooltip
                    className="tooltip-button"
                    place="bottom"
                    effect="float"
                  />
                </div>
              );
            }
            return '';
          }
        }
      ]
    };
  }

  componentDidMount = () => {
    this.getListStudent();
  };

  onEditStudent = (id, flg) => {
    const { history } = this.props;
    history.push({
      pathname: `/statistical/student/${this.props.match.params.id}/history/edit/${id}`,
      state: flg
    });
  };

  onDeleteStudent = (id) => {
    confirmDelete().then((res) => {
      if (res.value === true) {
        this.removeStudentRequest(id);
      }
    });
  };

  onCheckStudent = (id) => {
    checkUser().then((res) => {
      if (res.value === true) {
        putDataAcceptUser(UPDATE_USER_APP, ACCEPT_USER, id)
          .then((res) => {
            showMessage(res.data.message, true);
            this.getListStudent();
          })
          .catch(() => {
            showMessage('CÓ LỖI XẢY RA!', false);
          });
      }
    });
  };

  removeStudentRequest = (id) => {
    deleteById(DELETE_HISTORY_CLUB, id)
      .then((res) => {
        showMessage(res.data.message, true);
        this.getListStudent();
      })
      .catch(() => {
        showMessage('CÓ LỖI XẢY RA!', false);
      });
  };

  getListStudent = async () => {
    const { page, perPage } = this.state;
    this.getListRequest({
      page,
      // eslint-disable-next-line camelcase
      per_page: perPage,
      keyword: this.keyword.search_text || ''
    });
  };

  getListRequest = async (params) => {
    const { perPage } = this.state;
    const { id } = this.props.match.params;
    await postDataByID(GET_LIST_HISTORY_CLUB, id, params)
      .then((res) => {
        this.setState({
          tableData: res.data.data,
          paginate: {
            ...res.data.paginate,
            perPage
          },
          isLoading: false,
          udFlag: res.data.ud_flag
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  onChangeSearch = (e) => {
    clearTimeout(this.timer);
    const { page, perPage } = this.state;
    const { target } = e;
    const { name } = target;
    const value = target.value ? target.value : '';
    this.keyword = {
      ...this.keyword,
      [name]: value
    };
    this.timer = setTimeout(
      () =>
        this.getListRequest({
          page,
          per_page: perPage,
          keyword: this.keyword.search_text || ''
        }),
      500
    );
  };

  onSubmitSearch = (e) => {
    e.preventDefault();

    clearTimeout(this.timer);
    const { page, perPage } = this.state;
    const { target } = e;
    const { name } = target;
    const value = target.value ? target.value : '';
    this.keyword = {
      ...this.keyword,
      [name]: value
    };
    this.timer = setTimeout(
      () =>
        this.getListRequest({
          page,
          per_page: perPage,
          keyword: this.keyword.search_text || ''
        }),
      500
    );
  };

  changePage = (pageNumber) => {
    const { page, perPage } = this.state;
    if (pageNumber !== page) {
      this.getListRequest({
        page: pageNumber,
        // eslint-disable-next-line camelcase
        per_page: perPage,
        keyword: this.keyword.search_text || ''
      });
      this.setState({ page: pageNumber });
    }
  };

  render() {
    const { tableHeaders, paginate, tableData, isLoading, udFlag } = this.state;
    return (
      <>
        <div className="body-right-bottom">
          <div className="content">
            <NavStudent />
            <div className="container-fluid pt-4">
              <div className="row">
                <div className="content-form col-md-6">
                  <div className="content-title">
                    <p>Lịch sử hoạt động</p>
                  </div>
                </div>

                <div className="col-md-6 content__flex-end">
                  <Link
                    to={`/statistical/student/${this.props.match.params.id}/history/add`}
                  >
                    <NewButton />
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Table
                    fixed
                    tableHeaders={tableHeaders}
                    tableData={tableData}
                    tablePaginate={paginate}
                    isLoading={isLoading}
                  />
                </div>
                <div className="table-pagenatie ml-auto">
                  <div className="paginate-wrapper">
                    <Paginate
                      paginate={paginate}
                      changePage={this.changePage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(HistoryStudent);
