/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useLocation } from 'react-router-dom';
// import CountUp from 'react-countup';
import { GET_LIST_BANNER, DELETE_BANNER } from '../../constants/config';
import { PER_PAGE } from '../../constants/variable';
import {
  confirmDelete
  // checkAccept,
  // showMessage,
  // moneyFormat
} from '../../helpers/table';
import createNotification from '../../component/common/Notification';
import { postData, deleteById } from '../../services/base_services';
import Table from '../../component/common/Table';
import Paginate from '../../component/Paginate';
import NewButton from '../../component/common/NewButton';
import InputSearch from '../../component/common/InputSearch';
import editIcon from '../../assets/images/icon/edit-kn.svg';
import deleteIcon from '../../assets/images/icon/delete-kn.svg';

// import { formatDate } from '../../helpers/form';
const AdsTable = (props) => {
  const { history } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [tableData, setTableData] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [numberDelete, setNumberDelete] = useState(0);
  const typingTimeoutRef = useRef(null);
  const [key, setKey] = useState('');
  const [idEvent, setIdEvent] = useState('');
  const dateRef = useRef('');
  const [req, setReq] = useState({
    keyword: key,
    page: queryParams.get('page') || 1,
    per_page: queryParams.get('perpage') || PER_PAGE,
    event_id: idEvent,
    date: ''
  });

  // const formatDate2 = 'dd-MM-yyyy';
  // const IDEventRef = useRef('');
  // const [listEventDonate, setListEventDonate] = useState([]);
  // const [errors, setErrors] = useState([]);

  const tableHeaders = [
    {
      label: 'STT',
      index: 'stt',
      option: {
        className: 'text-center position-relative'
      },
      callback: null
    },
    {
      label: 'Tiêu đề',
      index: null,
      callback: (data) => {
        return (
          <div>
            <span>{data && data.title}</span>
          </div>
        );
      }
    },
    {
      label: 'CHỨC NĂNG',
      index: null,
      option: {
        className: 'text-center fixed-collumn'
      },
      callback: (data) => {
        if (data && data.id) {
          return (
            <div className="btn-group">
              <span
                data-tip="Chỉnh Sửa Banner"
                className="link-action ml-2 mr-2"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => onEditDonate(data.id)}
                  onKeyPress={() => {}}
                >
                  <img alt="edit" src={editIcon} className="btn-icon" />
                </div>
              </span>
              <span data-tip="Xóa Banner" className="link-action ml-2">
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => onDeleteDonate(data.id)}
                  onKeyPress={() => {}}
                >
                  <img alt="delete" src={deleteIcon} className="btn-icon" />
                </div>
              </span>
              <ReactTooltip
                className="tooltip-button"
                place="bottom"
                effect="float"
              />
            </div>
          );
        }
        return '';
      }
    }
  ];

  const getListDonate = async (newReq) => {
    //getListEventDonate
    await postData(GET_LIST_BANNER, newReq)
      .then((res) => res && res.data)
      .then((res) => {
        setPaginate({ ...res?.paginate, perPage: req?.per_page });
        setTableData(res && res.data);
      });
  };
  const deleteDonate = (id) => {
    deleteById(DELETE_BANNER, id).then(() => {
      setNumberDelete(numberDelete + 1);
      createNotification('success', `Bạn đã xoá thành công`);
    });
  };
  const onEditDonate = (id) => {
    history.push(`/postweb/banner/edit/${id}`);
  };
  const onDeleteDonate = (id, name) => {
    confirmDelete().then((res) => {
      if (res && res.value) {
        deleteDonate(id, name);
      }
    });
  };

  const updateURL = (pageNumber, perpage) => {
    history.push(`?page=${pageNumber}&perpage=${perpage}`);
  };

  const changePage = (pageNumber) => {
    setReq({
      ...req,
      page: pageNumber
    });
    updateURL(pageNumber, req.per_page);
  };
  const changePerPage = (value) => {
    setReq({
      ...req,
      keyword: key,
      page: 1,
      per_page: value
    });
    updateURL(1, value);
  };

  const onChangeSearch = (e) => {
    const value = e.target.value ? e.target.value : '';
    setKey(value);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      setReq({
        ...req,
        keyword: value,
        page: 1
      });
    }, 500);
  };

  useEffect(() => {
    getListDonate(req);
  }, [req, numberDelete]);

  return (
    <>
      <section className="body-right-bottom course-wrap">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>Quản Lý Banner </p>
            </div>
            <div className="col-md-1 top-table-border "></div>
          </div>

          <div className="row header-wrap">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <InputSearch onChange={onChangeSearch} />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 content__flex-end">
              <Link to="/postweb/banner/new" className="">
                <NewButton />
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                tableData={tableData}
                tableHeaders={tableHeaders}
                tablePaginate={paginate}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate
                  paginate={paginate}
                  changePage={changePage}
                  per_page={req?.per_page || PER_PAGE}
                  tablePaginate={paginate}
                  changePerPage={changePerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(AdsTable);

AdsTable.propTypes = {
  history: propTypes.isRequired
};
