/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import localForage from 'localforage';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@blowstack/ckeditor5-full-free-build';

import { checkError, getError } from '../../helpers/error';

import Alert from './Alert';
import MyUploadAdapter from '../CustomAdapterUpload';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const TextEditor = (props) => {
  let { errors, ...input } = props;
  const plugins = [
    'Alignment',
    'AutoImage',
    'Autoformat',
    'AutoLink',
    'BlockQuote',
    'Bold',
    'CloudServices',
    'Code',
    'CodeBlock',
    'Essentials',
    'FontBackgroundColor',
    'FontColor',
    'FontFamily',
    'FontSize',
    'Heading',
    'Highlight',
    'HorizontalLine',
    'HtmlEmbed',
    'Image',
    'ImageCaption',
    'ImageInsert',
    'ImageResize',
    'ImageStyle',
    'ImageToolbar',
    'ImageUpload',
    'Indent',
    'IndentBlock',
    'Italic',
    'Link',
    'LinkImage',
    'List',
    'ListStyle',
    'MediaEmbed',
    'MediaEmbedToolbar',
    'PageBreak',
    'Paragraph',
    'PasteFromOffice',
    'RemoveFormat',
    'SimpleUploadAdapter',
    'SpecialCharacters',
    'SpecialCharactersArrows',
    'SpecialCharactersCurrency',
    'SpecialCharactersLatin',
    'SpecialCharactersMathematical',
    'SpecialCharactersText',
    'Strikethrough',
    'Table',
    'TableCellProperties',
    'TableProperties',
    'TableToolbar',
    'TextTransformation',
    'TodoList',
    'Underline',
    'WordCount'
  ];

  return (
    <div className={checkError(errors, input.name)}>
      {input.label && (
        <label className="input-label ">
          {input.label} {input.required && <span className="required">*</span>}
        </label>
      )}
      <CKEditor
        {...input}
        editor={ClassicEditor}
        config={{
          toolbar: [
            'heading',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'code',
            'subscript',
            'superscript',
            'link',
            'blockQuote',
            'insertTable',
            // 'tableColumn',
            // 'tableRow',
            // 'mergeTableCells',
            'mediaEmbed',
            'imageInsert',
            'imageStyle',
            'imageTextAlternative',
            'imageResize',
            'bulletedList',
            'numberedList',
            'todoList',
            'indent',
            'outdent',
            'alignment',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            'highlight',
            'horizontalLine',
            'pageBreak',
            'removeFormat',
            'specialCharacters',
            'findAndReplace',
            'undo',
            'redo'
          ],
          mediaEmbed: {
            previewsInData: true
          },
          plugins: [
            ...ClassicEditor.builtinPlugins?.filter((item) =>
              plugins.includes(item.pluginName)
            )
          ]
        }}
        onReady={(editor) => {
          editor.plugins.get('FileRepository').createUploadAdapter = (
            loader
          ) => {
            return new MyUploadAdapter(loader);
          };
        }}
      />
      <Alert messsage={getError(errors, input.name)} />
    </div>
  );
};

export default TextEditor;

TextEditor.propTypes = {
  errors: PropTypes.isRequired,
  input: PropTypes.isRequired
};
