import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';

import plusIcon from '../../assets/images/icon/plus-icon.svg';

class NewButton extends Component {
  render() {
    return (
      <button type="button" className="btn-new" {...this.props}>
        <span>Thêm mới</span>
        <ReactSVG src={plusIcon} />
      </button>
    );
  }
}

export default NewButton;
