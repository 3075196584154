import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getTakenData,
  getDataByID,
  putData
} from '../../services/base_services';
import ButtonSave from '../../component/common/ButtonSave';
import InputText from '../../component/common/InputText';
import TextArea from '../../component/common/TextArea';
import {
  destructServerErrors,
  destructServerMessage
} from '../../helpers/error';
import { showMessage } from '../../helpers/table';
import Select from '../../component/common/Select';
import SelectCustom from '../../component/common/SelectCustom';
import {
  GET_LIST_BRANCH,
  GET_CLUB_BRANCH,
  GET_LIBRARY_REQUEST,
  UPDATE_LIBRARY_REQUEST,
  GET_LIST_CATALOG,
  GET_LIST_CATEGORY,
  GET_LIST_DOCUMENT
} from '../../constants/config';
import TextEditor from '../../component/common/TextEditor';
import GroupFile from '../../component/common/GroupFileCasorel';
import GroupVideo from '../../component/common/GroupVideo';
import GroupPhoto from '../../component/common/GroupPhoto';
import GroupEbook from '../../component/common/GroupEbook';
import GroupPhotoCustom from '../../component/common/GroupPhotoCustom';
import { getFormDataFromRef } from '../../helpers/form';
import CheckboxCustom from '../../component/common/CheckboxCustom';

class EditLibrary extends Component {
  constructor(props) {
    super(props);
    this.documents = [];
    this.state = {
      isLoading: true,
      status: false,
      errors: [],
      listBranch: [],
      listClub: [],
      contentText: '',
      defaultList: [],
      data: [],
      defaultbranch: '',
      defaultClub: '',
      listDoc: [],
      description: '',
      listDocumentType: [],
      documentType: 4,
      numPhoto: [
        {
          num: '',
          image: 'check'
        }
      ],
      overviewFlg: 0,
      checked: 0,
      private_flg: 0,
      listCategory: [],
      initallCategory: '',
      banner: ''
    };
  }

  // addDraft = (e) => {
  //   e.preventDefault();
  //   const {
  //     contextData,
  //     catalogId,
  //     numPhoto,
  //     overviewFlg,
  //     checked,
  //     private_flg
  //   } = this.state;
  //   const { user, match, history } = this.props;
  //   const { params } = match;
  //   const { id } = params;
  //   let formData = [];
  //   if (catalogId == 4) {
  //     const dataFile = getFormDataFromRef(this.refs);
  //     let attach_files = [];
  //     numPhoto.map((item, index) => {
  //       if (item.image != 'check') {
  //         attach_files = [
  //           ...attach_files,
  //           {
  //             image: dataFile[`photoIndex${index}`],
  //             title: dataFile[`indexText${index}`]
  //           }
  //         ];
  //       }
  //     });
  //     formData = {
  //       branch_id: this.branchId.value,
  //       club_id: this.clubId.value,
  //       catalog_id: this.infoCatalogId.value,
  //       description: this.description.value,
  //       attach_files: attach_files,
  //       title: this.title.value,
  //       author: user.user.id,
  //       status: 0,
  //       private_flg: private_flg
  //     };
  //   } else {
  //     formData = {
  //       content: contextData,
  //       branch_id: this.branchId.value,
  //       club_id: this.clubId.value,
  //       catalog_id: this.infoCatalogId.value,
  //       description: this.description.value,
  //       attach_files: this.documents,
  //       title: this.title.value,
  //       bgr_image: this.bgr_image && this.bgr_image.value,
  //       author: user.user.id,
  //       status: 0,
  //       private_flg: this.state.private_flg
  //     };
  //   }
  //   if (catalogId == 5) {
  //     formData = {
  //       ...formData,
  //       overview_flg: overviewFlg,
  //       checked: checked,
  //       private_flg: this.state.private_flg
  //     };
  //     putData(UPDATE_LIBRARY_REQUEST, id, formData)
  //       .then((res) => {
  //         showMessage(res.data.message, true);
  //         this.setState({
  //           errors: [],
  //           status: true
  //         });
  //         history.goBack();
  //       })
  //       .catch((err) => {
  //         const errs = destructServerMessage(err);
  //         const errss = destructServerErrors(err);
  //         this.setState({
  //           errors: errss
  //         });
  //         if (err.response.status == 422) {
  //           return null;
  //         }
  //         showMessage(errs, false);
  //       });
  //   } else {
  //     putData(UPDATE_LIBRARY_REQUEST, id, formData)
  //       .then((res) => {
  //         showMessage(res.data.message, true);
  //         this.setState({
  //           errors: [],
  //           status: true
  //         });
  //         history.goBack();
  //       })
  //       .catch((err) => {
  //         const errs = destructServerMessage(err);
  //         const errss = destructServerErrors(err);
  //         this.setState({
  //           errors: errss
  //         });
  //         if (err.response.status == 422) {
  //           return null;
  //         }
  //         showMessage(errs, false);
  //       });
  //   }
  // };

  addNew = (e) => {
    e.preventDefault();
    const { contextData, catalogId, numPhoto, overviewFlg } = this.state;
    const { user, history } = this.props;

    const dataFile = getFormDataFromRef(this.refs);
    let attach_files = [];
    numPhoto.map((item, index) => {
      if (item.image != 'check') {
        attach_files = [
          ...attach_files,
          {
            image: dataFile[`photoIndex${index}`],
            title: dataFile[`indexText${index}`]
          }
        ];
      }
    });

    let formData = {
      branch_id: this.defaultbranch || this.branchId.value,
      club_id: this.defaultClub || this.clubId.value,
      catalog_id: this.state.catalogId || this.InfoCataLogId?.value,
      description: this.description.value,
      title: this.title.value,
      author: user.user.id,
      private_flg: this.state.private_flg,
      category_id: this?.state?.initallCategory || ''
    };

    switch (catalogId) {
      case 1:
        formData = {
          ...formData,
          url_video: this.url_video.value || '',
          attach_files: this.video || [],
          status: 1
        };
        break;
      case 3:
        formData = {
          ...formData,
          attach_files: this.documents,
          status: 1,
          bgr_image: this.bgr_image?.value
        };
        break;
      case 4:
        formData = {
          ...formData,
          attach_files: attach_files,
          status: 1
        };
        break;
      case 5:
        formData = {
          ...formData,
          status: 1,
          overview_flg: overviewFlg,
          document_type: this.documentType.value,
          attach_files: this.documents,
          content: contextData,
          banner: this.banner.value
        };
        break;
      default:
    }
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    putData(UPDATE_LIBRARY_REQUEST, id, formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({
          errors: [],
          status: true
        });
        history.goBack();
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);
        this.setState({
          errors: errss
        });
        if (err.response.status == 422) {
          return null;
        }
        showMessage(errs, false);
        showMessage('Nhập trường danh mục', false);
      });
  };

  componentDidMount = async () => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    if (id) {
      await getDataByID(GET_LIBRARY_REQUEST, id)
        .then((res) => {
          console.log('first', res?.data);

          this.setState({
            data: res.data,
            contextData: res.data.content,
            isLoading: false,
            catalogId: res.data.Catalog.id,
            numPhoto: res.data.attach_files,
            documentType: res.data.document_type ? res.data.document_type : 8,
            overviewFlg: res?.data?.overview_flg,
            private_flg: res.data.private_flg,
            description: res?.data?.description,
            attach_files: res?.data?.attach_files,
            url_video: res?.data?.url_video,
            banner: res?.data?.banner

            // checked: checked
          });

          return Promise.resolve({ res });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
    this.getList();
  };

  getList = async () => {
    const { data } = this.state;
    await getTakenData(GET_LIST_BRANCH).then((res) => {
      this.setState({
        listBranch: [
          {
            id: '0',
            name: 'Tất cả phân đường'
          },
          ...res.data
        ],
        defaultbranch:
          res.data.find((item) => item.id === data.branch_id)?.id || 0
      });
    });
    await getTakenData(GET_LIST_CATALOG).then((res) => {
      this.setState({
        listDoc: res.data,
        defaultList: res.data.find((item) => item.id === data.catalog_id)
      });
    });
    await getDataByID(GET_CLUB_BRANCH, data.branch_id).then((res) => {
      this.setState({
        listClub: [
          {
            id: '0',
            name: 'Tất cả Câu Lạc Bộ/Võ Đường'
          },
          ...res.data
        ],
        defaultClub: res.data.find((item) => item.id === data.club_id)?.id || 0
      });
    });

    await getTakenData(GET_LIST_DOCUMENT).then((res) => {
      this.setState({
        listDocumentType: [...res?.data]
      });
    });

    await getTakenData(GET_LIST_CATEGORY).then((res) => {
      this.setState({
        listCategory: [
          {
            id: '',
            name: 'Tất cả danh mục'
          },
          ...res?.data?.map((item) => ({
            id: item?.id,
            name: item?.title
          }))
        ],
        initallCategory:
          res?.data.find((item) => item?.id === data?.category_id)?.id || ''
      });
    });
  };

  selectBranch = async (e) => {
    const branchId = e.target.value;
    await getDataByID(GET_CLUB_BRANCH, branchId).then((res) => {
      this.setState({
        defaultbranch: branchId,
        listClub: [
          {
            id: '0',
            name: 'Tất cả Câu Lạc Bộ/Võ Đường'
          },
          ...res.data
        ]
      });
    });
  };

  selectClub = async (e) => {
    const clubId = e.target.value;
    this.setState({
      defaultClub: clubId
    });
  };

  onChangeDocument = (data) => {
    this.documents = data;
  };

  onChangeVideo = (data) => {
    this.video = data;
  };

  changeEditer = (e, editor) => {
    const data = editor.getData();
    this.setState({
      contextData: data
    });
  };

  selectDoc = (e) => {
    this.setState({
      catalogId: e.target.value,
      contextData: '',
      documents: [],
      numPhoto: [
        {
          total: 0,
          image: 'check'
        }
      ],
      errors: []
    });
  };

  addPhoto = () => {
    const { numPhoto } = this.state;
    const count = numPhoto[numPhoto.length - 1].total;
    const temp = {
      total: count + 1,
      image: 'check'
    };
    this.setState({
      numPhoto: [...numPhoto, temp]
    });
  };

  addImage = (id, img) => {
    const { numPhoto } = this.state;
    const temp = numPhoto;
    temp[id].image = img;
    this.setState({
      numPhoto: temp
    });
  };

  deletePhoto = (e, id) => {
    e.preventDefault();
    const { numPhoto } = this.state;
    if (numPhoto.length != 1) {
      const temp = numPhoto;
      temp.map((item, index) => {
        if (temp.length - 1 > index && index >= id) {
          document.querySelector(`#img-${index}`).src = document.querySelector(
            `#img-${index + 1}`
          ).src;
          document.querySelector(
            `#div-${index}`
          ).className = document.querySelector(`#div-${index + 1}`).className;
        }
      });

      temp.splice(-1, 1);
      this.setState({
        numPhoto: temp
      });

      return temp;
    } else {
      return showMessage('Không được xóa hết ảnh', false);
    }
  };

  handleInputChange = (e) => {
    let value = e.target.checked;
    if (value) {
      this.setState({
        overviewFlg: 1
      });
    } else {
      this.setState({
        overviewFlg: 0
      });
    }
  };

  handleInputChangeChecked = (e) => {
    let value = e.target.checked;
    if (value) {
      this.setState({
        private_flg: 1
      });
    } else {
      this.setState({
        private_flg: 0
      });
    }
  };

  handleChangeCategory = (e) => {
    this.setState({
      initallCategory: e?.target?.value
    });
  };

  render() {
    const {
      errors,
      listClub,
      listBranch,
      showBranch,
      listDoc,
      listDocumentType,
      data,
      defaultList,
      isLoading,
      defaultbranch,
      defaultClub,
      catalogId,
      numPhoto,
      documentType,
      overviewFlg,
      private_flg,
      listCategory,
      initallCategory,
      banner
    } = this.state;
    const { history } = this.props;
    return isLoading ? (
      ''
    ) : (
      <>
        <div className="body-right-bottom">
          <div className="container-fluid container-shadow">
            <div className="row top-table">
              <div className="col-md-12 top-table-title">
                <p>Quản lý tư liệu</p>
              </div>
              <div className="col-md-1 top-table-border "></div>
              <div className="col-md-12 top-table-text">Chỉnh sửa tư liệu </div>
            </div>

            <div className="row">
              <div className="col-md-12 form-title">
                <p> Chỉnh sửa tư liệu </p>
              </div>
              <div className="col-md-12 checkbox-main-custom">
                <CheckboxCustom
                  id="checked"
                  label="Private"
                  onChange={this.handleInputChangeChecked}
                  defaultChecked={private_flg === 1 ? true : false}
                />
              </div>

              {catalogId == 5 && (
                <div className="col-md-12 checkbox-main-custom">
                  <CheckboxCustom
                    id="overview_flg"
                    label="Có phải bài intro hay không"
                    onChange={this.handleInputChange}
                    defaultChecked={overviewFlg === 1 ? true : false}
                  />
                </div>
              )}
              <div className="col-md-6">
                <InputText
                  className="form-control input-form"
                  label="Tác giả"
                  errors={errors}
                  type="text"
                  defaultValue={data && data.user && data.user.name}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <InputText
                  className="form-control input-form"
                  label="Chức danh"
                  errors={errors}
                  type="text"
                  defaultValue={data && data.user && data.user.position_name}
                  disabled
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <SelectCustom
                  label="Danh mục"
                  className="form-control-select input-form form-control-product mb-3 input-grey"
                  name="category_id"
                  ref={(c) => {
                    this.infoCategories = c;
                  }}
                  errors={[]}
                  key_value="id"
                  key_label="name"
                  data={listCategory}
                  onChange={this.handleChangeCategory}
                  defaultValue={initallCategory}
                  required={true}
                />
              </div>
              <div className="col-md-6">
                <Select
                  label="Loại tư liệu"
                  className="form-control-select input-form form-control-product mb-3 input-grey"
                  name="catalog_id"
                  ref={(c) => {
                    this.infoCatalogId = c;
                  }}
                  errors={errors}
                  key_value="id"
                  key_label="name"
                  include_blank="Loại tư liệu"
                  data={listDoc}
                  onChange={this.selectDoc}
                  defaultValue={data && data.Catalog}
                  required={true}
                />
              </div>
              {this.state.catalogId == 5 && (
                <div className="col-md-6">
                  <Select
                    label="Phân loại bài viết"
                    className="form-control-select input-form form-control-product mb-3 input-grey"
                    name="document_type"
                    ref={(c) => {
                      this.documentType = c;
                    }}
                    errors={errors}
                    key_value="id"
                    key_label="name"
                    data={listDocumentType}
                    onChange={this.selectDocType}
                    defaultValue={listDocumentType.find(
                      (item) => item.id === documentType
                    )}
                    required={true}
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-6">
                <SelectCustom
                  label="Phân đường "
                  className="form-control-select input-form form-control-product mb-3 input-grey"
                  name="branch_id"
                  ref={(c) => {
                    this.branchId = c;
                  }}
                  errors={errors}
                  key_value="id"
                  key_label="name"
                  // include_blank="Tất cả phân đường"
                  data={listBranch}
                  onChange={this.selectBranch}
                  defaultValue={defaultbranch}
                />
              </div>
              <div className="col-md-6">
                <SelectCustom
                  label="Câu Lạc Bộ/Võ Đường"
                  className="form-control-select input-form form-control-product mb-3 input-grey"
                  name="club_id"
                  ref={(c) => {
                    this.clubId = c;
                  }}
                  errors={errors}
                  key_value="id"
                  key_label="name"
                  // include_blank="Tất cả Câu Lạc Bộ/Võ Đường"
                  data={listClub}
                  onChange={this.selectClub}
                  defaultValue={defaultClub}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <InputText
                  className="form-control input-form"
                  placeholder="Tiêu đề"
                  name="title"
                  ref={(c) => {
                    this.title = c;
                  }}
                  label="Tiêu đề: "
                  errors={errors}
                  type="text"
                  defaultValue={data && data.title}
                  required={true}
                />
              </div>
              {catalogId == 5 ? (
                <>
                  <div className="col-md-12">
                    <TextEditor
                      name="content"
                      label="Nội dung"
                      errors={errors}
                      data={data && data.content}
                      onChange={this.changeEditer}
                      required={true}
                    />
                  </div>
                  <div className="col-md-12">
                    <TextArea
                      className="form-control input-form custom-text-area"
                      placeholder="Tóm tắt"
                      name="description"
                      ref={(c) => {
                        this.description = c;
                      }}
                      label="Tóm tắt"
                      errors={errors}
                      defaultValue={data && data.description}
                      type="textarea"
                    />
                  </div>

                  <div className="col-md-6">
                    <GroupPhoto
                      label="Ảnh bìa "
                      name="banner"
                      title="Chọn ảnh để tải"
                      errors={[]}
                      order={1}
                      required={true}
                      ref={(c) => {
                        this.banner = c;
                      }}
                      defaultValue={banner}
                    />
                  </div>

                  <div className="col-md-6">
                    <GroupFile
                      label="FILE ĐÌNH KÈM"
                      name="attach_files"
                      title="Chọn ảnh để tải"
                      errors={errors}
                      onChange={this.onChangeDocument}
                      default={data && data.attach_files}
                    />
                  </div>
                </>
              ) : catalogId == 1 || catalogId == 2 ? (
                <>
                  <div className="col-md-12">
                    <InputText
                      className="form-control input-form"
                      placeholder="Link Video"
                      name="url_video"
                      ref={(c) => {
                        this.url_video = c;
                      }}
                      label="Link Video "
                      errors={errors}
                      type="text"
                      defaultValue={this.state?.url_video}
                    />
                  </div>
                  <div className="col-md-6">
                    <TextArea
                      className="form-control input-form custom-text-area"
                      placeholder="Tóm tắt"
                      name="description"
                      ref={(c) => {
                        this.description = c;
                      }}
                      label="Tóm tắt:"
                      errors={errors}
                      defaultValue={data && data.description}
                      type="textarea"
                    />
                  </div>
                  <div className="col-md-6">
                    <GroupVideo
                      label="VIDEO ĐÌNH KÈM"
                      name="attach_files"
                      title="Chọn ảnh để tải"
                      errors={errors}
                      onChange={this.onChangeVideo}
                      default={data && data.attach_files}
                      audio={catalogId == 2 ? true : false}
                    />
                  </div>
                </>
              ) : catalogId == 4 ? (
                <>
                  <div className="col-md-12 d-flex flex-wrap pb-3">
                    <label className="input-label">
                      ẢNH ĐÌNH KÈM <span style={{ color: 'red' }}>*</span>{' '}
                    </label>
                    <ButtonSave
                      text="Thêm ảnh"
                      className="button-new ml-auto mr-0"
                      onClick={this.addPhoto}
                    />
                  </div>
                  {numPhoto.map((item, index) => (
                    <>
                      <div className="col-md-6 position-relative" key={index}>
                        <GroupPhotoCustom
                          name={`photoIndex${index}`}
                          ref={`photoIndex${index}`}
                          title="Chọn ảnh để tải"
                          errors={errors}
                          addImage={this.addImage}
                          order={index}
                          defaultValue={item.image == 'check' ? '' : item.image}
                        />
                        <form className="d-flex fex-wrap" id={`form-${index}`}>
                          <div
                            className="col-md-10"
                            style={{ marginLeft: '-15px' }}
                          >
                            <InputText
                              className="form-control input-form"
                              placeholder="< Nội dung ảnh >"
                              errors={errors}
                              type="text"
                              ref={`indexText${index}`}
                              defaultValue={item && item.title}
                            />
                          </div>
                          <div className="col-md-2">
                            <ButtonSave
                              text="X"
                              className="button-new button-new-close ml-auto mr-0"
                              onClick={(e) => this.deletePhoto(e, index)}
                            />
                          </div>
                        </form>
                      </div>
                    </>
                  ))}

                  <div className="col-md-12">
                    <TextArea
                      className="form-control w-50 input-form"
                      placeholder="Tóm tắt"
                      name="description"
                      ref={(c) => {
                        this.description = c;
                      }}
                      label="Tóm tắt:"
                      errors={errors}
                      type="textarea"
                      defaultValue={data && data.description}
                    />
                  </div>
                </>
              ) : catalogId == 3 ? (
                <>
                  <div className="col-md-6">
                    <GroupEbook
                      label="Nội dung"
                      name="attach_files"
                      title="Chọn ảnh để tải"
                      errors={errors}
                      onChange={this.onChangeDocument}
                      // default={this.documents}
                      default={data && data.attach_files}
                      required={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <GroupPhoto
                      label="Ảnh bìa"
                      name="bgr_image"
                      ref={(c) => {
                        this.bgr_image = c;
                      }}
                      title="Chọn ảnh để tải"
                      errors={errors}
                      order={2}
                      defaultValue={data && data.bgr_image}
                    />
                  </div>
                  <div className="col-md-12">
                    <TextArea
                      className="form-control input-form"
                      placeholder="Tóm tắt"
                      name="description"
                      ref={(c) => {
                        this.description = c;
                      }}
                      label="Tóm tắt:"
                      errors={errors}
                      type="textarea"
                      defaultValue={data && data.description}
                    />
                  </div>
                </>
              ) : (
                ''
              )}
            </div>

            <div className="row justify-content-center">
              <div className=" d-flex mx-auto my-5">
                {/* <ButtonSave
                  text="Lưu Nháp"
                  className="btn btn-new  ml-0"
                  onClick={this.addDraft}
                /> */}

                <div
                  onClick={history.goBack}
                  onKeyPress={[]}
                  role="button"
                  tabIndex={0}
                >
                  <ButtonSave text="Hủy" className="btn btn-cancel" />
                </div>
                <ButtonSave
                  onClick={this.addNew}
                  text="Lưu thông tin"
                  className="btn btn-new ml-0"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

EditLibrary.propTypes = {
  history: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired,
  match: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(withRouter(EditLibrary));
