const TIME_FORMAT = {
  ISO_DATE_FORMAT: 'DD-MM-YYYY',
  US_DATE_FORMAT: 'MM/DD/YYYY',
  EURO_DATE_FORMAT: 'dd/mm/yyyy',
  ABBR_DATE_FORMAT: 'D MMM YYYY',
  FULL_DATE_FORMAT: 'MMMM D, YYYY',
  ISO_DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  ISO_DATE_TIME_ZONE_FORMAT: 'YYYY-MM-DDTHH:mm:ssZ',
  ISO_8601_DATE_TIME: 'YYYY-MM-DD',
  EURO_DATE_TIME_FORMAT: 'DD/MM/YYYY HH:mm',
  YEAR_ONLY_FORMAT: 'YYYY',
  MONTH_YEAR_FORMAT: 'MMM YYYY',
  DATE_COUNTDOWN: 'DD : HH : mm : ss',
  DATE_COUNTDOWN_HOME: 'DD : HH : mm',
  HOURS_TIME_FORMAT: 'HH:mm DD/MM/YYYY',
  DOT_DATE_FORMAT: 'DD.MM.YYYY',
  DATE_FORMAT: 'dd-MM-yyyy'
};

export { TIME_FORMAT };
