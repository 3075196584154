import { ADMIN_ROUTES } from '../../constants/router';

import info from '../../assets/images/icon/info-kn.svg';
import operational from '../../assets/images/icon/operational-management-kn.svg';
import information from '../../assets/images/icon/information-management-kn.svg';
import statistics from '../../assets/images/icon/statistics-kn.svg';
import settings from '../../assets/images/icon/settings.svg';
import donation from '../../assets/images/icon/donation.svg';

export const menuItems = [
  {
    id: '1',
    link: ADMIN_ROUTES.HOME_PAGE,
    name: 'Tổng quan',
    icon: `${info}`,
    position_id: 3
  },
  {
    id: '2',
    link: '/statistical',
    name: 'Quản lý võ phái',
    icon: `${operational}`,
    position_id: 3,
    children: [
      {
        id: '2.1',
        link: ADMIN_ROUTES.STATISTICAL_BRANCH,
        name: 'Quản lý phân đường',
        children: [],
        position_id: 3
      },
      {
        id: '2.2',
        link: ADMIN_ROUTES.STATISTICAL_CLUB,
        name: 'Quản lý câu lạc bộ',
        children: [],
        position_id: 9
      },
      {
        id: '2.3',
        link: ADMIN_ROUTES.STATISTICAL_STUDENT,
        name: 'Quản lý võ sinh',
        children: [],
        position_id: 9
      }
    ]
  },
  {
    id: '3',
    link: '/active',
    name: 'Quản lý chung',
    icon: `${information}`,
    position_id: 3,
    children: [
      {
        id: '3.1',
        link: ADMIN_ROUTES.ACTIVE_DOCUMENTS,
        name: 'Quản lý bài viết',
        children: [],
        position_id: 9
      },
      {
        id: '3.2',
        link: ADMIN_ROUTES.ACTIVE_SERI,
        name: 'Quản lý seri',
        children: [],
        position_id: 9
      },
      {
        id: '3.3',
        link: ADMIN_ROUTES.ACTIVE_COURSE,
        name: 'Quản lý khóa học',
        children: [],
        position_id: 9
      },
      {
        id: '3.4',
        link: ADMIN_ROUTES.ACTIVE_COMPETITION,
        name: 'Quản lý kỳ thi',
        children: [],
        position_id: 9
      },
      {
        id: '3.5',
        link: ADMIN_ROUTES.ACTIVE_EVENT,
        name: 'Quản lý sự kiện',
        children: [],
        position_id: 9
      },
      {
        id: '3.6',
        link: '/active/libraries',
        name: 'Quản lý tư liệu',
        children: [
          {
            id: '3.6.1',
            link: ADMIN_ROUTES.ACTIVE_LIBRARIES_VIDEO,
            name: 'Video',
            position_id: 9
          },
          {
            id: '3.6.2',
            link: ADMIN_ROUTES.ACTIVE_LIBRARIES_EBOOK,
            name: 'Ebook',
            position_id: 9
          },
          {
            id: '3.6.3',
            link: ADMIN_ROUTES.ACTIVE_LIBRARIES_PHOTO,
            name: 'Photo',
            position_id: 9
          },
          {
            id: '3.6.4',
            link: ADMIN_ROUTES.ACTIVE_LIBRARIES_DOCUMENT,
            name: 'Document',
            position_id: 9
          },
          {
            id: '3.6.5',
            link: ADMIN_ROUTES.ACTIVE_LIBRARIES_REQUEST,
            name: 'Danh sách yêu cầu',
            position_id: 1
          },
          {
            id: '3.6.6',
            link: ADMIN_ROUTES.ACTIVE_LIBRARIES_CATEGORIES,
            name: 'Quản lý danh mục',
            position_id: 1
          }
        ],
        position_id: 9
      }
    ]
  },
  {
    id: '4',
    link: '/postweb',
    name: 'Quản lý Website',
    icon: `${statistics}`,
    position_id: 1,
    children: [
      {
        id: '4.1',
        name: 'Quản lý hình ảnh',
        children: [
          {
            id: '4.1.1',
            link: ADMIN_ROUTES.POSTWEB_BANNER,
            name: 'Quản lý banner',
            position_id: 9
          },
          {
            id: '4.1.2',
            link: ADMIN_ROUTES.POSTWEB_BANNER_BRANCH,
            name: 'Quản lý hình ảnh phân đường',
            position_id: 9
          },
          {
            id: '4.1.3',
            link: ADMIN_ROUTES.POSTWEB_BANNER_TECHNICAL,
            name: 'Quản lý hình ảnh kỹ thuật',
            position_id: 9
          },
          {
            id: '4.1.4',
            link: ADMIN_ROUTES.POSTWEB_BANNER_GROUP,
            name: 'Quản lý hình ảnh hoạt động',
            position_id: 9
          }
        ],
        position_id: 9
      },
      {
        id: '4.2',
        link: ADMIN_ROUTES.POSTWEB_ACTIVITIES,
        name: 'Quản lý hoạt động',
        children: [],
        position_id: 9
      },
      {
        id: '4.3',
        link: ADMIN_ROUTES.POSTWEB_TECHNIQUE,
        name: 'Quản lý kỹ thuật',
        children: [],
        position_id: 9
      },
      {
        id: '4.4',
        link: ADMIN_ROUTES.POSTWEB_FORM,
        name: 'Nội dung khác',
        children: [],
        position_id: 9
      },
      {
        id: '4.5',
        link: ADMIN_ROUTES.POSTWEB_CONTACT,
        name: 'Quản lý Liên Hệ',
        children: [],
        position_id: 9
      }
    ]
  },
  {
    id: '5',
    link: '/financial',
    name: 'Quản lý tài chính',
    icon: `${donation}`,
    position_id: 1,
    children: [
      {
        id: '5.1',
        link: ADMIN_ROUTES.FINANCIAL_DONATE,
        name: 'Quản lý donate',
        children: [],
        position_id: 9
      },
      {
        id: '5.2',
        link: ADMIN_ROUTES.FINANCIAL_ADS,
        name: 'Quản lý quảng cáo',
        children: [],
        position_id: 9
      }
    ]
  },
  {
    id: '6',
    link: '/setting',
    name: 'Cài đặt',
    icon: `${settings}`,
    position_id: 1,
    children: [
      {
        id: '6.1',
        link: ADMIN_ROUTES.SETTING_PERMISSION,
        name: 'Cài đặt phân quyền',
        children: [],
        position_id: 9
      }
    ]
  }
];
