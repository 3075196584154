import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Switch from 'react-switch';
import { withTranslation } from 'react-i18next';

import { NEW_SERI_REQUEST } from '../../constants/config';
import {
  destructServerErrors,
  destructServerMessage
} from '../../helpers/error';
import { postData } from '../../services/base_services';
import { showMessage } from '../../helpers/table';

import TextArea from '../../component/common/TextArea';
import InputText from '../../component/common/InputText';
import ButtonSave from '../../component/common/ButtonSave';
import TextEditor from '../../component/common/TextEditor';
import GroupPhoto from '../../component/common/GroupPhoto';

class NewSeri extends Component {
  constructor(props) {
    super(props);
    this.documents = [];
    this.state = {
      status: false,
      errors: [],
      contentText: '',
      is_show_new: true,
      thumbnail: ''
    };
  }

  addNew = (e) => {
    e.preventDefault();
    const { contentText, is_show_new } = this.state;
    const { history } = this.props;
    let formData = [];

    formData = {
      content: contentText,
      description: this.description?.value,
      name: this.name?.value,
      status: 1,
      is_show: is_show_new,
      banner: this.thumbnail?.value
    };

    postData(NEW_SERI_REQUEST, formData)
      .then((res) => {
        showMessage(res?.data?.message, true);
        this.setState({
          errors: [],
          status: true
        });
        history.goBack();
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);
        this.setState({
          errors: errss
        });
        if (err?.response?.status == 422) {
          return null;
        }
        showMessage(errs, false);
      });
  };

  addDraft = (e) => {
    e.preventDefault();
    const { contentText, is_show_new } = this.state;
    const { history } = this.props;
    let formData = [];
    formData = {
      content: contentText,
      description: this.description?.value,
      name: this.name?.value,
      status: 1,
      is_show: is_show_new,
      banner: this.thumbnail?.value
    };
    postData(NEW_SERI_REQUEST, formData)
      .then((res) => {
        showMessage(res?.data?.message, true);
        this.setState({
          errors: [],
          status: true
        });
        history.goBack();
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);
        this.setState({
          errors: errss
        });
        if (err?.response?.status == 422) {
          return null;
        }
        showMessage(errs, false);
      });
  };

  onChangeDocument = (data) => {
    this.documents = data;
  };

  changeEditer = (e, editor) => {
    const data = editor.getData();
    this.setState({
      contentText: data
    });
  };

  handleDisplayToHome = (e) => {
    this.setState({
      is_show_new: e
    });
  };

  render() {
    const { t } = this.props;

    const { errors, is_show_new, thumbnail } = this.state;
    const { history } = this.props;

    return (
      <>
        <div className="body-right-bottom document-wrap">
          <div className="container-fluid container-shadow">
            <div className="row top-table">
              <div className="col-md-12 top-table-title">
                <p>{t('seri.header')}</p>
              </div>
              <div className="col-md-1 top-table-border "></div>
              <div className="col-md-12 top-table-text">
                <p>{t('seri.headerFormAdd')}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-title">
                <p>{t('seri.headerFormAdd')}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <InputText
                  className="form-control input-form"
                  placeholder={t('seri.title')}
                  name="name"
                  ref={(c) => {
                    this.name = c;
                  }}
                  label={t('document.title')}
                  errors={errors}
                  type="text"
                  required={true}
                />
              </div>
              <div className="col-md-12">
                <TextArea
                  className="form-control input-form"
                  placeholder={t('document.description')}
                  name="description"
                  ref={(c) => {
                    this.description = c;
                  }}
                  label={t('document.description')}
                  errors={errors}
                  type="textarea"
                />
              </div>
              <div className="col-md-12">
                <TextEditor
                  name="content"
                  label={t('document.content')}
                  errors={errors}
                  onChange={this.changeEditer}
                  required={true}
                />
              </div>

              <div className="col-md-6">
                <GroupPhoto
                  label={t('document.banner')}
                  name="banner"
                  title={t('document.changeImgBanner')}
                  errors={[]}
                  order={1}
                  required={true}
                  ref={(c) => {
                    this.thumbnail = c;
                  }}
                  defaultValue={thumbnail}
                />
              </div>

              <div className="col-md-6">
                <label className="label-banner-mobile col-md-12">
                  {t('seri.display')}
                </label>
                <Switch
                  onChange={this.handleDisplayToHome}
                  checked={is_show_new}
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="mx-auto  my-5 d-flex flex-wrap">
                <ButtonSave
                  text={t('seri.saveDraft')}
                  className="btn btn-draf"
                  onClick={this.addDraft}
                />
                <ButtonSave
                  onClick={this.addNew}
                  text={t('seri.posts')}
                  className="btn btn-new"
                />
                <div onClick={history.goBack} role="button">
                  <ButtonSave
                    text={t('seri.cancel')}
                    className="btn btn-cancel"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

NewSeri.propTypes = {
  history: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state?.user
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withTranslation()(NewSeri)));
