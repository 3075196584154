/* eslint-disable react/display-name */
import React from 'react';
import { Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import PostWeb from '../container/PostWeb/PostWebTable';
import PostWebNew from '../container/PostWeb/PostWebAdd';
import PostWebEdit from '../container/PostWeb/PostWebEdit';
import TechniqueTable from '../container/PostWeb/TechiqueTable';
import ActivitiesTable from '../container/PostWeb/ActivitiesTable';
import ContactTable from '../container/PostWeb/ContactTable';

import BannerTable from '../container/Banner/BannerTable';
import BannerNew from '../container/Banner/BannerAdd';
import BannerEdit from '../container/Banner/BannerEdit';

import Form from '../container/Form/FormTable';
import FormNew from '../container/Form/FormAdd';
import FormEdit from '../container/Form/FormEdit';
import { ADMIN_ROUTES } from '../constants/router';
import BranchBanner from '../container/Banner/BranchBanner';
import BranchBannerEdit from '../container/Banner/BranchBannerEdit';
import TechnicalBanner from '../container/Banner/TechnicalBanner';
import TechnicalBannerEdit from '../container/Banner/TechnicalBannerEdit';
import GroupBanner from '../container/Banner/GroupBanner';
import GroupBannerEdit from '../container/Banner/GroupBannerEdit';

const routes = [
  {
    path: '/postweb',
    component: () => <PostWeb />,
    exact: true,
    meta: {
      action: 'form_managerment',
      subject: 'form'
    }
  },
  {
    path: ADMIN_ROUTES.POSTWEB_TECHNIQUE,
    component: () => <TechniqueTable />,
    exact: true
  },
  {
    path: ADMIN_ROUTES.POSTWEB_ACTIVITIES,
    component: () => <ActivitiesTable />,
    exact: true
  },
  {
    path: '/postweb/new',
    component: () => <PostWebNew />,
    exact: true
  },
  {
    path: '/postweb/edit/:id',
    component: () => <PostWebEdit />,
    exact: true
  },

  {
    path: ADMIN_ROUTES.POSTWEB_BANNER,
    component: () => <BannerTable />,
    exact: true,
    meta: {
      action: 'banner_managerment',
      subject: 'banner'
    }
  },
  {
    path: '/postweb/banner/new',
    component: () => <BannerNew />,
    exact: true
  },
  {
    path: '/postweb/banner/edit/:id',
    component: () => <BannerEdit />,
    exact: true
  },
  {
    path: ADMIN_ROUTES.POSTWEB_FORM,
    component: () => <Form />,
    exact: true,
    meta: {
      action: 'form_managerment',
      subject: 'form'
    }
  },
  {
    path: '/postweb/form/new',
    component: () => <FormNew />,
    exact: true
  },
  {
    path: '/postweb/form/edit/:id',
    component: () => <FormEdit />,
    exact: true
  },
  {
    path: ADMIN_ROUTES.POSTWEB_BANNER_BRANCH,
    component: () => <BranchBanner />,
    exact: true
  },
  {
    path: '/postweb/banner/branch/edit/:id',
    component: () => <BranchBannerEdit />,
    exact: true
  },
  {
    path: ADMIN_ROUTES.POSTWEB_BANNER_TECHNICAL,
    component: () => <TechnicalBanner />,
    exact: true
  },
  {
    path: '/postweb/banner/technical/edit/:id',
    component: () => <TechnicalBannerEdit />,
    exact: true
  },
  {
    path: ADMIN_ROUTES.POSTWEB_BANNER_GROUP,
    component: () => <GroupBanner />,
    exact: true
  },
  {
    path: '/postweb/banner/group/edit/:id',
    component: () => <GroupBannerEdit />,
    exact: true
  },
  {
    path: '/postweb/contact',
    component: () => <ContactTable />,
    exact: true
  }
];

const PostWebRoutes = () => {
  if (typeof window !== 'undefined') {
    return (
      <>
        <Switch>
          {routes.map((route) => (
            <AuthRoute key={route.path} {...route} />
          ))}
        </Switch>
      </>
    );
  }
  return '';
};

export default PostWebRoutes;
