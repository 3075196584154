import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import mailLogin from '../../assets/images/icon/mail-login.svg';
import logo from '../../assets/images/logo.svg';
import { getError } from '../../helpers/error';
export const Alert = (props) => {
  return props.message ? (
    <div className="invalid-feedback" id="invalid-feedback-custom">
      {' '}
      {props.message}{' '}
    </div>
  ) : (
    ''
  );
};
class Form extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  onSubmit = (e) => {
    e.preventDefault();
    let data = {
      email: this.refs.email.value
    };
    this.props.submitForm(data);
  };

  render() {
    let { errors, isLoading } = this.props;
    return (
      <div className="form-body">
        <form className="login-form" onSubmit={this.onSubmit}>
          <img className="login-form-logo" src={logo} alt="" srcSet="" />
          <div className="login-form-wel">
            <p>
              Bạn hãy nhập email mà bạn sử dụng trong hệ thống. Chúng tôi sẽ gửi
              một email xác nhận đến địa chỉ này
            </p>
          </div>

          <div className="login-form-input">
            <img className="login-form-input-img" src={mailLogin} alt="" />
            <input type="text" placeholder="Email" name="email" ref="email" />
          </div>
          <Alert message={getError(errors, 'email')} />
          <div className="row">
            <Link to="/login" className="btn-return-forget">
              Quay lại
            </Link>
            <button
              className="btn btn-primary login-form-btn"
              disabled={isLoading}
            >
              Khôi phục mật khẩu
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(null, null)(withRouter(Form));
