import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import ButtonSave from '../../component/common/ButtonSave';
import Datepicker from '../../component/common/Datepicker';
import DatePickerTime from '../../component/common/DatePickerTime';
import InputText from '../../component/common/InputText';
import Select from '../../component/common/Select';
import TextEditor from '../../component/common/TextEditor';
import UploadImg from '../../component/common/UploadImg';
import {
  ADD_COMPETITION,
  GET_ALL_BELT,
  GET_CLUB_BRANCH,
  GET_LIST_BRANCH
} from '../../constants/config';
import {
  destructServerErrors,
  destructServerMessage
} from '../../helpers/error';
import { showMessage } from '../../helpers/table';
import {
  getDataByID,
  getTakenData,
  postData
} from '../../services/base_services';

class AddCompetition extends Component {
  constructor(props) {
    super(props);
    this.documents = [];
    this.state = {
      status: false,
      errors: [],
      listBranch: [],
      listBelt: [],
      contextData: [],
      listDoc: [],
      listClub: []
    };
  }

  addNew = (e) => {
    e.preventDefault();
    const { contextData } = this.state;
    const { user } = this.props;
    let formData = [];
    formData = {
      certificate_id: this.certificateId.value,
      title: this.title.value,
      organized_by_branch: this.branchId.value,
      organized_by_club: this.organized_by_club
        ? this.organized_by_club.value
        : null,
      address: this.address.value,
      regis_expiry_date: this.regis_expiry_date.value
        .split('-')
        .reverse()
        .join('-'),
      regis_start_date: this.regis_start_date.value
        .split('-')
        .reverse()
        .join('-'),
      exam_date: this.exam_date.value,
      content_notify: contextData,
      attach_files: this.documents,
      num_member_regis: 50,
      num_examiner: 0,
      created_by: user.user.id,
      par_conditions: parseInt(this.certificateId.value) - 1
    };
    postData(ADD_COMPETITION, formData)
      .then((res) => {
        showMessage('Thêm mới thành công', true);
        this.setState({
          errors: [],
          status: true
        });
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);
        this.setState({
          errors: errss
        });
        if (err.response.status == 422) {
          return null;
        }
        showMessage(errs, false);
      });
  };

  componentDidMount = () => {
    this.getList();
  };

  getList = async () => {
    const { user } = this.props;
    const positionId = user && user.user && user.user.position_id;
    await getTakenData(GET_LIST_BRANCH).then((res) => {
      this.setState({
        listBranch: res.data
      });
    });
    await getTakenData(GET_ALL_BELT).then((res) => {
      this.setState({
        listBelt: res.data
      });
    });
    if (positionId == 2 || positionId == 3) {
      const idBranchList = this.state.listBranch[0].id;
      await getDataByID(GET_CLUB_BRANCH, idBranchList).then((res) => {
        this.setState({
          listClub: res.data
        });
      });
    }
  };

  onChangeDocument = (data) => {
    this.documents = data;
  };

  changeEditer = (e, editor) => {
    const data = editor.getData();
    this.setState({
      contextData: data
    });
  };

  chosseBranch = async (e) => {
    const branch_id = e.target.value;
    await getDataByID(GET_CLUB_BRANCH, branch_id).then((res) => {
      this.setState({
        listClub: res.data
      });
    });
  };

  render() {
    const formatDate2 = 'dd-MM-yyyy';
    const formatDate = 'dd-MM-yyyy hh:mm:ss a';
    const { status, errors, listBranch, listBelt, listClub } = this.state;
    const { history, user } = this.props;
    const positionId = user && user.user && user.user.position_id;
    const club_id = user && user.user && user.user.club_id;
    const listClubTmp = listClub.filter((item) => {
      return item.id == club_id;
    });
    if (status) {
      return (
        <Redirect
          to={{
            pathname: '/active/competition'
          }}
        />
      );
    }
    return (
      <>
        <div className="body-right-bottom">
          <div className="container-fluid content">
            <div className="row top-table">
              <div className="col-md-12 top-table-title">
                <p>Quản Lý Hoạt Động</p>
              </div>
              <div className="col-md-1 top-table-border "></div>
              <div className="col-md-12 top-table-text">Kỳ Thi</div>
            </div>
            <div className="content-form">
              <div className="row">
                <div className="col-md-12 content-title">
                  <p>Tạo Kỳ Thi Mới</p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <Select
                    label="Loại kỳ thi"
                    className="form-control-white input-form mb-3 input-blu"
                    name="certificate_id"
                    ref={(c) => {
                      this.certificateId = c;
                    }}
                    errors={errors}
                    key_value="id"
                    key_label="name"
                    include_blank="Loại kỳ thi"
                    data={listBelt}
                    required={true}
                  />
                </div>
                <div className="col-md-6">
                  <InputText
                    className="form-control input-form"
                    placeholder="Tiêu đề"
                    name="title"
                    ref={(c) => {
                      this.title = c;
                    }}
                    label="Tên kỳ thi "
                    errors={errors}
                    type="text"
                    required={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {positionId == 2 || positionId == 3 ? (
                    <Select
                      label="Đối tượng tham gia:"
                      className="form-control-select input-form input-form-select-custom"
                      name="organized_by"
                      ref={(c) => {
                        this.branchId = c;
                      }}
                      disabled
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      data={listBranch}
                    />
                  ) : (
                    <Select
                      label="Đối tượng tham gia:"
                      className="form-control-select input-form"
                      name="organized_by"
                      ref={(c) => {
                        this.branchId = c;
                      }}
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      include_blank="Tất cả phân đường"
                      data={listBranch}
                      onChange={this.chosseBranch}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  {positionId == 3 ? (
                    <Select
                      label="Câu Lạc Bộ/Võ Đường:"
                      className="form-control-select input-form input-form-select-custom"
                      name="organized_by_club"
                      errors={errors}
                      key_value="id"
                      disabled
                      key_label="name"
                      data={listClubTmp}
                      ref={(c) => {
                        this.organized_by_club = c;
                      }}
                    />
                  ) : (
                    <Select
                      label="Câu Lạc Bộ/Võ Đường:"
                      className="form-control-select input-form"
                      name="organized_by_club"
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      include_blank="Tất cả Câu Lạc Bộ/Võ Đường"
                      data={listClub}
                      ref={(c) => {
                        this.organized_by_club = c;
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Datepicker
                    label="Thời gian bắt đầu nhận hồ sơ"
                    className="form-control input-form"
                    name="regis_start_date"
                    ref={(c) => {
                      this.regis_start_date = c;
                    }}
                    errors={errors}
                    clearIcon={false}
                    format={formatDate2}
                    required={true}
                  />
                </div>
                <div className="col-md-6">
                  <Datepicker
                    label="Thời gian hết hạn nhận hồ sơ "
                    className="form-control input-form"
                    name="regis_expiry_date"
                    ref={(c) => {
                      this.regis_expiry_date = c;
                    }}
                    errors={errors}
                    clearIcon={false}
                    format={formatDate2}
                    required={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <DatePickerTime
                    label="Thời gian tổ chức kỳ thi"
                    required={true}
                    className="form-control input-form"
                    name="exam_date"
                    ref={(c) => {
                      this.exam_date = c;
                    }}
                    errors={errors}
                    clearIcon={false}
                    format={formatDate}
                  />
                </div>
                <div className="col-md-6">
                  <InputText
                    className="form-control input-form"
                    placeholder="Địa điểm"
                    name="address"
                    ref={(c) => {
                      this.address = c;
                    }}
                    label="Địa điểm tổ chức"
                    errors={errors}
                    type="text"
                    required={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <TextEditor
                    name="content_notify"
                    label="Thông tin chung "
                    errors={errors}
                    onChange={this.changeEditer}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="input-label">Hình ảnh đính kèm</label>
                  <UploadImg
                    label="hình ảnh đính kèm"
                    name="main_exam"
                    title="Chọn ảnh để tải"
                    onChange={this.onChangeDocument}
                    errors={[]}
                    default={this.documents}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-add-buttons">
                    <div
                      onClick={history.goBack}
                      onKeyPress={[]}
                      role="button"
                      tabIndex={0}
                    >
                      <ButtonSave text="Hủy" className="btn btn-cancel" />
                    </div>
                    <ButtonSave
                      onClick={this.addNew}
                      text="Lưu thông tin"
                      className="btn btn-new ml-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

AddCompetition.propTypes = {
  history: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(withRouter(AddCompetition));
