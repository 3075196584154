export const COLORS_AGE = [
  {
    name: 'Dưới 13 tuổi',
    color: '#F9B248'
  },
  {
    name: 'Từ 13 đến 18 tuổi',
    color: '#0050FD'
  },
  {
    name: 'Trên 18 tuổi',
    color: '#500003'
  }
];
