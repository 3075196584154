import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { checkLogin } from '../../actions/index';
import Loading from '../../assets/images/loading.gif';
import { BASE_URL_SITE_REGISTER } from '../../constants/config';

class CallBackLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { history } = this.props;
    const {token} = this.props.match.params;
    const { callbackLogin } = this.props;
    callbackLogin(token)
      .then((res) => {
        history.push('/home');
      })
      .catch((error) => {
        window.location.href = { BASE_URL_SITE_REGISTER };
      });
  };

  render() {
    return <div className="main-loading">
    <img src={Loading} alt="loading" />
  </div>;
  }
}

const MapDispatchToProp = (dispatch) => {
  return {
    callbackLogin: (data) => dispatch(checkLogin(data))
  };
};

export default connect(null, MapDispatchToProp)(withRouter(CallBackLogin));
