import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { formatDate } from '../../helpers/form';
import { showMessage } from '../../helpers/table';
import {
  postDataWithIDParams,
  putDataAccept
} from '../../services/base_services';
import {
  destructServerErrors,
  destructServerMessage
} from '../../helpers/error';

import Table from '../../component/common/TableStudent';
import InputSearch from '../../component/common/InputSearch';
import Paginate from '../../component/Paginate';
import NavStudent from './NavStudent';
class StudentDocumentManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data1: [],
      tableHeaders: [
        {
          label: 'STT',
          index: 'stt',
          option: {
            className: 'text-center position-relative'
          },
          callback: null
        },
        {
          label: 'TÊN TƯ LIỆU',
          option: {
            className: 'width-300'
          },
          index: null,
          callback: (data) => {
            return (
              <div>
                {data?.library !== null ? (
                  <span className="text-left">{data?.library?.title}</span>
                ) : null}
              </div>
            );
          }
        },
        {
          label: 'LOẠI TƯ LIỆU',
          index: null,
          option: {
            className: 'text-center width-200'
          },
          callback: (data) => {
            return (
              <div>
                {data?.library !== null ? (
                  <>
                    {data?.library?.catalog_id === 1 && <span>Video</span>}
                    {data?.library?.catalog_id === 5 && <span>Document</span>}
                    {data?.library?.catalog_id === 3 && <span>Ebook</span>}
                    {data?.library?.catalog_id === 4 && <span>Photo</span>}
                  </>
                ) : null}
              </div>
            );
          }
        },

        {
          label: 'NGÀY BẮT ĐẦU',
          index: null,
          option: {
            className: 'text-center width-200'
          },
          callback: (data) => {
            return (
              <div>
                {data?.library !== null ? (
                  <span>
                    {data && data.updated_at && formatDate(data.updated_at)}
                  </span>
                ) : null}
              </div>
            );
          }
        }

        // {
        //   label: 'CHỨC NĂNG',
        //   index: null,
        //   option: {
        //     className: 'text-center fixed-collumn'
        //   },
        //   callback: (data) => {
        //     if (data.id) {
        //       return (
        //         <div className="btn-group">
        //           <span data-tip="Xóa tư liệu" className="link-action ml-2">
        //             <img
        //               src={deleteIcon}
        //               onClick={() => this.onRejectRequest(data.id)}
        //             />
        //           </span>
        //           <ReactTooltip
        //             className="tooltip-button"
        //             place="bottom"
        //             effect="float"
        //           />
        //         </div>
        //       );
        //     }
        //     return '';
        //   }
        // }
      ],
      tableData: [],
      paginate: [],
      isLoading: false
    };
  }
  onChangeSearch = (e) => {
    clearTimeout(this.timer);
    const { page, perPage } = this.state;
    const { target } = e;
    const { name } = target;
    const value = target.value ? target.value : '';
    this.keyword = {
      ...this.keyword,
      [name]: value
    };
    this.timer = setTimeout(
      () =>
        this.getListRequest({
          page: 1,
          per_page: perPage,
          keyword: this.keyword.search_text || ''
        }),
      500
    );
    this.setState({
      page: 1
    });
  };

  onRejectRequest = (data) => {
    putDataAccept('library_member', 'reject-request', data)
      .then(this.getListDocument())
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({
          errors: [],
          status: true
        });
        // history.goBack();
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);

        this.setState({
          errors: errss
        });
        if (err.response.status == 422) {
          return null;
        }
        showMessage(errs, false);
      });
  };

  onSubmitSearch = (e) => {
    e.preventDefault();
    clearTimeout(this.timer);
    const { page, perPage } = this.state;
    const { target } = e;
    const { name } = target;
    const value = target.value ? target.value : '';
    this.keyword = {
      ...this.keyword,
      [name]: value
    };
    this.timer = setTimeout(
      () =>
        this.getListRequest({
          page,
          per_page: perPage,
          keyword: this.keyword.search_text || ''
        }),
      500
    );
  };

  getListDocument = async () => {
    const { page, perPage } = this.state;
    this.getListRequest({
      page,
      per_page: perPage
      // keyword: this.keyword.search_text || ''
    });
  };

  handleInputChange = (e) => {
    let value = e.target.checked;
    if (value) {
      this.setState({
        // overviewFlg: 1
      });
    } else {
      this.setState({
        // overviewFlg: 0
      });
    }
  };

  getListRequest = async (params) => {
    const { perPage } = this.state;
    const { id } = this.props.match.params;
    await postDataWithIDParams('privatelibraries', id, params)
      .then((res) => {
        this.setState({
          data1: res?.data,
          tableData: res.data.data,
          paginate: {
            ...res.data.paginate,
            perPage
          },
          isLoading: false
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  componentDidMount = () => {
    this.getListDocument();
  };

  render() {
    const { tableHeaders, tableData, paginate, isLoading } = this.state;
    return (
      <div className="body-right-bottom">
        <div className="container-fluid container-shadow">
          <NavStudent />
          <div className="row ">
            <div></div>
            <div className="ml-auto d-flex flex-wrap px-30px mt-5">
              <InputSearch
                onChange={this.onChangeSearch}
                onSubmitSearch={this.onSubmitSearch}
              />
            </div>
          </div>
          <div className="row px-15px ">
            <div className="col-md-12">
              <Table
                fixed
                tableHeaders={tableHeaders}
                tableData={tableData}
                tablePaginate={paginate}
                isLoading={isLoading}
                wtIndex={true}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={this.changePage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(StudentDocumentManagement);
