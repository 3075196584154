import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import GoogleMapReact from 'google-map-react';
import marker from '../../assets/images/icon/maker.svg';
import { GOOGLE_MAP_KEY } from '../../constants/config';
import GroupFlag from './GroupFlag';
class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 11,
      resetMap: true
    };
  }

  handleChange = (address) => {
    const { setAddess, setMap } = this.props;
    setAddess(address);
  };

  handleSelect = (address) => {
    const { setAddess, setMap } = this.props;
    this.setState({
      resetMap: false
    });
    setAddess(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({
          resetMap: true
        });
        setMap({
          lat: latLng.lat,
          lng: latLng.lng
        });
      })
      .catch((error) => console.error('Error', error));
  };

  render() {
    const { label, address, map, setFlag, flag } = this.props;
    const { resetMap } = this.state;
    return (
      <>
        <div className="d-flex w-100">
          <div className="col-md-9 p-0">
            <PlacesAutocomplete
              value={address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
              searchOptions={{
                componentRestrictions: {
                  country: 'vn'
                }
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div>
                  {label && <label className="input-label">{label}</label>}

                  <input
                    {...getInputProps({
                      placeholder: 'Nhập địa chỉ ...',
                      className: 'location-search-input form-control input-form'
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className="col-md-3 mr-0">
            <GroupFlag
              label="Quốc kì"
              name="flag"
              title="Chọn ảnh để tải"
              errors={[]}
              setFlag={setFlag}
              defaultValue={flag ? flag : ''}
            />
          </div>
        </div>
        <div style={{ height: '300px', width: '100%' }} className="mt-3">
          {resetMap && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: GOOGLE_MAP_KEY
              }}
              defaultCenter={{
                lat: map.lat,
                lng: map.lng
              }}
              defaultZoom={15}
            >
              {address && <img src={marker} style={{ height: '12px' }}></img>}
            </GoogleMapReact>
          )}
        </div>
      </>
    );
  }
}

export default LocationSearchInput;
