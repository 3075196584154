import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { destructServerErrors } from '../../helpers/error';
import {
  postData,
  getDataByID,
  putData,
  getTakenData
} from '../../services/base_services';
import {
  GET_BRANCH_REQUEST,
  GET_GROUP_BRANCH_REQUEST
} from '../../constants/config';
import { LIMIT_POSITION } from '../../constants/position';
import { showMessage } from '../../helpers/table';

import createNotification from '../../component/common/Notifications';
import UploadImg from '../../component/common/UploadImg';
import ButtonSave from '../../component/common/ButtonSave';
import TextEditor from '../../component/common/TextEditor';
import InputText from '../../component/common/InputText';
import Select from '../../component/common/Select';
import LocationSearchInput from '../../component/common/LocationSearchInput';

const AddBranch = (props) => {
  const { t } = useTranslation();

  const { history, user } = props;
  const isAddPage = props.match.params.type === 'new';
  const id = props.match.params.id;

  const [branchDetail, setBranchDetail] = useState(null);
  const [bgrImage, setBgrImage] = useState([]);
  const [chiefImage, setChiefImage] = useState([]);
  const [decisionImage, setDecisionImage] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [address, setAddess] = useState('');
  const [map, setMap] = useState({ lng: 108.1760846, lat: 16.0748634 });
  const [flag, setFlag] = useState('');
  const [contentText, setContentText] = useState('');
  const [introducing, setIntroducing] = useState('');
  const [executiveBoard, setExecutiveBoard] = useState('');
  const [dataGroupBranch, setDataGroupBranch] = useState([]);
  const [position, setPosition] = useState(LIMIT_POSITION);

  const name = useRef(null);
  const areaId = useRef(null);

  useEffect(() => {
    if (id) {
      getBranchDetail(id);
    } else {
      setLoading(false);
    }
    getGroupBranch();
  }, []);

  const getGroupBranch = () => {
    getTakenData(GET_GROUP_BRANCH_REQUEST).then((res) => {
      setDataGroupBranch(res?.data);
    });
  };

  const getBranchDetail = (id) => {
    return getDataByID(GET_BRANCH_REQUEST, id)
      .then((res) => {
        setBranchDetail(res.data);
        if (res.data.bgr_image) {
          setBgrImage([res.data.bgr_image]);
        }
        if (res?.data?.main_branch_image) {
          setChiefImage([res?.data?.main_branch_image]);
        }
        if (res?.data?.decision) {
          setDecisionImage([res?.data?.decision]);
        }
        if (res?.data?.intro_branch) {
          setIntroducing(res?.data?.intro_branch);
        }
        if (res?.data?.mains_branch) {
          setExecutiveBoard(res?.data?.mains_branch);
        }
        if (res?.data?.contact_info) {
          setContentText(res?.data?.contact_info);
        }

        setAddess(res.data.address);
        if (res.data.lng && res.data.lat) {
          setMap({
            lng: res.data.lng,
            lat: res.data.lat
          });
        }
        setPosition(res.data.position);

        setLoading(false);
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  };

  const handleSubmit = () => {
    if (isAddPage) {
      const data = {
        name: name.current.value,
        bgr_image: bgrImage,
        main_branch_image: chiefImage,
        decision: decisionImage,
        intro_branch: introducing,
        contact_info: contentText,
        mains_branch: executiveBoard,
        area_id: areaId.current.value,
        address: address,
        lng: map.lng,
        lat: map.lat,
        flag_img: flag,
        position: position
      };

      if (Number.isInteger(Number(position)) && Number(position) > 0) {
        postData(GET_BRANCH_REQUEST, data)
          .then((res) => {
            if (res.status === 201) {
              history.push('/statistical/branch');
              return createNotification(t('branch.success'), res.data.message);
            }
            return createNotification(t('branch.error'), res.data.message);
          })
          .catch((err) => {
            const errss = destructServerErrors(err);
            setErrors(errss);
            if (err.response.status == 422) {
              return null;
            }
            return createNotification(t('branch.error'), err.message);
          });
      } else {
        showMessage(t('branch.errorPosition'), false);
      }
    } else {
      const data = {
        name: name.current.value,
        bgr_image: bgrImage,
        main_branch_image: chiefImage,
        decision: decisionImage,
        intro_branch: introducing,
        contact_info: contentText,
        mains_branch: executiveBoard,
        address: address,
        area_id: areaId.current.value,
        lng: map.lng,
        lat: map.lat,
        flag_img: flag,
        position: position
      };
      if (Number.isInteger(Number(position)) && Number(position) > 0) {
        putData(GET_BRANCH_REQUEST, id, data)
          .then((res) => {
            if (res.status === 200) {
              history.push('/statistical/branch');
              return createNotification(t('branch.success'), res.data.message);
            }
            return createNotification(t('branch.error'), res.data.message);
          })
          .catch((err) => {
            const errss = destructServerErrors(err);
            setErrors(errss);
            if (err.response.status == 422) {
              return null;
            }
            return createNotification(t('branch.error'), err.message);
          });
      } else {
        showMessage(t('branch.errorPosition'), false);
      }
    }
  };

  const changeContactInfo = (e, editor) => {
    const data = editor.getData();
    setContentText(data);
  };

  const changeExecutiveBoard = (e, editor) => {
    const data = editor.getData();
    setExecutiveBoard(data);
  };

  const changeIntroducing = (e, editor) => {
    const data = editor.getData();
    setIntroducing(data);
  };

  const onChangeThumnails = (e) => {
    setBgrImage(e);
  };

  const onChangeChief = (e) => {
    setChiefImage(e);
  };

  const onChangeDecision = (e) => {
    setDecisionImage(e);
  };

  const onChangePosition = (e) => {
    setPosition(e);
    if (!Number.isInteger(Number(e)) || Number(e) <= 0) {
      setErrors({
        ...errors,
        position: [t('branch.errorPosition')]
      });
    }
  };

  return isLoading ? (
    ''
  ) : (
    <>
      <section className="body-right-bottom">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>{t('branch.header')}</p>
            </div>
            <div className="col-md-1 top-table-border "></div>
            <div className="col-md-12 top-table-text">
              {isAddPage ? t('branch.add') : t('branch.edit')}
            </div>
          </div>
          <div className="content-form">
            <div className="row">
              <div className="col-md-12 content-title">
                <p>{isAddPage ? t('branch.add') : t('branch.edit')}</p>
              </div>
            </div>
            <div className="row form-add">
              <div className="col-md-6">
                <div className="form-add-title">
                  <p>{t('branch.headerForm')}</p>
                </div>
              </div>
            </div>
            <div className="row form-add">
              <div className="col-md-6">
                {!_.isEmpty(user) && props?.user?.position_id == 2 && id ? (
                  <InputText
                    className="form-control input-form"
                    placeholder={t('branch.nameBranch')}
                    name="name"
                    ref={name}
                    disabled
                    label={t('branch.nameBranch')}
                    errors={errors}
                    type="text"
                    required={true}
                    defaultValue={branchDetail && branchDetail.name}
                  />
                ) : (
                  <InputText
                    className="form-control input-form"
                    placeholder={t('branch.nameBranch')}
                    name="name"
                    ref={name}
                    label={t('branch.nameBranch')}
                    errors={errors}
                    type="text"
                    required={true}
                    defaultValue={branchDetail && branchDetail.name}
                  />
                )}
              </div>
              <div className="col-md-6">
                <Select
                  label={t('branch.branchOfArea')}
                  className="form-control-select input-form mb-3"
                  name="area_id"
                  ref={areaId}
                  errors={errors}
                  key_value="type"
                  key_label="name"
                  include_blank={t('branch.allBranch')}
                  data={dataGroupBranch}
                  defaultValue={branchDetail?.area}
                  required={true}
                  disabled={user?.position_id >= 2}
                />
              </div>
            </div>
            <div className="row form-add">
              <div className="col-xl-4">
                <label className="input-label">{t('branch.imgBanner')}</label>
                <div className="form-add-box-img">
                  <UploadImg
                    label={t('branch.addImgBanner')}
                    name="bgr_image"
                    title={t('branch.changeImgBanner')}
                    onChange={(e) => onChangeThumnails(e)}
                    errors={[]}
                    default={bgrImage}
                    order={1}
                  />
                </div>
              </div>
              <div className="col-xl-4">
                <label className="input-label">
                  {t('branch.imgManageBranch')}
                </label>
                <div className="form-add-box-img">
                  <UploadImg
                    label={t('branch.addImgBanner')}
                    name="main_branch_image"
                    title={t('branch.changeImgBanner')}
                    onChange={(e) => onChangeChief(e)}
                    errors={[]}
                    default={chiefImage}
                    order={2}
                  />
                </div>
              </div>
              <div className="col-xl-4">
                <label className="input-label">{t('branch.imgDecision')}</label>
                <div className="form-add-box-img">
                  <UploadImg
                    label={t('branch.addImgBanner')}
                    name="decision"
                    title={t('branch.changeImgBanner')}
                    onChange={(e) => onChangeDecision(e)}
                    errors={[]}
                    default={decisionImage}
                    order={3}
                  />
                </div>
              </div>
              <div className="col-md-12 overflow-hidden">
                <style jsx>
                  {`
                    textarea.input-form.form-control {
                      height: 100%;
                      max-height: 400px;
                      overflow-y: hidden;
                    }
                  `}
                </style>
                <TextEditor
                  name="intro_branch"
                  label={t('branch.introduceBranch')}
                  errors={errors}
                  onChange={changeIntroducing}
                  data={introducing}
                />
              </div>
              <div className="col-md-12 overflow-hidden">
                <style jsx>
                  {`
                    textarea.input-form.form-control {
                      height: 100%;
                      max-height: 400px;
                      overflow-y: hidden;
                    }
                  `}
                </style>
                <TextEditor
                  name="mains_branch"
                  label={t('branch.mainsBranches')}
                  errors={errors}
                  onChange={changeExecutiveBoard}
                  data={executiveBoard}
                />
              </div>
              <div className="col-md-12 overflow-hidden">
                <style jsx>
                  {`
                    textarea.input-form.form-control {
                      height: 100%;
                      max-height: 400px;
                      overflow-y: hidden;
                    }
                  `}
                </style>
                <TextEditor
                  name="contact_info"
                  label={t('branch.contact')}
                  errors={errors}
                  onChange={changeContactInfo}
                  data={contentText}
                />
              </div>
              {/* <div className="col-md-12">
                <LocationSearchInput
                  label={t('branch.address')}
                  address={address}
                  setAddess={setAddess}
                  map={map}
                  setMap={setMap}
                  setFlag={setFlag}
                  flag={branchDetail && branchDetail.flag_img}
                />
              </div> */}
              <div className="col-md-6">
                {user?.position_id == 1 && (
                  <InputText
                    className="form-control input-form"
                    placeholder={t('branch.position')}
                    name="position"
                    onChange={(e) => onChangePosition(e?.target?.value)}
                    label={t('branch.labelPosition')}
                    errors={errors}
                    type="number"
                    defaultValue={position}
                    min={1}
                    step={1}
                    required={true}
                  />
                )}
              </div>
              <div className="col-md-12 mt-4 ">
                <div className="form-add-buttons">
                  <Link to="/statistical/branch">
                    <ButtonSave
                      text={t('branch.cancel')}
                      className="btn btn-cancel"
                    />
                  </Link>
                  <ButtonSave
                    onClick={() => handleSubmit()}
                    text={t('branch.save')}
                    className="btn btn-new ml-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

AddBranch.propTypes = {
  match: PropTypes.isRequired,
  history: PropTypes.isRequired,
  user: PropTypes.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user
  };
};

export default connect(mapStateToProps, null)(withRouter(AddBranch));
