import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import {  withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { GET_LIST_MENU } from '../../constants/config';
import { getTakenData } from '../../services/base_services';

import Table from '../../component/common/Table';

import editIcon from '../../assets/images/icon/edit-kn.svg';

const TechnicalBanner = (props) => {
  const { history } = props;

  const [tableData, setTableData] = useState([]);

  const tableHeaders = [
    {
      label: 'STT',
      index: 'stt',
      option: {
        className: 'text-center position-relative'
      },
      callback: null
    },
    {
      label: 'Tiêu đề',
      index: null,
      // eslint-disable-next-line react/display-name
      callback: (data) => {
        return (
          <div>
            <span>{data?.name || '--/--'}</span>
          </div>
        );
      }
    },
    {
      label: 'CHỨC NĂNG',
      index: null,
      option: {
        className: 'text-center fixed-collumn'
      },
      callback: (data) => {
        if (data && data.id) {
          return (
            <div className="btn-group">
              <span
                data-tip="Chỉnh Sửa hình ảnh"
                className="link-action ml-2 mr-2"
              >
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => onEditTechnicalBanner(data.id)}
                >
                  <img alt="edit" src={editIcon} className="btn-icon" />
                </div>
              </span>
              <ReactTooltip
                className="tooltip-button"
                place="bottom"
                effect="float"
              />
            </div>
          );
        }
        return '';
      }
    }
  ];

  const onEditTechnicalBanner = (id) => {
    history.push(`/postweb/banner/technical/edit/${id}`);
  };

  useEffect(() => {
    getTakenData(GET_LIST_MENU)
    .then((res) => {
        setTableData( res?.data?.[0]?.submenus);
    })
    .catch((err) => err);
  }, []);

  return (
    <>
      <section className="body-right-bottom course-wrap">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>Quản Lý hình ảnh nhóm kỹ thuật</p>
            </div>
            <div className="col-md-1 top-table-border "></div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Table
                tableData={tableData}
                tableHeaders={tableHeaders}
                tablePaginate={[]}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(TechnicalBanner);

TechnicalBanner.propTypes = {
  history: propTypes.isRequired
};
