import React, { Component } from 'react';
import Pagination from 'react-js-pagination';

import PropTypes from 'prop-types';

import leftArrow from '../../assets/images/left-ar.png';
import rightArrow from '../../assets/images/right-ar.png';

import PerPage from './PerPage';
import InputText from '../common/InputText';

class Paginate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: ''
    };
  }
  changePage = (page) => {
    const { changePage } = this.props;
    this.setState({ inputValue: '' });

    changePage(page);
  };

  changePerPages = (newPerPage) => {
    const { changePerPage } = this.props;
    this.setState({ inputValue: '' });
    changePerPage(newPerPage);
  };

  onChangeInput = (value) => {
    this.changePage(value);
    this.setState({ inputValue: value });
  };

  render() {
    const { paginate, per, per_page, tablePaginate } = this.props;

    const { inputValue } = this.state;

    if (!paginate.total) {
      return '';
    }

    const perPage = parseInt(paginate.perPage, 10) || per || 5;

    return (
      <div className="d-flex align-items-baseline pr-30 spacing-20 mr-15">
        {per_page && <p>Tổng số: {paginate?.total}</p>}
        <Pagination
          activePage={paginate.current_page}
          itemsCountPerPage={perPage}
          totalItemsCount={paginate.total}
          pageRangeDisplayed={3}
          onChange={this.changePage}
          prevPageText={paginate?.prev && <img src={leftArrow} alt="left" />}
          nextPageText={paginate?.next && <img src={rightArrow} alt="right" />}
          hideFirstLastPages
          itemClassPrev="paginate-nav"
          itemClassNext="paginate-nav"
        />
        {per_page && (
          <PerPage
            per_page={per_page}
            tablePaginate={tablePaginate}
            changePerPage={this.changePerPages}
          />
        )}

        {per_page && <p>Đến trang </p>}
        {per_page && (
          <InputText
            className="form-control input-form"
            errors={''}
            type="number"
            value={inputValue}
            onChange={(e) => this.onChangeInput(e?.target?.value)}
          />
        )}
      </div>
    );
  }
}

Paginate.propTypes = {
  changePage: PropTypes.func.isRequired,
  paginate: PropTypes.isRequired,
  perPage: PropTypes.isRequired,
  per_page: PropTypes.isRequired,
  tablePaginate: PropTypes.isRequired,
  changePerPage: PropTypes.isRequired
};

export default Paginate;
