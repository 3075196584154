import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

import { formatDate } from '../../helpers/form';
import {
  GET_CLUB_BRANCH,
  GET_LIST_BRANCH,
  NEW_COURSE_REQUEST,
  GET_ALL_BELT_COURSE
} from '../../constants/config';
import { destructServerErrors } from '../../helpers/error';
import { getFormDataFromRef } from '../../helpers/form';
import { getTakenData, postData } from '../../services/base_services';

import close from '../../assets/images/images/delete.png';

import ButtonSave from '../../component/common/ButtonSave';
import GroupEbookSmall from '../../component/common/GroupEbookSmall';
import InputText from '../../component/common/InputText';
import createNotification from '../../component/common/Notification';
import Select from '../../component/common/Select';
import Datepicker from '../../component/common/Datepicker';
import TextEditor from '../../component/common/TextEditor';

const formatDate2 = 'dd-MM-yyyy';

class AddCourse extends Component {
  constructor(props) {
    super(props);
    this.documents = [];
    this.state = {
      status: false,
      errors: [],
      listBranch: [],
      idBranch: 0,
      listClub: [],
      listBelt: [],
      isChangeBranch: 0,
      arrDelete: [],
      start_at: '',
      numFiles: [
        {
          file: '',
          title: ''
        }
      ],
      urlFiles: {},
      summary: ''
    };
  }

  //test add course
  getListBelt = async () => {
    await getTakenData(GET_ALL_BELT_COURSE).then((res) => {
      this.setState({
        listBelt: res && res.data
      });
    });
  };
  getListBranch = async () => {
    const { user } = this.props;
    const positionId = user && user.user && user.user.position_id;
    await getTakenData(GET_LIST_BRANCH).then((res) => {
      this.setState({
        listBranch: res && res.data
      });
    });
    if (positionId == 2 || positionId == 3) {
      const idBranchList = this.state.listBranch[0].id;
      this.getListClub(idBranchList);
    }
  };
  handleChangeBranch = () => {
    const organized_by_branch = this.IDBranchRef.value;
    this.getListClub(organized_by_branch);
  };
  getListClub = async (id) => {
    if (id > 0)
      await getTakenData(`${GET_CLUB_BRANCH}/${id}`).then((res) => {
        this.setState({
          listClub: res && res.data
        });
      });
    else
      this.setState({
        listClub: []
      });
  };

  handleAddInput = () => {
    const { numFiles } = this.state;
    const temp = {
      title: '',
      file: ''
    };
    this.setState({
      numFiles: [...numFiles, temp]
    });
  };

  deleteFile = (e, id) => {
    e.preventDefault();
    let inputDeleteElm = document.querySelector(`#div-${id}`);
    if (inputDeleteElm) {
      inputDeleteElm.innerHTML = '';
      this.setState({
        arrDelete: [...this.state.arrDelete, id]
      });
    }
    return;
  };

  onSubmit = () => {
    const { user, history } = this.props;
    const { numFiles, urlFiles } = this.state;

    const idUser = user && user.user && user.user.id;
    const title = this.nameRef.value;
    const par_conditions = this.IDBeltRef.value;
    const organized_by_branch =
      this.IDBranchRef.value === '' ? null : this.IDBranchRef.value;
    const organized_by_club =
      this.IDClubRef && this.IDClubRef.value === ''
        ? null
        : this.IDClubRef.value;
    const course_overview = this.summary;

    const dataFile = getFormDataFromRef(this.refs);

    let attach_files = [];
    numFiles.map((item, index) => {
      attach_files = [
        ...attach_files,
        {
          file: urlFiles[index].length === 0 ? '' : urlFiles[index][0],
          title: dataFile[`indexText${index}`]
        }
      ];
    });

    for (let i = 0; i < this.state.arrDelete.length; i++) {
      attach_files.splice(this.state.arrDelete[i], 1, 'file was delete');
    }
    let attach_files_select = attach_files.filter(
      (item) => item !== 'file was delete'
    );

    const formData = {
      title,
      par_conditions,
      organized_by_branch,
      organized_by_club,
      start_at: this.state.start_at,
      created_by: idUser,
      is_sendnotify: false,
      course_overview,
      num_lesson: attach_files.length - this.state.arrDelete.length || 0,
      attach_files: attach_files_select,
      status: 2
    };

    postData(NEW_COURSE_REQUEST, formData)
      .then((res) => {
        createNotification(
          this.props.t('courses.success'),
          `${this.props.t('courses.addSuccess')} ${title}`
        );
        this.setState({
          errors: ''
        });
        history.push('/active/course');
      })
      .catch((err) => {
        const errss = destructServerErrors(err);
        this.setState({
          errors: errss
        });
        if (err.response.status == 422) {
          return null;
        }
        return createNotification(this.props.t('courses.error'), err.message);
      });
  };

  handleChangeDate = (date) => {
    this.setState({ start_at: formatDate(date, 'yyyy-mm-dd') });
  };

  componentDidMount = () => {
    this.getListBelt();
    this.getListBranch();
  };

  handleChangeSummary = (e, editor) => {
    const data = editor.getData();
    this.summary = data;
  };

  render() {
    const { errors, listClub, listBranch, listBelt, numFiles } = this.state;
    const { history, location, user } = this.props;
    const positionId = user && user.user && user.user.position_id;
    const club_id = user && user.user && user.user.club_id;
    const listClubTmp = listClub.filter((item) => {
      return item.id == club_id;
    });
    const id = location && location.query && location.query.log_id;
    return (
      <>
        <section className="body-right-bottom">
          <div className="container-fluid content course">
            <div className="row top-table">
              <div className="col-md-12 top-table-title">
                <p>{this.props.t('courses.header')} </p>
              </div>
              <div className="col-md-1 top-table-border "></div>
              <div className="col-md-12 top-table-text">
                {this.props.t('courses.headerFormAdd')}
              </div>
            </div>

            <div className="content-form">
              <div className="row">
                <div className="col-md-12 content-title">
                  <p>{this.props.t('courses.headerFormAdd')}</p>
                </div>
              </div>
              <div className="form-add">
                <div className="row">
                  <div className="col-md-12">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('courses.nameCourses')}
                      name="title"
                      ref={(r) => {
                        this.nameRef = r;
                      }}
                      label={this.props.t('courses.nameCourses')}
                      required={true}
                      errors={errors}
                      type="text"
                    />
                  </div>

                  <div className="col-md-6">
                    <Select
                      className="form-control-white input-form mb-3 input-blu"
                      label={this.props.t('courses.registrationConditions')}
                      required={true}
                      name="par_conditions"
                      ref={(r) => {
                        this.IDBeltRef = r;
                      }}
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      include_blank={this.props.t(
                        'courses.registrationConditions'
                      )}
                      data={listBelt}
                    />
                  </div>
                  <div className="col-md-6">
                    <Datepicker
                      label={this.props.t('courses.timeToStart')}
                      required={true}
                      className="form-control input-form"
                      name="start_at"
                      value={this.state.start_at}
                      errors={errors}
                      clearIcon={true}
                      format={formatDate2}
                      onChange={(date) => this.handleChangeDate(date)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-add-title form-add-title-custom form-add-title--small">
                      <p>{this.props.t('courses.object')}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {positionId == 2 || positionId == 3 ? (
                      <Select
                        label=""
                        className="form-control-select input-form form-control-product mb-3 input-grey input-form-select-custom"
                        name="branch_id"
                        ref={(r) => {
                          this.IDBranchRef = r;
                        }}
                        disabled
                        errors={errors}
                        key_value="id"
                        key_label="name"
                        include_blank=""
                        data={listBranch}
                      />
                    ) : (
                      <Select
                        label=""
                        className="form-control-select input-form form-control-product mb-3 input-grey"
                        name="branch_id"
                        ref={(r) => {
                          this.IDBranchRef = r;
                        }}
                        errors={errors}
                        key_value="id"
                        key_label="name"
                        data={listBranch}
                        include_blank={this.props.t('courses.branch')}
                        onChange={this.handleChangeBranch}
                      />
                    )}
                  </div>

                  <div className="col-md-6">
                    {positionId == 3 ? (
                      <Select
                        label=""
                        className="form-control-select input-form form-control-product mb-3 input-grey input-form-select-custom"
                        name="branch_id"
                        ref={(r) => {
                          this.IDClubRef = r;
                        }}
                        disabled
                        errors={errors}
                        key_value="id"
                        key_label="name"
                        data={listClubTmp}
                      />
                    ) : (
                      <Select
                        label=""
                        className="form-control-select input-form form-control-product mb-3 input-grey"
                        name="branch_id"
                        ref={(r) => {
                          this.IDClubRef = r;
                        }}
                        errors={errors}
                        key_value="id"
                        key_label="name"
                        include_blank={this.props.t('courses.club')}
                        data={listClub}
                      />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-add-title form-add-title-custom form-add-title--small">
                      <p>{this.props.t('courses.lesson')}</p>
                    </div>
                    {errors && errors.attach_files ? (
                      <div className="form-add-error">
                        <p>{errors.attach_files}</p>
                      </div>
                    ) : null}

                    {numFiles.map((item, index) => (
                      <>
                        <div
                          className="row position-relative"
                          key={index}
                          id={`div-${index}`}
                        >
                          <div className="col-md-6">
                            <InputText
                              className="form-control input-form course-custom-input"
                              placeholder={this.props.t('courses.lesson')}
                              name={`indexText${index}`}
                              ref={`indexText${index}`}
                              label={this.props.t('courses.nameLesson')}
                              errors={errors}
                              type="text"
                              order={index}
                            />
                          </div>
                          <div className="col-md-6">
                            <div className="upload-wrap">
                              <GroupEbookSmall
                                label={this.props.t('courses.document')}
                                title={this.props.t('courses.changeFile')}
                                name={`fileIndex${index}`}
                                ref={`fileIndex${index}`}
                                errors={errors}
                                onChange={(data) =>
                                  this.setState({
                                    urlFiles: {
                                      ...this.state.urlFiles,
                                      [index]: data
                                    }
                                  })
                                }
                                default={[]}
                                order={index}
                              />
                              <div
                                className="cursor-pointer"
                                onClick={(e) => this.deleteFile(e, index)}
                              >
                                <img src={close} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>

                  <div className="col-12">
                    <ButtonSave
                      onClick={this.handleAddInput}
                      text={this.props.t('courses.addLesson')}
                      className="btn btn-new ml-0 mb-4"
                    />
                  </div>

                  <div className="col-12">
                    <TextEditor
                      name="course_overview"
                      label={this.props.t('courses.summary')}
                      errors={errors}
                      onChange={this.handleChangeSummary}
                      data={this.summary}
                    />
                  </div>

                  <div className="col-12">
                    <div className="form-add-buttons">
                      <ButtonSave
                        onClick={history.goBack}
                        text={this.props.t('courses.cancel')}
                        className="btn btn-cancel"
                      />
                      <ButtonSave
                        onClick={this.onSubmit}
                        text={this.props.t('courses.save')}
                        className="btn btn-new ml-0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

AddCourse.propTypes = {
  history: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withTranslation()(AddCourse)));
