import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  GET_DETAIL_POSTWEB,
  UPDATE_POSTWEB,
  GET_LIST_MENU
} from '../../constants/config';
import { destructServerErrors } from '../../helpers/error';
import {
  putData,
  getDataByID,
  getTakenData
} from '../../services/base_services';
import { LIMIT_POSITION } from '../../constants/position';
import { showMessage } from '../../helpers/table';

import Select from '../../component/common/Select';
import TextArea from '../../component/common/TextArea';
import InputText from '../../component/common/InputText';
import ButtonSave from '../../component/common/ButtonSave';
import TextEditor from '../../component/common/TextEditor';
import GroupFileCasorel from '../../component/common/GroupOneFile';
import createNotification from '../../component/common/Notification';
import GroupPhoto from '../../component/common/GroupPhoto';

const NewAds = (props) => {
  const { t } = useTranslation();
  const { history, match } = props;
  const id = match && match.params && match.params.id;
  let formData = {};
  const titleRef = useRef('');
  const bgr = useRef([]);
  const typeRef = useRef();
  const descriptionRef = useRef();
  const banner = useRef([]);

  const [isLoading, setLoading] = useState(true);
  const [detail, setDetail] = useState([]);
  const [errors, setErrors] = useState([]);
  const [document, setDocument] = useState([]);
  const [typeF, setTypeF] = useState(1);
  const [typeDocument, setTypeDocument] = useState(0);
  const [menus, setMenus] = useState([]);
  const [subMenus, setSubMenus] = useState([]);
  const [contentText, setContentText] = useState('');
  const [position, setPosition] = useState(LIMIT_POSITION);

  const getMenus = () => {
    getTakenData(GET_LIST_MENU).then(async (res) => {
      setMenus(
        res.data.map((value) => ({
          ...value,
          value: value.id,
          submenus: value.submenus.map((valueSub) => ({
            ...valueSub,
            value: valueSub.id
          }))
        }))
      );
    });
  };

  const getDetail = () => {
    getDataByID(GET_DETAIL_POSTWEB, id).then((res) => {
      setDetail(res?.data);
      setDocument(res?.data?.attach_files);
      setLoading(false);
      setTypeF(res?.data?.parent_id);
      setTypeDocument(res?.data?.menu_id);
      setContentText(res?.data?.content);
      setPosition(res?.data?.position);
    });
  };

  const onSubmit = () => {
    const title = titleRef.current.value;
    const attach_files = document;
    const description = descriptionRef?.current?.value;

    {
      formData = {
        title,
        attach_files,
        menu_id: parseInt(typeDocument),
        content: contentText,
        description,
        position: position,
        image: banner?.current?.value
      };
      if (Number.isInteger(Number(position)) && Number(position) > 0) {
        putData(UPDATE_POSTWEB, id, formData)
          .then((res) => {
            createNotification(
              t('postWeb.success'),
              `${t('postWeb.updateSuccess')}`
            );
            setErrors('');
            typeF === 1
              ? history.push('/postweb/technique')
              : history.push('/postweb/activities');
          })
          .catch((err) => {
            const errss = destructServerErrors(err);
            setErrors(errss);
            if (err.response.status == 422) {
              return null;
            }
            return createNotification(t('postWeb.error'), err.message);
          });
      } else {
        showMessage(t('branch.errorPosition'), false);
      }
    }
  };

  useEffect(() => {
    getMenus();
    getDetail();
  }, []);

  const changeType = (e) => {
    setTypeF(e.target.value);
    const submenus = menus.filter((mn) => mn.id == e.target.value);
    setSubMenus(submenus[0].submenus);
  };

  const changeTypeDocument = (e) => {
    setTypeDocument(e.target.value);
  };

  const changeEditer = (e, editor) => {
    const data = editor.getData();
    setContentText(data);
  };

  const onChangePosition = (e) => {
    setPosition(e);
    if (!Number.isInteger(Number(e)) || Number(e) <= 0) {
      setErrors({
        ...errors,
        position: [t('branch.errorPosition')]
      });
    }
  };

  return isLoading ? (
    ''
  ) : (
    <>
      <section className="body-right-bottom">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>{t('postWeb.header')} </p>
            </div>
            <div className="col-md-1 top-table-border "></div>
            <div className="col-md-12 top-table-text">
              {t('postWeb.headerFormEdit')}
            </div>
          </div>

          <div className="content-form">
            <div className="row">
              <div className="col-md-12 content-title">
                <p>{t('postWeb.headerFormEdit')}</p>
              </div>
            </div>
            <div className="form-add">
              <div className="row">
                <div className="col-md-6">
                  <Select
                    label={t('postWeb.articleType')}
                    className="form-control-select input-form form-control-product mb-3 input-grey"
                    name="typemenu"
                    ref={typeRef}
                    errors={errors}
                    key_value="value"
                    key_label="name"
                    data={menus}
                    onChange={(e) => changeType(e)}
                    defaultValue={menus.find((item) => item.id === typeF)}
                    disabled
                  />
                </div>
                {typeF != 0 && (
                  <div className="col-md-6">
                    <Select
                      label={t('postWeb.articleType')}
                      className="form-control-white input-form form-control-product mb-3 input-blu"
                      name="typesubmenu"
                      ref={typeRef}
                      errors={errors}
                      key_value="value"
                      key_label="name"
                      include_blank={t('postWeb.articleType')}
                      data={
                        menus.length > 0 &&
                        menus.filter((item) => item.id === typeF)[0].submenus
                      }
                      defaultValue={
                        menus.length > 0 &&
                        menus
                          .filter((item) => item.id === typeF)[0]
                          .submenus.find((item) => item.id === typeDocument)
                      }
                      onChange={(e) => changeTypeDocument(e)}
                    />
                  </div>
                )}
                <div className="col-md-12">
                  <InputText
                    label={t('postWeb.title')}
                    className="form-control input-form"
                    placeholder={t('postWeb.title')}
                    name="title"
                    ref={titleRef}
                    errors={errors}
                    type="text"
                    defaultValue={detail && detail.title}
                    required={true}
                  />
                </div>
                <div className="col-md-12">
                  <TextEditor
                    name="content"
                    label={t('postWeb.content')}
                    errors={errors}
                    onChange={changeEditer}
                    data={detail && detail.content}
                    required={true}
                  />
                </div>

                <div className="col-12">
                  <TextArea
                    className="form-control input-form-short"
                    placeholder={t('postWeb.describe')}
                    name="description"
                    ref={descriptionRef}
                    label={t('postWeb.describe')}
                    errors={errors}
                    type="textarea"
                    defaultValue={detail && detail?.description}
                  />
                </div>
                <div className="col-md-6">
                  <GroupPhoto
                    label="Ảnh bìa"
                    name="image"
                    ref={banner}
                    title="Chọn ảnh để tải"
                    errors={errors}
                    defaultValue={detail && detail?.image}
                    order={1}
                    required={true}
                  />
                </div>
                <div className="col-md-6"></div>

                <div className="col-md-6">
                  <GroupFileCasorel
                    label={t('postWeb.changeFile')}
                    name="attach_files"
                    ref={bgr}
                    title={t('postWeb.changeFile')}
                    errors={errors}
                    default={document}
                    onChange={(data) => setDocument(data)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <InputText
                label={t('postWeb.labelPosition')}
                className="form-control input-form"
                placeholder={t('postWeb.position')}
                name="position"
                errors={errors}
                type="number"
                value={position}
                onChange={(e) => onChangePosition(e?.target?.value)}
                min={1}
                step={1}
                required={true}
              />
              <div className="row mt-2">
                <div className="col-12">
                  <div className="form-add-buttons">
                    <ButtonSave
                      onClick={history.goBack}
                      text={t('postWeb.cancel')}
                      className="btn btn-cancel"
                    />
                    <ButtonSave
                      onClick={() => onSubmit()}
                      text={t('postWeb.save')}
                      className="btn btn-new ml-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(NewAds);

NewAds.propTypes = {
  history: PropTypes.isRequired
};
