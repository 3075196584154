/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

import ButtonSave from '../../component/common/ButtonSave';
import Datepicker from '../../component/common/Datepicker';
import InputPhone from '../../component/common/InputPhone';
import InputText from '../../component/common/InputText';
import UploaderAvatarStudent from '../../component/common/UploaderAvatarStudent';
import CheckboxCustom from '../../component/common/CheckboxCustom';
import NavStudent from './NavStudent';

import {
  GET_LIST_ALL_CLUB,
  GET_STUDENT_INFO,
  UPDATE_STUDENT_REQUEST
} from '../../constants/config';
import {
  destructServerErrors,
  destructServerMessage
} from '../../helpers/error';
import { showMessage } from '../../helpers/table';
import {
  getDataByID,
  getTakenData,
  putData,
  putDataId
} from '../../services/base_services';

import defaultIMG from '../../assets/images/image.svg';

class EditStudent extends Component {
  constructor(props) {
    super(props);
    this.documents = [];
    this.state = {
      status: false,
      isLoading: true,
      errors: [],
      idClub: 0,
      defaultClb: null,
      listClub: [],
      listClubOption: [],
      data: [],
      defaultList: [],
      typical_flg: 0,
      idUser: '',
      coach_flg: false,
      user_id: ''
    };
  }

  editStudent = (e) => {
    e.preventDefault();
    const { match, history } = this.props;
    const { params } = match;
    const { id } = params;
    const formData = {
      name: this.name.value,
      club_id: this.state.idClub,
      avatar: this.avatar.value,
      address: this.address.value,
      email: this.email.value,
      phone: this.phone.value,
      identify_card_num: this.identify_card_num.value,
      home_town: this.home_town.value,
      health_status: this.health_status.value,
      academic_level: this.academic_level.value,
      birthday: this.birthday.value.split('-').reverse().join('-'),
      join_date: this.join_date.value.split('-').reverse().join('-'),
      profile: this.profile.value,
      job_detail: this.job.value,
      typical_flg: this.state.typical_flg,
      coach_flg: this.state.coach_flg,
      user_id: this.user_id.value
    };
    if (formData.avatar == { defaultIMG }) {
      formData.avatar = '';
    }
    putData(UPDATE_STUDENT_REQUEST, id, formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({
          errors: [],
          status: true
        });
        history.goBack();
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);

        this.setState({
          errors: errss
        });
        if (err.response.status == 422) {
          return null;
        }
        showMessage(errs, false);
      });
  };

  componentDidMount = async () => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.getList(id);
  };

  handleInputChangeChecked = (data) => {
    this.setState({
      checked: !data
    });
    if (data) {
      this.setState({ typical_flg: 0 });
      this.handleSetTypical(0);
    } else {
      this.setState({ typical_flg: 1 });
      this.handleSetTypical(1);
    }
  };

  handleSetTypical = (typical_flg) => {
    const { match, history } = this.props;
    const { params } = match;
    const { id } = params;
    putData('usertypical', id, { typical_flg: typical_flg })
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({
          errors: [],
          status: true
        });
      })
      .catch((err) => {
        const errs = destructServerMessage(err);
        const errss = destructServerErrors(err);

        this.setState({
          errors: errss
        });
        if (err.response.status == 422) {
          return null;
        }
        showMessage(errs, false);
      });
  };

  handleChange = (newValue) => {
    this.setState({
      idClub: newValue?.value
    });
  };

  handleInputChangeCheckedCoach = (e) => {
    let value = e.target.checked;
    if (value) {
      this.setState({
        coach_flg: true
      });
    } else {
      this.setState({
        coach_flg: false
      });
    }
  };

  getList = async (id) => {
    let { data } = this.state;
    if (id) {
      await getDataByID(GET_STUDENT_INFO, id)
        .then((res) => {
          this.setState({
            data: res?.data,
            isLoading: false,
            checked: res?.data?.typical_flg,
            coach_flg: res.data?.coach_flg
          });
          return Promise.resolve({ res });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
    await getTakenData(GET_LIST_ALL_CLUB).then((res) => {
      let { data } = this.state;
      let listClb = res.data;
      let listClbOpt = [];
      for (let i = 0; i < listClb.length; i++) {
        listClbOpt.push({
          label: listClb[i].name,
          value: listClb[i].id
        });
      }
      let findValue = res.data.find((item) => item.id === data.club_id);
      findValue = {
        label: findValue.name,
        value: findValue.id
      };
      this.setState({
        listClub: res.data,
        defaultClb: findValue,
        idClub: findValue.value
      });
      this.setState({
        listClubOption: listClbOpt
      });
    });
  };

  render() {
    const {
      status,
      errors,
      listClub,
      data,
      isLoading,
      defaultList,
      defaultClb,
      listClubOption
    } = this.state;
    const formatDate = 'dd-MM-yyyy';
    const { history, user } = this.props;
    const positionId = user?.user?.position_id;
    return isLoading ? (
      ''
    ) : (
      <>
        <div className="body-right-bottom">
          <div className="container-fluid content">
            <NavStudent />
            <div className="content-form mt-5">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 form-title">
                    {this.props.t('student.generalInformation')}
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.name')}
                      name="name"
                      ref={(c) => {
                        this.name = c;
                      }}
                      label={this.props.t('student.name')}
                      required={true}
                      errors={errors}
                      type="text"
                      defaultValue={data?.name}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="input-label">
                      {this.props.t('student.ofClub')}
                      <span style={{ color: '#ED1C24' }}>*</span>
                    </label>

                    {defaultClb?.label &&
                      (positionId == 3 ? (
                        <Select
                          className="select-custom-import"
                          name="partners"
                          onChange={this.handleChange}
                          options={listClubOption}
                          placeholder={this.props.t('student.ofClub')}
                          defaultValue={defaultClb}
                          isDisabled
                          required={true}
                        />
                      ) : (
                        <Select
                          className="select-custom-import"
                          name="partners"
                          onChange={this.handleChange}
                          options={listClubOption}
                          placeholder={this.props.t('student.ofClub')}
                          defaultValue={defaultClb}
                          required={true}
                        />
                      ))}
                    {errors.club_id && (
                      <div className="invalid-feedback d-block">
                        {errors.club_id}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <InputPhone
                      className="form-control input-form"
                      placeholder={this.props.t('student.phone')}
                      name="phone"
                      ref={(c) => {
                        this.phone = c;
                      }}
                      label={this.props.t('student.phone')}
                      required={true}
                      errors={errors}
                      type="text"
                      defaultValue={data && data.phone}
                    />
                  </div>
                  <div className="col-md-6">
                    <Datepicker
                      name="join_date"
                      ref={(c) => {
                        this.join_date = c;
                      }}
                      label={this.props.t('student.joinDate')}
                      errors={errors}
                      clearIcon={false}
                      className="form-control input-form"
                      format={formatDate}
                      defaultValue={data?.join_date}
                      required={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <Datepicker
                      name="birthday"
                      ref={(c) => {
                        this.birthday = c;
                      }}
                      label={this.props.t('student.brithday')}
                      required={true}
                      errors={errors}
                      clearIcon={false}
                      className="form-control input-form"
                      format={formatDate}
                      defaultValue={data?.birthday}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.uersId')}
                      name="user_id"
                      ref={(c) => {
                        this.user_id = c;
                      }}
                      label={this.props.t('student.uersId')}
                      errors={errors}
                      type="text"
                      defaultValue={data?.user_id}
                      required={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 form-title">
                    {this.props.t('student.otherInformation')}
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.address')}
                      name="address"
                      ref={(c) => {
                        this.address = c;
                      }}
                      label={this.props.t('student.address')}
                      errors={errors}
                      type="text"
                      defaultValue={data?.address}
                    />
                  </div>

                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.email')}
                      name="email"
                      ref={(c) => {
                        this.email = c;
                      }}
                      label={this.props.t('student.email')}
                      errors={errors}
                      type="text"
                      defaultValue={data && data.email}
                    />
                  </div>

                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.account')}
                      name="profile"
                      ref={(c) => {
                        this.profile = c;
                      }}
                      label={this.props.t('student.account')}
                      errors={errors}
                      type="text"
                      defaultValue={data?.profile}
                    />
                  </div>

                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.job')}
                      name="job_detail"
                      ref={(c) => {
                        this.job = c;
                      }}
                      label={this.props.t('student.job')}
                      errors={errors}
                      type="text"
                      defaultValue={data?.job_detail}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.cardID')}
                      name="identify_card_num"
                      type="number"
                      ref={(c) => {
                        this.identify_card_num = c;
                      }}
                      label={this.props.t('student.cardID')}
                      tabIndex="0"
                      onKeyDown={(e) =>
                        (e.keyCode === 69 || e.keyCode === 190) &&
                        e.preventDefault()
                      }
                      errors={errors}
                      defaultValue={data?.identify_card_num}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.homeTown')}
                      name="home_town"
                      ref={(c) => {
                        this.home_town = c;
                      }}
                      label={this.props.t('student.homeTown')}
                      errors={errors}
                      type="text"
                      defaultValue={data?.home_town}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.healthCondition')}
                      name="health_status"
                      ref={(c) => {
                        this.health_status = c;
                      }}
                      label={this.props.t('student.healthCondition')}
                      errors={errors}
                      type="text"
                      defaultValue={data?.health_status}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      className="form-control input-form"
                      placeholder={this.props.t('student.education')}
                      name="academic_level"
                      ref={(c) => {
                        this.academic_level = c;
                      }}
                      label={this.props.t('student.education')}
                      errors={errors}
                      type="text"
                      defaultValue={data?.academic_level}
                    />
                  </div>
                  <div className="col-md-6">
                    <UploaderAvatarStudent
                      ref={(c) => {
                        this.avatar = c;
                      }}
                      name="avatar"
                      errors={errors}
                      defaultValue={data?.avatar}
                    />
                  </div>
                  <div className="col-md-3 checkbox-main-custom d-flex ">
                    <CheckboxCustom
                      id="checked"
                      label={this.props.t('student.outstandingStudent')}
                      onChange={() =>
                        this.handleInputChangeChecked(this.state.checked)
                      }
                      defaultChecked={this.state.checked}
                    />
                  </div>
                  <div className="col-md-3 checkbox-main-custom d-flex ">
                    <CheckboxCustom
                      id="check"
                      label={this.props.t('student.coach')}
                      onChange={(e) => this.handleInputChangeCheckedCoach(e)}
                      defaultChecked={this.state.coach_flg}
                    />
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-12 my-5">
                  <div className="form-add-buttons">
                    <a>
                      <ButtonSave
                        text={this.props.t('student.cancel')}
                        className="btn btn-cancel"
                        onClick={history.goBack}
                      />
                    </a>
                    <ButtonSave
                      onClick={this.editStudent}
                      text={this.props.t('student.save')}
                      className="btn btn-new ml-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

EditStudent.propTypes = {
  match: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(withTranslation()(EditStudent)));
