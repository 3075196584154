/* eslint-disable react/display-name */
import React from 'react';
import { Switch } from 'react-router-dom';

import { ADMIN_ROUTES } from '../constants/router';

import TableLibrary from '../container/Library/TableLibrary';
import NewLibrary from '../container/Library/NewLibrary';
import EditLibrary from '../container/Library/EditLibrary';
import DetailLibrary from '../container/Library/DetailLibrary';
import EbookLibrary from '../container/Library/EbookLibrary';
import PhotoLibrary from '../container/Library/PhotoLibrary';
import DocLibrary from '../container/Library/DocLibrary';
import VideoLibrary from '../container/Library/VideoLibrary';
import RequestList from '../container/Library/RequestList';

import AuthRoute from './AuthRoute';
import Categories from '../container/Library/Categories ';
import NewTitle from '../container/Library/NewTitle';
import EditTitle from '../container/Library/EditTitle';

const routes = [
  {
    path: '/libraries',
    component: () => <TableLibrary />,
    exact: true
  },
  {
    path: '/active/libraries/new',
    component: () => <NewLibrary />,
    exact: true
  },
  {
    path: '/active/libraries/new-title',
    component: () => <NewTitle />,
    exact: true
  },
  {
    path: '/active/libraries/edit/:id',
    component: () => <EditLibrary />,
    exact: true
  },
  {
    path: '/active/libraries/edit-title/:id',
    component: () => <EditTitle />,
    exact: true
  },
  {
    path: '/active/libraries/detail/:id',
    component: () => <DetailLibrary />,
    exact: true
  },
  {
    path: ADMIN_ROUTES.ACTIVE_LIBRARIES_EBOOK,
    component: () => <EbookLibrary />,
    exact: true,
    meta: {
      action: 'library_ebook_view',
      subject: 'library'
    }
  },
  {
    path: ADMIN_ROUTES.ACTIVE_LIBRARIES_PHOTO,
    component: () => <PhotoLibrary />,
    exact: true,
    meta: {
      action: 'library_photo_view',
      subject: 'library'
    }
  },
  {
    path: ADMIN_ROUTES.ACTIVE_LIBRARIES_DOCUMENT,
    component: () => <DocLibrary />,
    exact: true,
    meta: {
      action: 'library_document_view',
      subject: 'library'
    }
  },
  {
    path: ADMIN_ROUTES.ACTIVE_LIBRARIES_VIDEO,
    component: () => <VideoLibrary />,
    exact: true,
    meta: {
      action: 'library_audio_video_view',
      subject: 'library'
    }
  },
  {
    path: ADMIN_ROUTES.ACTIVE_LIBRARIES_REQUEST,
    component: () => <RequestList />,
    exact: true
  },
  {
    path: ADMIN_ROUTES.ACTIVE_LIBRARIES_CATEGORIES,
    component: () => <Categories />,
    exact: true
  }
];

const LibrariesRoutes = () => {
  if (typeof window !== 'undefined') {
    return (
      <>
        <Switch>
          {routes.map((route) => (
            <AuthRoute key={route.path} {...route} />
          ))}
        </Switch>
      </>
    );
  }
  return '';
};

export default LibrariesRoutes;
