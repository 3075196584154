import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { destructServerErrors } from '../../helpers/error';
import { putData, getDataByID } from '../../services/base_services';
import { GET_DETAIL_BRANCH_REQUEST } from '../../constants/config';
import { ADMIN_ROUTES } from '../../constants/router';

import InputText from '../../component/common/InputText';
import GroupPhoto from '../../component/common/GroupPhoto';
import ButtonSave from '../../component/common/ButtonSave';
import createNotification from '../../component/common/Notification';

const EditBranchBanner = (props) => {
  const { history, match } = props;

  const id = match && match.params && match.params.id;

  const banner = useRef([]);

  const [detail, setDetail] = useState([]);
  const [errors, setErrors] = useState([]);

  const onSubmit = () => {
    const payload = {
      image: banner?.current?.value || detail?.image
    };

    putData(GET_DETAIL_BRANCH_REQUEST, id, payload)
      .then(() => {
        createNotification('success', `Bạn đã thêm thành công`);
        setErrors('');
        history.push(ADMIN_ROUTES.POSTWEB_BANNER_BRANCH);
      })
      .catch((err) => {
        const errss = destructServerErrors(err);
        setErrors(errss);
        if (err.response.status == 422) {
          return null;
        }
        return createNotification('error', err.message);
      });
  };

  useEffect(() => {
    getDataByID(GET_DETAIL_BRANCH_REQUEST, id)
      .then((res) => {
        setDetail(res?.data);
      })
      .catch((err) => err);
  }, []);

  return (
    <>
      <section className="body-right-bottom">
        <div className="container-fluid content">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>Quản Lý hình ảnh phân đường</p>
            </div>
            <div className="col-md-1 top-table-border "></div>
            <div className="col-md-12 top-table-text">Chỉnh sửa</div>
          </div>

          <div className="content-form">
            <div className="row">
              <div className="col-md-12 content-title">
                <p>Chỉnh sửa hình ảnh phân đường</p>
              </div>
            </div>
            <div className="form-add">
              <div className="row">
                <div className="col-md-12">
                  <InputText
                    label="Tên phân đường"
                    className="form-control input-form"
                    placeholder="Tên phân đường"
                    errors={errors}
                    name="title"
                    type="text"
                    defaultValue={detail?.name}
                    disabled={true}
                    required={true}
                  />
                </div>
                <div className="col-md-6">
                  <GroupPhoto
                    label="Hình ảnh "
                    name="image"
                    ref={banner}
                    title="Chọn ảnh để tải"
                    errors={errors}
                    defaultValue={detail?.image}
                    order={1}
                    required={true}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-12">
                  <div className="form-add-buttons">
                    <ButtonSave
                      onClick={history.goBack}
                      text="Hủy"
                      className="btn btn-cancel"
                    />
                    <ButtonSave
                      onClick={() => onSubmit()}
                      text="Lưu thông tin"
                      className="btn btn-new ml-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(EditBranchBanner);

EditBranchBanner.propTypes = {
  history: PropTypes.isRequired,
  match: PropTypes.isRequired
};
